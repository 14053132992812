import { MARKET_LIST } from "./profiles";
import { Promotion } from "../slices/promotionSlice";
import { Tutorial } from '../slices/tutorialSlice';
import { Reward } from '../slices/rewardSlice';
import { User } from "../slices/userSlice";
import { Tin } from "../slices/tinSlice";

import lata from '../images/CMP_lata.png';
import latao from '../images/CMP_latao.png';
import bike from '../images/reward/bike2.jpeg';
import patins from '../images/reward/patins.jpeg';
import secador from '../images/reward/secador.jpeg';

type UserLogin = {
  name: string;
  email: string;
  password: string;
  id: number;
  admin?: boolean;
};

export const mockUsers: UserLogin[] = [
  { email: "Lojista.imobiliario@anjo.com", password: "Imobiliario", id: 1, name: 'lojista' },
  { email: "Balconista.imobiliario@anjo.com", password: "Imobiliario", id: 2, name: 'balconista' },
  { email: "Pintor.imobiliario@anjo.com", password: "Imobiliario", id: 3, name: 'pintor' },
  { email: "Cliente.imobiliario@anjo.com", password: "Imobiliario", id: 4, name: 'cliente' },
  { email: "Lojista.automotivo@anjo.com", password: "Automotivo", id: 5, name: 'lojista' },
  { email: "Balconista.automotivo@anjo.com", password: "Automotivo", id: 6, name: 'balconista' },
  { email: "Colorista.automotivo@anjo.com", password: "Automotivo", id: 7, name: 'colorista' },
  { email: "ChefeOficina.automotivo@anjo.com", password: "Automotivo", id: 8, name: 'chefe oficial' },
  { email: "Funileiro.automotivo@anjo.com", password: "Automotivo", id: 9, name: 'funileiro' },
  { email: "montanari@gmail.com", password: "montanari123", id: 10, name: 'montanari' },
  { email: "admin", password: "admin", id: 11, admin: true, name: 'admin' },
];

export const mockUsersList: User[] = [
  { 
    id: 1,
    email: "Lojista.imobiliario@anjo.com", 
    password : 'lojista123',
    name: 'lojista',
    city: 'Campinas',
    cpf: '111.111.111-11',
    profile: 'Lojista',
    contact: '(19) 11111-1111',
    points: 100,
    active: true
  },
  { 
    id: 2,
    email: "cliente.imobiliario@anjo.com", 
    password : 'cliente123',
    name: 'Cliente',
    city: 'São Paulo',
    cpf: '222.222.222-22',
    profile: 'Cliente',
    contact: '(19) 22222-2222',
    points: 200,
    active: true,
  },
  { 
    id: 3,
    email: "pintor.imobiliario@anjo.com", 
    password : 'pintor123',
    name: 'pintor',
    city: 'Rio Preto',
    cpf: '333.333.333-33',
    profile: 'Pintor',
    contact: '(19) 33333-3333',
    points: 300,
    active: true,
  },
];

export const mockPromotions: Promotion[] = [
  {
    name: "Promoção leve duas pague uma",
    id: 1,
    punctuation: 10,
    profile: [{id: null, name: 'Lojista'}],
    market: MARKET_LIST[0].customValue,
    initDate: '12/12/2022',
    endDate: '12/12/2022',
    description: '01 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  } as Promotion,
  {
    name: "Promoção de final de ano",
    id: 2,
    punctuation: 1,
    profile: [{id: null, name: 'Balconista'}],
    market: MARKET_LIST[1].customValue,
    initDate: '12/12/2022',
    endDate: '12/12/2022',
    description: '02 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  } as Promotion,
  {
    name: "Promoção 30 anos anjo",
    id: 3,
    punctuation: 5,
    profile: [{id: null, name: 'Pintor'}],
    market: MARKET_LIST[0].customValue,
    initDate: '12/12/2022',
    endDate: '12/12/2022',
    description: '03 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  } as Promotion,
  {
    name: "Promoção 29 anos anjo",
    id: 4,
    punctuation: 10000,
    profile: [{id: null, name: 'Cliente'}],
    market: MARKET_LIST[0].customValue,
    initDate: '12/12/2022',
    endDate: '12/12/2022',
    description: '03 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  } as Promotion,
];

export const mockTutorials: Tutorial[] = [
  {
    id : 1,
    url : "https://youtu.be/C4CgKJN3njw",
    title : "CMP 100 anos",
    description : "01 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    market: '',
    profile: '',
  } as Tutorial,
  {
    id : 2,
    url : "https://www.youtube.com/watch?v=OTWtr6fYnGo",
    title : "Whatsapp busines",
    description : "02 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    market: '',
    profile: '',
  } as Tutorial,
  {
    id : 3,
    url : "https://www.youtube.com/watch?v=3tUH2k3F3I8",
    title : "Arquitetos",
    description : "03 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    market: '',
    profile: '',
  } as Tutorial,
  {
    id : 4,
    url : "https://www.youtube.com/watch?v=Q6-9wecR9P8",
    title : "Encontro de pintura",
    description : "04 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    market: '',
    profile: '',
  } as Tutorial,
  {
    id : 5,
    url : "https://www.youtube.com/watch?v=lk6Q3gpbjns",
    title : "Eduardo Meira Pintor",
    description : "05 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    market: '',
    profile: '',
  } as Tutorial,
];


export const mockTimes = [
  '8:00h', '8:30h',
  '9:00h', '9:30h',
  '10:00h', '10:30h',
  '11:00h', '11:30h',
  '12:00h', '12:30h',
  '13:00h', '13:30h',
  '14:00h', '14:30h',
  '15:00h', '15:30h',
  '16:00h', '16:30h',
  '17:00h', '17:30h',
]
export const mockRewards : Reward[] = [
  {
    rewardId: 1,
    acceptedDate: "31/03/2024",
    points: 20200,
    status: "open",
    userName: "Joao Montanari",
    image : bike
  } as Reward,
  {
    rewardId: 2,
    acceptedDate: "31/03/2024",
    points: 10000,
    status: "open",
    userName: "Joao Fernandes",
    image : patins
  } as Reward,
  {
    rewardId: 3,
    acceptedDate: "31/03/2024",
    points: 800,
    status: "open",
    userName: "Daniel Dante",
    image : secador
  } as Reward,
];

export const mockTin : Tin[] = [
  {
    id : 1,
    url : 'www.cmp.com/ID=1234',
    information : 'Lata de tinta do ramo residencial',
    image : 'https://abrir.url/StNLt',
    market : 'Lojista',
    url_id : 1,
    status : 'new',
    registrationTimestamp : '2023-12-14',
    imageUrl : 'https://abrir.url/StNLt',
    op: '1234-567',
  },
  {
    id : 2,
    url : 'www.cmp.com/ID=1234',
    information : 'Lata de tinta do ramo automotivo',
    image : 'https://abrir.url/StNLt',
    market : 'Balconista',
    url_id : 2,
    status : 'new',
    registrationTimestamp : '2023-12-14',
    imageUrl : 'https://abrir.url/StNLt',
    op: '1234-567',
  },
  {
    id : 3,
    url : 'www.cmp.com/ID=1234',
    information : 'Lata de tinta do ramo residencial',
    image : 'https://abrir.url/StNLt',
    market : 'Pintor',
    url_id : 3,
    status : 'new',
    registrationTimestamp : '2023-12-14',
    imageUrl : 'https://abrir.url/StNLt',
    op: '1234-567',
  },
]