import './tinList.css';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { Add, Edit, Delete } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { listTin, deleteTin, Tin } from '../../../slices/tinSlice';

import Header from '../../../components/Header/Header';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import Loading from '../../../components/Loading/Loading';
import AddTinModal from '../../../components/AddTinModal/AddTinModal';
import Search from '../../../components/Search/Search';

const TinList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { listResponse, tinDelete, status } = useAppSelector((state) => state.tin);

    const [tins, setTins] = useState([] as Tin[]);
    const [search, setSearch] = useState('');
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
    const [isOpenAddTin, setOpenAddTin] = useState(false);
    const [selectId, setSelectId] = useState(0);

    const openDeleteModal = (id: number) => {
        setOpenDeleteModal(true);
        setSelectId(id);
    }

    const deleteClick = async () => {
        if (selectId) {
            await dispatch(deleteTin(selectId));
        }
    }

    useEffect(() => {
        if (status === "ready") {
            dispatch(listTin());
        }
    }, [status, tinDelete]);

    useEffect(() => {
        dispatch(listTin());
    }, [dispatch]);

    useEffect(() => {
        setTins(listResponse);
    }, [listResponse]);

    useEffect(() => {
        if (search) {
            let result = listResponse.filter((item) => 
                item.url.toLowerCase().indexOf(search.toLowerCase()) != -1
            );
            setTins(result);
        } else {
            setTins(listResponse);
        }
    }, [search]);

    return (
        <div className='tinListMain' >
            <Header />
            <div className='tinListTitle' >
                <ArrowBack onClick={() => navigate('/admin/home')} />
                <h1>Lista de </h1>
            </div>

            <Search
                setValue={setSearch}
                value={search}
                border={true}
            />

            <div className='tinListContainer' >
                {
                    tins && tins.map((tin, index) => (
                        <div className='tinListCard' key={index} >
                            <div className='tinListCardSubDivision' >
                                <label>URL:</label>
                                <span>{tin.url}</span>
                            </div>
                            <div className='tinListCardSubDivision' >
                                <Edit onClick={() => navigate('/admin/tin-form', { state: { id: tin.id } })} />
                                <Delete onClick={() => openDeleteModal(tin.id)} />
                            </div>
                        </div>
                    ))
                }
            </div>

            <button className='createTinButton' onClick={() => setOpenAddTin(true)} >
                <Add />
            </button>

            <DeleteModal
                isOpen={isOpenDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onDelete={() => deleteClick()}
                text='Deseja realmente excluir esta lata?'
            />

            <AddTinModal
                isOpen={isOpenAddTin}
                onClose={() => setOpenAddTin(false)}
            />

            <Loading isOpen={status === 'loading'} />

        </div>
    );
}

export default TinList;