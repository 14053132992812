import './simpleModal.css';

const SimpleModal = ({isOpen, onClose, children} : {isOpen:boolean, onClose:Function, children:any}) => {
    return (
        <>
            {
                isOpen ? (
                    <div className='simpleModal'>
                        <div className='simpleModalBackground'>
                            <div className='simpleModalContainer'>
                                <div className='simpleModalControls'>
                                    <button className='simpleModalClose' type='button' onClick={() => onClose()} />
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}

export default SimpleModal;