import {
    Button
} from "@mui/material";
import colors from "../../utils/colors";
import arrowDown from '../../images/arrow_down.png'
const MeetBtn = ({ isMenu = false, title, click, color = colors.red, disabled = false, textColor = 'white' }:
    { isMenu?: boolean, title: string, click: any, color?: string | any, disabled?: boolean | any, textColor?: string }) => {

    let menuStyle = { display: 'flex', justifyContent: 'center', margin: 'auto', width: '100%' }
    let regularStyle = { width: '100%' }

    return (

        <div style={isMenu ? menuStyle : regularStyle}>


            <Button
                disabled={disabled}
                style={{
                    width: '100%',
                    marginTop: 3, marginBottom: 3,
                    backgroundColor: color,
                    maxHeight: 33,
                    borderRadius: 5,
                    maxWidth: isMenu ? 90 : 113
                }}
                onClick={disabled ? null : click} >
                <span style={{ color: textColor, marginTop: 2, textTransform: 'none', fontSize: 12 }}>
                    {title}</span>

                {!isMenu && <>&nbsp;</>}
                {!isMenu && <img src={arrowDown} />}

            </Button>

        </div>
    );
}

export default MeetBtn;
