import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../utils/types';
import { buildUrl } from '../api/services';
import { CmpAxios } from '../api/CmpAxios';
import { MessageError, Error } from '../utils/ErrorResponse';
import { NotificationType } from './notificationSlice';

type PontuationState = {
    success: boolean | null,
    status: 'idle' | 'loading' | 'failed' | 'ready',
    errorPoints: boolean | null,
    pontuated: boolean | null,
    error: Error | null,
}

const initialState: PontuationState = {
    success: null,
    status: 'idle',
    errorPoints: null,
    pontuated: null,
    error: null,
}

export const cleanPoints = createAsyncThunk(
    "pontuation/cleanPoints",
    async (data: any) => {
        return true;
    }
);

export const pontuateQr = createAsyncThunk(
    "pontuation/pontuateQr",
    async (data: any, { rejectWithValue }) => {
        try {
            let user = JSON.parse(localStorage.getItem("userInfo") as string);
            let id = user.id;
            let token = localStorage.getItem("token");
            let body = { userId: id, canUrlId: data.id }
            let baseRequest = await buildUrl('pontuateQr')
            const request = await CmpAxios(
                baseRequest.route,
                baseRequest.method,
                body,
                token
            );

            if(!request.error) {
                return request.response;
            } else {
                return rejectWithValue(request.response);
            }

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const pontuateBanner = createAsyncThunk(
    "pontuation/pontuateBanner",
    async (data: any, { rejectWithValue }) => {
        let user = JSON.parse(localStorage.getItem("userInfo") as string);
        let id = user.id;
        let token = localStorage.getItem("token");
        let body = { userId: id, canUrlId: data.id, promotionId: data.promo }
        let baseRequest = await buildUrl('pontuateBanner')
        const request = await CmpAxios(
            baseRequest.route,
            baseRequest.method,
            body,
            token
        );

        if (!request.error) {
            return request.response;
        } else {
            return rejectWithValue(request.response);
        }
    }
);

const puntuationSlice = createSlice({
    name: "pontuation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(pontuateQr.pending, (state) => {
                state.status = "loading";
                state.errorPoints = null;
                state.success = null;
                state.pontuated = null;
            })
            .addCase(pontuateQr.fulfilled, (state, action) => {
                state.status = "ready";
                state.success = true;
                state.pontuated = true;
            })
            .addCase(pontuateQr.rejected, (state, action) => {
                state.status = "failed";
                state.errorPoints = true;
                state.pontuated = false;
                state.error = 
                MessageError(({ message: action.payload } as ErrorResponse).message) || 
                { 
                    response: "", 
                    message: "Falha ao tentar pontuar a promoção!", 
                    notificationType: NotificationType.Error 
                } as Error;
            })



            .addCase(cleanPoints.fulfilled, (state, action) => {
                state.status = "idle";
                state.success = null;
                state.pontuated = null;
                state.errorPoints = null;
            })



            .addCase(pontuateBanner.fulfilled, (state, action) => {
                state.status = "ready";
                state.success = true;
                state.pontuated = true;
            })
            .addCase(pontuateBanner.pending, (state) => {
                state.status = "loading";
                state.errorPoints = null;
                state.success = null;
                state.pontuated = null;
            })
            .addCase(pontuateBanner.rejected, (state, action) => {
                state.status = "failed";
                state.errorPoints = true;
                state.pontuated = false;
                state.error = 
                MessageError(({ message: action.payload } as ErrorResponse).message) || 
                { 
                    response: "", 
                    message: "Falha ao tentar pontuar a promoção!", 
                    notificationType: NotificationType.Error 
                } as Error;
            })
    }
});

export default puntuationSlice.reducer;


