import Qrscan from "../../components/QrScan/QrScan";
 
 
 
const ScanPage = () => {
    return (
        <div>
            <Qrscan />
        </div>
    );
};
 
export default ScanPage;