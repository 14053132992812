export const AUTOMOTIVE_PROFILES = [
    {
        customLabel: 'Lojista',
        customValue: 'Lojista'
    },
    {
        customLabel: 'Balconista',
        customValue: 'Balconista'
    },
    {
        customLabel: 'Colorista',
        customValue: 'Colorista'
    },
    {
        customLabel: 'Chefe de Oficina',
        customValue: 'Chefe de oficina'
    },
    {
        customLabel: 'Funileiro',
        customValue: 'Funileiro'
    },
]

export const HOUSE_PROFILES = [
    {
        customLabel: 'Lojista',
        customValue: 'Lojista'
    },
    {
        customLabel: 'Balconista',
        customValue: 'Balconista'
    },
    {
        customLabel: 'Pintor',
        customValue: 'Pintor'
    },
    {
        customLabel: 'Cliente Final',
        customValue: 'Cliente'
    },
]

export const ALL_PROFILES = [
    {
        customLabel: 'Lojista',
        customValue: 'Lojista'
    },
    {
        customLabel: 'Balconista',
        customValue: 'Balconista'
    },
    {
        customLabel: 'Colorista',
        customValue: 'Colorista'
    },
    {
        customLabel: 'Chefe de Oficina',
        customValue: 'Chefe de Oficina'
    },
    {
        customLabel: 'Funileiro',
        customValue: 'Funileiro'
    },
    {
        customLabel: 'Pintor',
        customValue: 'Pintor'
    },
    {
        customLabel: 'Cliente Final',
        customValue: 'Cliente'
    },
    {
        customLabel: 'Promotor',
        customValue: 'Promotor'
    },
    {
        customLabel: 'Administrador',
        customValue: 'Administrador'
    },
    {
        customLabel: 'Gestor',
        customValue: 'Gestor'
    },
]

export const ALL_PROFILES_MANAGER = [
    {
        customLabel: 'Lojista',
        customValue: 'Lojista'
    },
    {
        customLabel: 'Balconista',
        customValue: 'Balconista'
    },
    {
        customLabel: 'Colorista',
        customValue: 'Colorista'
    },
    {
        customLabel: 'Chefe de Oficina',
        customValue: 'Chefe de Oficina'
    },
    {
        customLabel: 'Funileiro',
        customValue: 'Funileiro'
    },
    {
        customLabel: 'Pintor',
        customValue: 'Pintor'
    },
    {
        customLabel: 'Cliente Final',
        customValue: 'Cliente'
    },
    {
        customLabel: 'Promotor',
        customValue: 'Promotor'
    },
]

export const ALL_CLIENT_PROFILE = [
    {
        customLabel: 'Lojista',
        customValue: 'Lojista'
    },
    {
        customLabel: 'Balconista',
        customValue: 'Balconista'
    },
    {
        customLabel: 'Colorista',
        customValue: 'Colorista'
    },
    {
        customLabel: 'Chefe de Oficina',
        customValue: 'Chefe de Oficina'
    },
    {
        customLabel: 'Funileiro',
        customValue: 'Funileiro'
    },
    {
        customLabel: 'Pintor',
        customValue: 'Pintor'
    },
    {
        customLabel: 'Cliente Final',
        customValue: 'Cliente'
    },
]

export const ALL_SPECIAL_PROFILE = [
    {
        customLabel: 'Promotor',
        customValue: 'Promotor'
    },
    {
        customLabel: 'Administrador',
        customValue: 'Administrador'
    },
    {
        customLabel: 'Gestor',
        customValue: 'Gestor'
    },
]

export const MARKET_LIST = [
    {
        customLabel: 'Imobiliário',
        customValue: 'Imobiliário'
    },
    {
        customLabel: 'Automotivo',
        customValue: 'Automotivo'
    },
];