import './userForm.css';
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import bcrypt from "bcryptjs-react";

import Header from '../../components/Header/Header';
import SimpleInput from '../../components/SimpleInput/SimpleInput';
import CustomSelect from '../../components/CustomSelect/customSelect';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Loading from '../../components/Loading/Loading';

import { User, updateUser } from '../../slices/userSlice';
import { ALL_PROFILES, ALL_PROFILES_MANAGER } from '../../utils/profiles';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { clearUserState, createUser } from '../../slices/userSlice';
import { NotificationType, showNotification } from '../../slices/notificationSlice';

const UserFormAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const { error, userCreated, userUpdated, status, listResponse } = useAppSelector((state) => state.user);
    const { userProfile } = useAppSelector((state) => state.auth);
    const [disabled, setDisabled] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [cpf, setCpf] = useState('');
    const [city, setCity] = useState('');
    const [profile, setProfile] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [points, setPoints] = useState(0);
    
    const [newPassword, setNewPassword] = useState('');
    const [isChangePassword, setChangePassword] = useState(false);

    useEffect(() => {
        if (state && state.id) {
            let response : User | undefined = listResponse.find((user) => {
                return user.id === state.id;
            });

            if (response) {
                const {
                    name,
                    email,
                    contact,
                    cpf,
                    city,
                    profile,
                    password,
                    points,
                } = response;

                setName(name);
                setEmail(email);
                setTelephone(contact);
                setCpf(cpf);
                setCity(city);
                setProfile(profile);
                setPassword(bcrypt.hashSync(password, 12));
                setPoints(points);
            }
        }
    }, []);

    useEffect(() => {
        setDisabled(
            Boolean(!(
                name &&
                email &&
                telephone &&
                cpf &&
                city &&
                profile &&
                password
            ))
        );
    }, [name, email, telephone, cpf, city, profile, password]);

    const handleClick = () => {
        var hashPass = bcrypt.hashSync(password, 12);

        if(state && state.id) {
            let id : number = state.id;
            if (newPassword) {
                hashPass = bcrypt.hashSync(newPassword, 12);
                dispatch(
                    updateUser({
                        id,
                        name,
                        email,
                        contact: telephone,
                        cpf,
                        city,
                        profile,
                        password: hashPass,
                        points,
                    })
                );
            } else {
                dispatch(
                    updateUser({
                        id,
                        name,
                        email,
                        contact: telephone,
                        cpf,
                        city,
                        profile,
                        points,
                    })
                );
            }
        } else {
            if (password === confPassword) {
                dispatch(
                    createUser({
                        name,
                        email,
                        password: hashPass,
                        city,
                        cpf,
                        profile,
                        contact: telephone,
                    })
                )
            } else {
                dispatch(showNotification({
                    message: 'As senhas não estão condizentes!',
                    type: NotificationType.Warning
                }))
            }
        }
    }

    useEffect(() => {
        if(status === 'ready') {
            navigate('/admin/user-list');
        }
    }, [status, userCreated, userUpdated]);

    useEffect(() => {
        if (error && status === 'failed') {
            dispatch(showNotification({
                message: error.message,
                type: error.notificationType as NotificationType,
            }))
        }
    }, [error, status]);



    useEffect(() => {
        if (userCreated) {
            dispatch(showNotification({
                message: 'Usuário criado com sucesso!',
                type: NotificationType.Success,
            }))
            dispatch(clearUserState(true));
            navigate('/admin/user-list');
        }
    }, [userCreated]);

    return (
        <div className='userFormMain' >
            <Header />
            <div className='userFormTitle' >
                <ArrowBack onClick={() => navigate('/admin/user-list')} />
                <h1>{state && state.id ? 'Edição' : 'Cadastro'}</h1>
                <span>Informações do usuário</span>
            </div>
            <div className='userFormContainer' >
                <SimpleInput label='Nome' value={name} setValue={setName} />
                <SimpleInput label='Email' value={email} setValue={setEmail} />
                
                {
                    !(state && state.id) ? (
                        <>
                            <SimpleInput 
                                label='Senha' 
                                type='password' 
                                value={password} 
                                setValue={setPassword} 
                                isPassword
                            />
                            <SimpleInput 
                                label='Confirmar senha' 
                                type='password' 
                                value={confPassword} 
                                setValue={setConfPassword} 
                                isPassword
                            />
                        </>
                    ) : null
                }
                
                <div className='userFormLine' >
                    <SimpleInput label='Telefone' value={telephone} isPhone setValue={setTelephone} />
                    <SimpleInput label='CPF' value={cpf} isCpf setValue={setCpf} />
                </div>
                <SimpleInput label='Cidade' value={city} setValue={setCity} />
                {
                    state && state.id ? (
                        <SimpleInput label='Pontos' value={points} setValue={setPoints} type='number' />
                    ) : null
                }
                
                {
                    userProfile === "Gestor" ? (
                        <CustomSelect
                            label='Perfil'
                            value={profile}
                            setValue={setProfile}
                            listArray={ALL_PROFILES_MANAGER}
                        />
                    ) : (
                        <CustomSelect
                            label='Perfil'
                            value={profile}
                            setValue={setProfile}
                            listArray={ALL_PROFILES}
                        />
                    )
                }

                {
                    isChangePassword ? (
                        <SimpleInput 
                            label='nova senha' 
                            type='password' 
                            value={newPassword} 
                            setValue={setNewPassword} 
                            isPassword
                        />
                    ) : null
                }
                
                {
                    state && state.id && !isChangePassword ? (
                        <button 
                            style={{ color: '#D02030', fontWeight: 'bold', fontSize: '14px' }} 
                            onClick={() => setChangePassword(true)} 
                        >
                            trocar senha
                        </button>
                    ) : null
                }

                <PrimaryButton
                    label='Salvar'
                    onClick={handleClick}
                    padding='48px 0'
                    disabled={disabled}
                />

            </div>
            <Loading isOpen={status == 'loading'} />
        </div>
    );
}

export default UserFormAdmin;