import { Button } from "@mui/material";
import colors from "../../utils/colors";
import "./primaryButton.css";

const PrimaryButton = ({
  disabled = false,
  onClick,
  padding,
  startIcon,
  label,
}: {
  disabled?: boolean;
  onClick(): void;
  padding: string;
  startIcon?: React.ReactNode;
  label: string;
}) => {
  return (
    <div className="primaryButton" style={{ padding }}>
      <Button
        disabled={disabled}
        style={{
          backgroundColor: !disabled ? colors.pinkRed : "grey",
          maxHeight: 42,
          padding: "10px 20px",
          textTransform: "none",
          width: "fit-content",
          color: "white",
          fontSize: "12px",
        }}
        onClick={onClick}
        startIcon={startIcon}
      >
        <span style={{ color: "white", marginTop: 2 }}>{label}</span>
      </Button>
    </div>
  );
};

export default PrimaryButton;
