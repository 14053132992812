import { Add, Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import './tutorialList.css';

import { Tutorial, deleteTutorial, listTutorial } from '../../../slices/tutorialSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

import info from '../../../images/info.png';
import youtube from '../../../images/youtube.png';
import player from '../../../images/player.png';

import SimpleModal from '../../../components/SimpleModal/SimpleModal';
import Search from '../../../components/Search/Search';
import Loading from '../../../components/Loading/Loading';
import Header from '../../../components/Header/Header';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

const TutorialList = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [search, setSearch] = useState('');
    const [deleteId, setDeleteId] = useState(0);

    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [tutorials, setTutorials] = useState([] as Tutorial[]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { listResponse, status, tutorialDelete } = useAppSelector((state) => state.tutorial);

    function imageUrl(link : string) {
        let url : string;
        let urlparts: Array<string>;
        if (link.includes("watch?v=")) {
            urlparts = link.split("watch?v=");
            url = `https://img.youtube.com/vi/${urlparts[1]}/0.jpg`;
        } else if (link.includes("youtu.be/")) {
            urlparts = link.split("youtu.be/");
            urlparts = urlparts[1].split("?");
            url = `https://img.youtube.com/vi/${urlparts[0]}/0.jpg`;
        } else {
            url = player;
        }
        return `url(${url})`;
    }

    function defineModalInfos(title: string, description: string) {
        setModalOpen(!isModalOpen);
        setTitle(title);
        setDescription(description);
    }

    function defineDeleteModalInfos(id: number) {
        setModalDeleteOpen(true);
        setDeleteId(id);
    }

    async function deleteClick() {
        if (deleteId) {
            await dispatch(deleteTutorial(deleteId));
        }
    }

    useEffect(() => {
        setTutorials(listResponse);
    }, [listResponse]);

    useEffect(() => {
        if(!search) {
            setTutorials(listResponse);
        } else {
            let result = listResponse.filter((item) => item.title.toLowerCase().indexOf(search.toLowerCase()) != -1);
            setTutorials(result);
        }
    }, [search]);
    

    useEffect(() => {
        if (status === 'ready') {
            dispatch(listTutorial());
        }
    }, [status, tutorialDelete]);

    useEffect(() => {
        dispatch(listTutorial());
    }, [dispatch]);

    return (
        <div className='tutorialMain'>
            <Header />

            <h1>Tutoriais </h1>

            <Search 
                value={search} 
                setValue={setSearch}
                border={true}
            />

            <div className='listVideos'>
                {
                    tutorials && tutorials.map((video, index) => (
                        <div key={index} className='player' style={{ backgroundImage: imageUrl(video.url) }} >
                            <div className='playerconfig'>
                                <Edit onClick={() => navigate('/admin/tutorial-form/', { state : { id : video.id } })} />
                                <Delete onClick={() => defineDeleteModalInfos(video.id)} />
                            </div>
                            <a href={video.url} target='_black'>
                                <img src={youtube} alt="youtube logo" className='youtube' />
                            </a>
                            <div className='playerHeader'>
                                <h4>{video.title}</h4>
                                <img src={info} alt="info" onClick={() => defineModalInfos(video.title, video.description)} />
                            </div>
                        </div>
                    ))
                }
            </div>

            <DeleteModal
                isOpen={isModalDeleteOpen}
                onClose={() => setModalDeleteOpen(false)}
                onDelete={() => deleteClick()}
                text='Deseja realmente excluir o item selecionado?'
            />

            <SimpleModal isOpen={isModalOpen} onClose={() => { setModalOpen(!isModalOpen) }}>
                <h3>{title}</h3>
                <p>{description}</p>
            </SimpleModal>

            <button className='createTutorialButton' onClick={() => navigate('/admin/tutorial-form')} >
                <Add />
            </button>

            <Loading isOpen={ status === 'loading' } />
        </div>
    );
}

export default TutorialList;