import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import './tutorial.css';
 
import { Tutorial, listTutorial } from '../../slices/tutorialSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { getTinUrl } from '../../slices/tinSlice';
 
import info from '../../images/info.png';
import youtube from '../../images/youtube.png';
import player from '../../images/player.png';
 
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import Search from '../../components/Search/Search';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';
 
const TutorialList = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [search, setSearch] = useState('');
    const [tutorials, setTutorials] = useState([] as Tutorial[]);
    const [isModalOpen, setModalOpen] = useState(false);
 
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
 
    const { tinQueryId, getResponse } = useAppSelector((state) => state.tin);
    const { userProfile } = useAppSelector((state) => state.auth);
    const { userData, userMarket } = useAppSelector((state) => state.user);
    const { listResponse, status, tutorialDelete } = useAppSelector((state) => state.tutorial);
 
    function imageUrl(link : string) {
        let url : string;
        let urlparts: Array<string>;
        if (link.includes("watch?v=")) {
            urlparts = link.split("watch?v=");
            url = `https://img.youtube.com/vi/${urlparts[1]}/0.jpg`;
        } else if (link.includes("youtu.be/")) {
            urlparts = link.split("youtu.be/");
            urlparts = urlparts[1].split("?");
            url = `https://img.youtube.com/vi/${urlparts[0]}/0.jpg`;
        } else {
            url = player;
        }
        return `url(${url})`;
    }
 
    function defineModalInfos(title: string, description: string) {
        setModalOpen(!isModalOpen);
        setTitle(title);
        setDescription(description);
    }
 
    useEffect(() => {
        if(tinQueryId) {
            dispatch(getTinUrl(tinQueryId));
        }
    }, []);
 
    useEffect(() => {
        if (status === 'ready') {
            dispatch(listTutorial());
        }
    }, [status, tutorialDelete]);
 
    useEffect(() => {
        dispatch(listTutorial());
    }, [dispatch]);
 
    useEffect(() => {
        setTutorials(
            listResponse.filter((item) => item.profile === userProfile && (item.market === getResponse.market || item.market === userMarket))
        );
    }, [listResponse]);
 
    useEffect(() => {
        if (!search) {
            setTutorials(listResponse);
        } else {
            let result = listResponse.filter((item) => item.title.toLowerCase().indexOf(search.toLowerCase()) != -1);
            setTutorials(result);
        }
    }, [search]);
 
    return (
        <div className='tutorialMain'>
            <Header />
           
            <h1>Tutoriais</h1>
 
            <Search
                value={search}
                setValue={setSearch}
                border={true}
            />
 
            <div className='listVideos'>
                {
                    tutorials && tutorials.map((video, index) => (
                        userData && (tinQueryId || userMarket) ? (
                            <div key={index} className='player' style={{ backgroundImage: imageUrl(video.url) }} >
                                <a href={video.url} target='_black'>
                                    <img src={youtube} alt="youtube logo" className='youtube' />
                                </a>
                                <div className='playerHeader'>
                                    <h4>{video.title}</h4>
                                    <img src={info} alt="info" onClick={() => defineModalInfos(video.title, video.description)} />
                                </div>
                            </div>
                        ) : null
                    ))
                }
 
                {
                    tutorials.length === 0 ? (
                        <div style={{ marginBlock: '80px', marginInline: '20px', textAlign: 'center', color: '#A8A8A8' }} >
                            <span>Não existem vídeos tutoriais recomendados para o seu usuário!</span>
                        </div>
                    ) : null
                }
 
            </div>
 
            <Loading isOpen={ status === 'loading' } />
 
            <SimpleModal isOpen={isModalOpen} onClose={() => { setModalOpen(!isModalOpen) }}>
                <h3>{title}</h3>
                <p>{description}</p>
            </SimpleModal>
        </div>
    );
}
 
export default TutorialList;