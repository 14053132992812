import './deleteModal.css';

const DeleteModal = ({isOpen, onClose, onDelete, text} : {isOpen:boolean, onClose:Function, onDelete:Function, text:string}) => {
    return (
        <>
            {
                isOpen ? (
                    <div className='deleteModal'>
                        <div className='deleteModalBackground' onClick={() => onClose()} >
                            <div className='deleteModalContainer'>
                                <div className='deleteModalHeader'>
                                    <h5>Remover</h5>
                                </div>
                                <div className='deleteModalContent' >
                                    <p>{text}</p>
                                    <div className='deleteModalBtns' >
                                        <button style={{ background :  '#EBEBEB', color : '#A8A8A8' }} >
                                            Cancelar
                                        </button>
                                        <button onClick={() => onDelete()} style={{ background :  '#D02030' }} >
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}

export default DeleteModal;