import './faq.css'
import arrowDown from '../../images/arrow_down.png'
import arrowUp from '../../images/arrow_up.png'

const FaqExpand = ({ 
  faqOpen, 
  setFaqOpen, 
  title, 
  text, 
  id 
} : { 
  faqOpen: any, 
  setFaqOpen: any, 
  title: string, 
  text: string, 
  id: number 
}) => {

  function updateFaq(faqId: number) {
    if (faqOpen == faqId) {
      setFaqOpen(0);
    } else {
      setFaqOpen(faqId);
    }

  }

  return (
    <>
      <div onClick={() => { updateFaq(id) }} className="helpBtn" style={{ marginTop: 20, marginBottom: 20 }}>
        <span className="btnText">{title}</span>
        <img alt='ar1' className="arrow" src={faqOpen == id ? arrowUp : arrowDown} />
      </div>
      {
        faqOpen === id &&
        <div className="textHelp">
          {text}
          <br />
          <br />
        </div>
      }
    </>
  );
};

export default FaqExpand;
