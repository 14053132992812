const permissionsAuto = [
    { profile: 'Lojista', permissions: ['sac', 'conference', 'reward',] },
    { profile: 'Balconista', permissions: ['tutorial', 'reward', 'sac', 'conference'] },
]


const permissionsBroker = [
    { profile: 'Lojista', permissions: ['sac'] },
    { profile: 'Balconista', permissions: ['sac', 'tutorial', 'reward'] },
]

const permissionsCommon = [
    { profile: 'Colorista', permissions: ['tutorial', 'reward', 'sac', 'conference'] },
    { profile: 'Funileiro', permissions: ['tutorial', 'reward', 'sac', 'conference'] },
    { profile: 'Chefe de Oficina', permissions: ['tutorial', 'reward', 'sac', 'conference'] },
    { profile: 'Pintor', permissions: ['sac', 'tutorial', 'reward'] },
    { profile: 'Cliente', permissions: ['sac', 'tutorial'] },
]

export function buildPermissions(market, profile) {
    if (profile != 'Administrador' && profile !== "Promotor" && profile !== "Gestor" && profile) {
        if (profile !== "Balconista" && profile !== "Lojista") {
            return permissionsCommon.find(p => p.profile === profile).permissions;
        }

        if (market && market.toUpperCase() == 'AUTOMOTIVO') {
            return permissionsAuto.find(p => p.profile === profile).permissions;
        } else {
            return permissionsBroker.find(p => p.profile === profile).permissions;
        }
    } else {
        return null;
    }
}
