import './marketChoice.css'
import { useState, useEffect } from 'react';
import { Home, DirectionsCar } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import MenuOpt from '../../components/Header/MenuOpt';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { saveUserMarket } from '../../slices/userSlice';
import { saveQueryId } from '../../slices/tinSlice';
const autoProfiles = ['Lojista', 'Colorista', 'Funileiro', 'Chefe de Oficina', 'Balconista'];
const brokerProfiles = ['Lojista', 'Pintor', 'Cliente', 'Balconista'];

const MarketChoice = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [market, setMarket] = useState('');
    const { userProfile } = useAppSelector((state) => state.auth);
    const { userMarket } = useAppSelector((state) => state.user);
    const { tinQueryId } = useAppSelector((state) => state.tin);

    useEffect(() => {
        dispatch(saveUserMarket({ market: market }));
    }, [market]);

    useEffect(() => {
        if (userMarket) {
            navigate('/');
        }
    }, [userMarket]);

    useEffect(() => {
        let currentPath = window.location.href;
        if (currentPath.toUpperCase().indexOf("?ID=") !== -1) {
            let id = currentPath.toUpperCase().split('?ID=')[1];
            const resultId = id.replace(/\D/g, '');
            if (resultId) {
                dispatch(saveQueryId({ id: parseInt(resultId) }));
            }
        }
    }, []);

    useEffect(() => {
        if(tinQueryId != null && tinQueryId != undefined) {
            navigate('/');
        }
    }, [tinQueryId]);

    return (
        <div className='marketChoiceMain'>
            <div className='marketChoiceHeader' >
                <MenuOpt leftCloseSideBar='130px' />
            </div>
            <h1>Escolha seu </h1>
            <div className='marketChoiceContent'>

                {
                    userProfile && brokerProfiles.indexOf(userProfile) !== -1 &&
                    <div className='marketCard'>
                        <button onClick={() => setMarket('Imobiliário')}>
                            <Home />
                        </button>
                        <label>
                            Explorar o mercado imobiliário
                        </label>
                    </div>
                }

                {
                    userProfile && autoProfiles.indexOf(userProfile) !== -1 &&
                    <div className='marketCard'>
                        <button onClick={() => setMarket('Automotivo')}>
                            <DirectionsCar />
                        </button>
                        <label>
                            Explorar o mercado automotivo
                        </label>
                    </div>
                }

            </div>
            <label className='marketChoiceInformation'>
                Você efetuou login na plataforma sem realizar a leitura de um QRCode.
                Escolha o mercado que melhor condiz com o que você está procurando no momento!
            </label>
        </div>
    );
}

export default MarketChoice;


