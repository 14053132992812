const colors = {
    darkBlue: '#0C1A4F',
    lightBlue: '#0099E0',
    lightGrey: '#595858',
    red: "#D02030",
    infoTextGrey: "#A8A8A8",
    greyBackground: '#F7F7F7',
    menuOpts: '#F2F2F2',
    pinkRed: '#D01235',
    grey: '#D9D9D9',
    yellow: '#E1DA3F',
    green: '#28C95F'

}

export default colors;

