export const policie = `
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

        ol.lst-kix_list_7-0 {
            list-style-type: none
        }

        .lst-kix_list_14-1>li:before {
            content: "o  "
        }

        .lst-kix_list_14-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_2-1>li {
            counter-increment: lst-ctn-kix_list_2-1
        }

        ol.lst-kix_list_9-0.start {
            counter-reset: lst-ctn-kix_list_9-0 0
        }

        .lst-kix_list_14-0>li:before {
            content: "\\0025aa   "
        }

        .lst-kix_list_14-4>li:before {
            content: "o  "
        }

        .lst-kix_list_6-1>li {
            counter-increment: lst-ctn-kix_list_6-1
        }

        .lst-kix_list_14-5>li:before {
            content: "\\0025aa   "
        }

        .lst-kix_list_14-7>li:before {
            content: "o  "
        }

        .lst-kix_list_14-6>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_7-4.start {
            counter-reset: lst-ctn-kix_list_7-4 0
        }

        .lst-kix_list_5-0>li {
            counter-increment: lst-ctn-kix_list_5-0
        }

        .lst-kix_list_9-0>li {
            counter-increment: lst-ctn-kix_list_9-0
        }

        ol.lst-kix_list_2-3.start {
            counter-reset: lst-ctn-kix_list_2-3 0
        }

        ol.lst-kix_list_7-5 {
            list-style-type: none
        }

        ol.lst-kix_list_7-6 {
            list-style-type: none
        }

        ol.lst-kix_list_7-7 {
            list-style-type: none
        }

        ol.lst-kix_list_7-8 {
            list-style-type: none
        }

        ol.lst-kix_list_7-1 {
            list-style-type: none
        }

        ol.lst-kix_list_7-2 {
            list-style-type: none
        }

        ol.lst-kix_list_11-8.start {
            counter-reset: lst-ctn-kix_list_11-8 0
        }

        ol.lst-kix_list_7-3 {
            list-style-type: none
        }

        ol.lst-kix_list_7-4 {
            list-style-type: none
        }

        .lst-kix_list_14-2>li:before {
            content: "\\0025aa   "
        }

        ol.lst-kix_list_5-3.start {
            counter-reset: lst-ctn-kix_list_5-3 0
        }

        ol.lst-kix_list_12-0.start {
            counter-reset: lst-ctn-kix_list_12-0 0
        }

        .lst-kix_list_14-8>li:before {
            content: "\\0025aa   "
        }

        ol.lst-kix_list_8-8.start {
            counter-reset: lst-ctn-kix_list_8-8 0
        }

        ol.lst-kix_list_10-4.start {
            counter-reset: lst-ctn-kix_list_10-4 0
        }

        .lst-kix_list_7-2>li {
            counter-increment: lst-ctn-kix_list_7-2
        }

        .lst-kix_list_5-0>li:before {
            content: "(" counter(lst-ctn-kix_list_5-0, lower-roman) ") "
        }

        ol.lst-kix_list_6-0 {
            list-style-type: none
        }

        ol.lst-kix_list_6-1 {
            list-style-type: none
        }

        .lst-kix_list_5-4>li {
            counter-increment: lst-ctn-kix_list_5-4
        }

        .lst-kix_list_5-3>li:before {
            content: "" counter(lst-ctn-kix_list_5-3, decimal) ". "
        }

        ol.lst-kix_list_9-5.start {
            counter-reset: lst-ctn-kix_list_9-5 0
        }

        .lst-kix_list_5-2>li:before {
            content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". "
        }

        .lst-kix_list_8-3>li {
            counter-increment: lst-ctn-kix_list_8-3
        }

        .lst-kix_list_5-1>li:before {
            content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". "
        }

        .lst-kix_list_5-7>li:before {
            content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". "
        }

        .lst-kix_list_5-6>li:before {
            content: "" counter(lst-ctn-kix_list_5-6, decimal) ". "
        }

        .lst-kix_list_5-8>li:before {
            content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". "
        }

        .lst-kix_list_9-4>li {
            counter-increment: lst-ctn-kix_list_9-4
        }

        ol.lst-kix_list_6-6 {
            list-style-type: none
        }

        ol.lst-kix_list_6-7 {
            list-style-type: none
        }

        .lst-kix_list_5-4>li:before {
            content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". "
        }

        ol.lst-kix_list_6-8 {
            list-style-type: none
        }

        .lst-kix_list_5-5>li:before {
            content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". "
        }

        ol.lst-kix_list_6-2 {
            list-style-type: none
        }

        ol.lst-kix_list_6-3 {
            list-style-type: none
        }

        ol.lst-kix_list_6-4 {
            list-style-type: none
        }

        ol.lst-kix_list_6-5 {
            list-style-type: none
        }

        ol.lst-kix_list_12-5.start {
            counter-reset: lst-ctn-kix_list_12-5 0
        }

        .lst-kix_list_6-1>li:before {
            content: "" counter(lst-ctn-kix_list_6-1, lower-latin) ". "
        }

        .lst-kix_list_6-3>li:before {
            content: "" counter(lst-ctn-kix_list_6-3, decimal) ". "
        }

        .lst-kix_list_6-5>li {
            counter-increment: lst-ctn-kix_list_6-5
        }

        .lst-kix_list_6-8>li {
            counter-increment: lst-ctn-kix_list_6-8
        }

        .lst-kix_list_6-0>li:before {
            content: "(" counter(lst-ctn-kix_list_6-0, lower-roman) ") "
        }

        .lst-kix_list_6-4>li:before {
            content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". "
        }

        .lst-kix_list_6-2>li:before {
            content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". "
        }

        .lst-kix_list_2-5>li {
            counter-increment: lst-ctn-kix_list_2-5
        }

        .lst-kix_list_2-8>li {
            counter-increment: lst-ctn-kix_list_2-8
        }

        .lst-kix_list_6-8>li:before {
            content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". "
        }

        .lst-kix_list_6-5>li:before {
            content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". "
        }

        .lst-kix_list_6-7>li:before {
            content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". "
        }

        .lst-kix_list_6-6>li:before {
            content: "" counter(lst-ctn-kix_list_6-6, decimal) ". "
        }

        ol.lst-kix_list_10-6.start {
            counter-reset: lst-ctn-kix_list_10-6 0
        }

        .lst-kix_list_7-4>li:before {
            content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". "
        }

        .lst-kix_list_7-6>li:before {
            content: "" counter(lst-ctn-kix_list_7-6, decimal) ". "
        }

        ol.lst-kix_list_6-2.start {
            counter-reset: lst-ctn-kix_list_6-2 0
        }

        .lst-kix_list_7-2>li:before {
            content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". "
        }

        .lst-kix_list_7-6>li {
            counter-increment: lst-ctn-kix_list_7-6
        }

        .lst-kix_list_8-6>li {
            counter-increment: lst-ctn-kix_list_8-6
        }

        .lst-kix_list_12-6>li {
            counter-increment: lst-ctn-kix_list_12-6
        }

        ol.lst-kix_list_9-7 {
            list-style-type: none
        }

        ol.lst-kix_list_9-8 {
            list-style-type: none
        }

        .lst-kix_list_13-7>li:before {
            content: "o  "
        }

        ol.lst-kix_list_9-3 {
            list-style-type: none
        }

        ol.lst-kix_list_9-4 {
            list-style-type: none
        }

        .lst-kix_list_5-7>li {
            counter-increment: lst-ctn-kix_list_5-7
        }

        ol.lst-kix_list_9-5 {
            list-style-type: none
        }

        .lst-kix_list_7-8>li:before {
            content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". "
        }

        ol.lst-kix_list_9-6 {
            list-style-type: none
        }

        ol.lst-kix_list_9-0 {
            list-style-type: none
        }

        ol.lst-kix_list_9-1 {
            list-style-type: none
        }

        ol.lst-kix_list_9-2 {
            list-style-type: none
        }

        ol.lst-kix_list_2-5.start {
            counter-reset: lst-ctn-kix_list_2-5 0
        }

        .lst-kix_list_15-5>li:before {
            content: "\\0025aa   "
        }

        .lst-kix_list_9-8>li {
            counter-increment: lst-ctn-kix_list_9-8
        }

        .lst-kix_list_4-1>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_15-7>li:before {
            content: "o  "
        }

        .lst-kix_list_4-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_4-5>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_10-5>li {
            counter-increment: lst-ctn-kix_list_10-5
        }

        .lst-kix_list_15-1>li:before {
            content: "o  "
        }

        .lst-kix_list_15-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_6-4>li {
            counter-increment: lst-ctn-kix_list_6-4
        }

        ol.lst-kix_list_9-2.start {
            counter-reset: lst-ctn-kix_list_9-2 0
        }

        .lst-kix_list_9-3>li {
            counter-increment: lst-ctn-kix_list_9-3
        }

        .lst-kix_list_11-2>li {
            counter-increment: lst-ctn-kix_list_11-2
        }

        ol.lst-kix_list_2-8.start {
            counter-reset: lst-ctn-kix_list_2-8 0
        }

        ol.lst-kix_list_8-8 {
            list-style-type: none
        }

        .lst-kix_list_12-3>li:before {
            content: "" counter(lst-ctn-kix_list_12-3, decimal) ". "
        }

        ol.lst-kix_list_7-6.start {
            counter-reset: lst-ctn-kix_list_7-6 0
        }

        ol.lst-kix_list_8-4 {
            list-style-type: none
        }

        .lst-kix_list_12-1>li:before {
            content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". "
        }

        ol.lst-kix_list_8-5 {
            list-style-type: none
        }

        ol.lst-kix_list_8-6 {
            list-style-type: none
        }

        ol.lst-kix_list_8-7 {
            list-style-type: none
        }

        ol.lst-kix_list_8-0 {
            list-style-type: none
        }

        ol.lst-kix_list_8-1 {
            list-style-type: none
        }

        ol.lst-kix_list_8-2 {
            list-style-type: none
        }

        ol.lst-kix_list_8-3 {
            list-style-type: none
        }

        .lst-kix_list_10-4>li {
            counter-increment: lst-ctn-kix_list_10-4
        }

        .lst-kix_list_13-3>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_5-8.start {
            counter-reset: lst-ctn-kix_list_5-8 0
        }

        .lst-kix_list_13-5>li:before {
            content: "\\0025aa   "
        }

        .lst-kix_list_12-5>li:before {
            content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". "
        }

        ol.lst-kix_list_12-2.start {
            counter-reset: lst-ctn-kix_list_12-2 0
        }

        .lst-kix_list_12-7>li:before {
            content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". "
        }

        ol.lst-kix_list_6-0.start {
            counter-reset: lst-ctn-kix_list_6-0 0
        }

        .lst-kix_list_13-1>li:before {
            content: "o  "
        }

        .lst-kix_list_5-1>li {
            counter-increment: lst-ctn-kix_list_5-1
        }

        ol.lst-kix_list_11-6 {
            list-style-type: none
        }

        ol.lst-kix_list_11-7 {
            list-style-type: none
        }

        ol.lst-kix_list_11-8 {
            list-style-type: none
        }

        .lst-kix_list_7-1>li {
            counter-increment: lst-ctn-kix_list_7-1
        }

        ol.lst-kix_list_11-2 {
            list-style-type: none
        }

        ol.lst-kix_list_11-3 {
            list-style-type: none
        }

        ol.lst-kix_list_2-6.start {
            counter-reset: lst-ctn-kix_list_2-6 0
        }

        .lst-kix_list_3-0>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_11-4 {
            list-style-type: none
        }

        ol.lst-kix_list_11-5 {
            list-style-type: none
        }

        ol.lst-kix_list_11-0 {
            list-style-type: none
        }

        ol.lst-kix_list_11-1 {
            list-style-type: none
        }

        .lst-kix_list_8-0>li {
            counter-increment: lst-ctn-kix_list_8-0
        }

        .lst-kix_list_10-0>li {
            counter-increment: lst-ctn-kix_list_10-0
        }

        .lst-kix_list_3-4>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_3-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_8-0>li:before {
            content: "(" counter(lst-ctn-kix_list_8-0, lower-roman) ") "
        }

        .lst-kix_list_8-7>li:before {
            content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". "
        }

        .lst-kix_list_3-8>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_10-7.start {
            counter-reset: lst-ctn-kix_list_10-7 0
        }

        .lst-kix_list_8-3>li:before {
            content: "" counter(lst-ctn-kix_list_8-3, decimal) ". "
        }

        ul.lst-kix_list_13-5 {
            list-style-type: none
        }

        ul.lst-kix_list_13-4 {
            list-style-type: none
        }

        ul.lst-kix_list_13-3 {
            list-style-type: none
        }

        ul.lst-kix_list_13-2 {
            list-style-type: none
        }

        ul.lst-kix_list_13-1 {
            list-style-type: none
        }

        .lst-kix_list_3-7>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_list_13-0 {
            list-style-type: none
        }

        .lst-kix_list_8-4>li:before {
            content: "" counter(lst-ctn-kix_list_8-4, lower-latin) ". "
        }

        .lst-kix_list_10-2>li {
            counter-increment: lst-ctn-kix_list_10-2
        }

        ol.lst-kix_list_8-5.start {
            counter-reset: lst-ctn-kix_list_8-5 0
        }

        ul.lst-kix_list_13-8 {
            list-style-type: none
        }

        .lst-kix_list_11-1>li:before {
            content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". "
        }

        ul.lst-kix_list_13-7 {
            list-style-type: none
        }

        ul.lst-kix_list_13-6 {
            list-style-type: none
        }

        .lst-kix_list_11-0>li:before {
            content: "(" counter(lst-ctn-kix_list_11-0, lower-roman) ") "
        }

        ol.lst-kix_list_9-3.start {
            counter-reset: lst-ctn-kix_list_9-3 0
        }

        .lst-kix_list_8-8>li:before {
            content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". "
        }

        ol.lst-kix_list_2-2 {
            list-style-type: none
        }

        ol.lst-kix_list_2-3 {
            list-style-type: none
        }

        ol.lst-kix_list_2-4 {
            list-style-type: none
        }

        ol.lst-kix_list_7-2.start {
            counter-reset: lst-ctn-kix_list_7-2 0
        }

        ol.lst-kix_list_2-5 {
            list-style-type: none
        }

        ol.lst-kix_list_2-0 {
            list-style-type: none
        }

        ol.lst-kix_list_2-1 {
            list-style-type: none
        }

        .lst-kix_list_4-8>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_12-5 {
            list-style-type: none
        }

        ol.lst-kix_list_12-6 {
            list-style-type: none
        }

        .lst-kix_list_4-7>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_12-7 {
            list-style-type: none
        }

        ol.lst-kix_list_12-8 {
            list-style-type: none
        }

        ol.lst-kix_list_12-1 {
            list-style-type: none
        }

        ol.lst-kix_list_12-2 {
            list-style-type: none
        }

        ol.lst-kix_list_12-3 {
            list-style-type: none
        }

        ol.lst-kix_list_12-4 {
            list-style-type: none
        }

        ul.lst-kix_list_4-8 {
            list-style-type: none
        }

        ul.lst-kix_list_4-6 {
            list-style-type: none
        }

        ul.lst-kix_list_4-7 {
            list-style-type: none
        }

        ol.lst-kix_list_12-0 {
            list-style-type: none
        }

        .lst-kix_list_8-4>li {
            counter-increment: lst-ctn-kix_list_8-4
        }

        ul.lst-kix_list_4-0 {
            list-style-type: none
        }

        ul.lst-kix_list_4-1 {
            list-style-type: none
        }

        ol.lst-kix_list_10-8.start {
            counter-reset: lst-ctn-kix_list_10-8 0
        }

        ul.lst-kix_list_4-4 {
            list-style-type: none
        }

        ol.lst-kix_list_2-6 {
            list-style-type: none
        }

        ul.lst-kix_list_4-5 {
            list-style-type: none
        }

        ol.lst-kix_list_2-7 {
            list-style-type: none
        }

        ul.lst-kix_list_4-2 {
            list-style-type: none
        }

        ol.lst-kix_list_2-8 {
            list-style-type: none
        }

        ul.lst-kix_list_4-3 {
            list-style-type: none
        }

        .lst-kix_list_11-3>li {
            counter-increment: lst-ctn-kix_list_11-3
        }

        ol.lst-kix_list_7-1.start {
            counter-reset: lst-ctn-kix_list_7-1 0
        }

        ol.lst-kix_list_8-6.start {
            counter-reset: lst-ctn-kix_list_8-6 0
        }

        ol.lst-kix_list_8-0.start {
            counter-reset: lst-ctn-kix_list_8-0 0
        }

        .lst-kix_list_7-0>li:before {
            content: "(" counter(lst-ctn-kix_list_7-0, lower-roman) ") "
        }

        .lst-kix_list_2-2>li {
            counter-increment: lst-ctn-kix_list_2-2
        }

        ol.lst-kix_list_5-0 {
            list-style-type: none
        }

        ol.lst-kix_list_5-1 {
            list-style-type: none
        }

        ol.lst-kix_list_9-7.start {
            counter-reset: lst-ctn-kix_list_9-7 0
        }

        ol.lst-kix_list_5-2 {
            list-style-type: none
        }

        .lst-kix_list_2-4>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) " "
        }

        .lst-kix_list_2-8>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) "." counter(lst-ctn-kix_list_2-6, decimal) "." counter(lst-ctn-kix_list_2-7, decimal) "." counter(lst-ctn-kix_list_2-8, decimal) " "
        }

        .lst-kix_list_6-6>li {
            counter-increment: lst-ctn-kix_list_6-6
        }

        .lst-kix_list_7-3>li:before {
            content: "" counter(lst-ctn-kix_list_7-3, decimal) ". "
        }

        .lst-kix_list_10-0>li:before {
            content: "(" counter(lst-ctn-kix_list_10-0, lower-roman) ") "
        }

        .lst-kix_list_9-7>li {
            counter-increment: lst-ctn-kix_list_9-7
        }

        .lst-kix_list_13-8>li:before {
            content: "\\0025aa   "
        }

        ol.lst-kix_list_5-7 {
            list-style-type: none
        }

        ol.lst-kix_list_5-8 {
            list-style-type: none
        }

        ol.lst-kix_list_5-3 {
            list-style-type: none
        }

        .lst-kix_list_8-7>li {
            counter-increment: lst-ctn-kix_list_8-7
        }

        ol.lst-kix_list_5-4 {
            list-style-type: none
        }

        ol.lst-kix_list_5-5 {
            list-style-type: none
        }

        ol.lst-kix_list_5-6 {
            list-style-type: none
        }

        .lst-kix_list_7-7>li:before {
            content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". "
        }

        ol.lst-kix_list_8-1.start {
            counter-reset: lst-ctn-kix_list_8-1 0
        }

        .lst-kix_list_15-4>li:before {
            content: "o  "
        }

        .lst-kix_list_9-5>li {
            counter-increment: lst-ctn-kix_list_9-5
        }

        .lst-kix_list_5-8>li {
            counter-increment: lst-ctn-kix_list_5-8
        }

        .lst-kix_list_10-4>li:before {
            content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". "
        }

        .lst-kix_list_10-8>li:before {
            content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". "
        }

        .lst-kix_list_4-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_list_15-3 {
            list-style-type: none
        }

        ul.lst-kix_list_15-2 {
            list-style-type: none
        }

        .lst-kix_list_15-0>li:before {
            content: "\\0025aa   "
        }

        ul.lst-kix_list_15-1 {
            list-style-type: none
        }

        .lst-kix_list_15-8>li:before {
            content: "\\0025aa   "
        }

        ul.lst-kix_list_15-0 {
            list-style-type: none
        }

        .lst-kix_list_4-4>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_2-2.start {
            counter-reset: lst-ctn-kix_list_2-2 0
        }

        ul.lst-kix_list_15-8 {
            list-style-type: none
        }

        ul.lst-kix_list_15-7 {
            list-style-type: none
        }

        ul.lst-kix_list_15-6 {
            list-style-type: none
        }

        .lst-kix_list_9-3>li:before {
            content: "" counter(lst-ctn-kix_list_9-3, decimal) ". "
        }

        ul.lst-kix_list_15-5 {
            list-style-type: none
        }

        ul.lst-kix_list_15-4 {
            list-style-type: none
        }

        ol.lst-kix_list_7-0.start {
            counter-reset: lst-ctn-kix_list_7-0 0
        }

        .lst-kix_list_12-8>li {
            counter-increment: lst-ctn-kix_list_12-8
        }

        .lst-kix_list_9-7>li:before {
            content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". "
        }

        .lst-kix_list_2-4>li {
            counter-increment: lst-ctn-kix_list_2-4
        }

        .lst-kix_list_11-4>li:before {
            content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". "
        }

        .lst-kix_list_12-4>li:before {
            content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". "
        }

        .lst-kix_list_5-3>li {
            counter-increment: lst-ctn-kix_list_5-3
        }

        .lst-kix_list_7-4>li {
            counter-increment: lst-ctn-kix_list_7-4
        }

        .lst-kix_list_1-0>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_11-8>li:before {
            content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". "
        }

        .lst-kix_list_12-3>li {
            counter-increment: lst-ctn-kix_list_12-3
        }

        ol.lst-kix_list_2-0.start {
            counter-reset: lst-ctn-kix_list_2-0 0
        }

        .lst-kix_list_12-0>li:before {
            content: "(" counter(lst-ctn-kix_list_12-0, lower-roman) ") "
        }

        ol.lst-kix_list_8-4.start {
            counter-reset: lst-ctn-kix_list_8-4 0
        }

        .lst-kix_list_1-4>li:before {
            content: "o  "
        }

        .lst-kix_list_13-0>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_list_14-4 {
            list-style-type: none
        }

        ul.lst-kix_list_14-3 {
            list-style-type: none
        }

        ul.lst-kix_list_14-2 {
            list-style-type: none
        }

        .lst-kix_list_13-4>li:before {
            content: "o  "
        }

        ul.lst-kix_list_14-1 {
            list-style-type: none
        }

        ul.lst-kix_list_14-0 {
            list-style-type: none
        }

        .lst-kix_list_10-7>li {
            counter-increment: lst-ctn-kix_list_10-7
        }

        ul.lst-kix_list_14-8 {
            list-style-type: none
        }

        ul.lst-kix_list_14-7 {
            list-style-type: none
        }

        .lst-kix_list_2-0>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) ". "
        }

        ol.lst-kix_list_2-1.start {
            counter-reset: lst-ctn-kix_list_2-1 0
        }

        ol.lst-kix_list_8-3.start {
            counter-reset: lst-ctn-kix_list_8-3 0
        }

        ul.lst-kix_list_14-6 {
            list-style-type: none
        }

        .lst-kix_list_11-5>li {
            counter-increment: lst-ctn-kix_list_11-5
        }

        ul.lst-kix_list_14-5 {
            list-style-type: none
        }

        ol.lst-kix_list_9-8.start {
            counter-reset: lst-ctn-kix_list_9-8 0
        }

        .lst-kix_list_1-8>li:before {
            content: "\\0025aa   "
        }

        .lst-kix_list_12-8>li:before {
            content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". "
        }

        .lst-kix_list_8-2>li {
            counter-increment: lst-ctn-kix_list_8-2
        }

        ul.lst-kix_list_1-0 {
            list-style-type: none
        }

        ol.lst-kix_list_12-6.start {
            counter-reset: lst-ctn-kix_list_12-6 0
        }

        .lst-kix_list_8-1>li {
            counter-increment: lst-ctn-kix_list_8-1
        }

        ol.lst-kix_list_8-2.start {
            counter-reset: lst-ctn-kix_list_8-2 0
        }

        ol.lst-kix_list_6-6.start {
            counter-reset: lst-ctn-kix_list_6-6 0
        }

        .lst-kix_list_7-0>li {
            counter-increment: lst-ctn-kix_list_7-0
        }

        .lst-kix_list_11-0>li {
            counter-increment: lst-ctn-kix_list_11-0
        }

        ul.lst-kix_list_1-3 {
            list-style-type: none
        }

        ul.lst-kix_list_1-4 {
            list-style-type: none
        }

        ul.lst-kix_list_1-1 {
            list-style-type: none
        }

        ul.lst-kix_list_1-2 {
            list-style-type: none
        }

        ul.lst-kix_list_1-7 {
            list-style-type: none
        }

        ul.lst-kix_list_1-8 {
            list-style-type: none
        }

        ul.lst-kix_list_1-5 {
            list-style-type: none
        }

        ul.lst-kix_list_1-6 {
            list-style-type: none
        }

        ol.lst-kix_list_9-6.start {
            counter-reset: lst-ctn-kix_list_9-6 0
        }

        .lst-kix_list_2-3>li {
            counter-increment: lst-ctn-kix_list_2-3
        }

        ol.lst-kix_list_11-2.start {
            counter-reset: lst-ctn-kix_list_11-2 0
        }

        .lst-kix_list_5-2>li {
            counter-increment: lst-ctn-kix_list_5-2
        }

        .lst-kix_list_9-2>li {
            counter-increment: lst-ctn-kix_list_9-2
        }

        ol.lst-kix_list_8-7.start {
            counter-reset: lst-ctn-kix_list_8-7 0
        }

        .lst-kix_list_10-3>li {
            counter-increment: lst-ctn-kix_list_10-3
        }

        .lst-kix_list_12-1>li {
            counter-increment: lst-ctn-kix_list_12-1
        }

        ol.lst-kix_list_11-7.start {
            counter-reset: lst-ctn-kix_list_11-7 0
        }

        ol.lst-kix_list_2-4.start {
            counter-reset: lst-ctn-kix_list_2-4 0
        }

        .lst-kix_list_2-7>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) "." counter(lst-ctn-kix_list_2-6, decimal) "." counter(lst-ctn-kix_list_2-7, decimal) " "
        }

        .lst-kix_list_2-7>li {
            counter-increment: lst-ctn-kix_list_2-7
        }

        .lst-kix_list_2-5>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) " "
        }

        ol.lst-kix_list_10-3.start {
            counter-reset: lst-ctn-kix_list_10-3 0
        }

        ol.lst-kix_list_9-4.start {
            counter-reset: lst-ctn-kix_list_9-4 0
        }

        ul.lst-kix_list_3-7 {
            list-style-type: none
        }

        ul.lst-kix_list_3-8 {
            list-style-type: none
        }

        .lst-kix_list_10-1>li:before {
            content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". "
        }

        ul.lst-kix_list_3-1 {
            list-style-type: none
        }

        ul.lst-kix_list_3-2 {
            list-style-type: none
        }

        .lst-kix_list_7-7>li {
            counter-increment: lst-ctn-kix_list_7-7
        }

        ul.lst-kix_list_3-0 {
            list-style-type: none
        }

        ul.lst-kix_list_3-5 {
            list-style-type: none
        }

        ul.lst-kix_list_3-6 {
            list-style-type: none
        }

        ul.lst-kix_list_3-3 {
            list-style-type: none
        }

        ul.lst-kix_list_3-4 {
            list-style-type: none
        }

        .lst-kix_list_10-7>li:before {
            content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". "
        }

        .lst-kix_list_7-8>li {
            counter-increment: lst-ctn-kix_list_7-8
        }

        .lst-kix_list_10-5>li:before {
            content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". "
        }

        li.li-bullet-1:before {
            margin-left: -21.8pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 21.8pt
        }

        .lst-kix_list_10-3>li:before {
            content: "" counter(lst-ctn-kix_list_10-3, decimal) ". "
        }

        .lst-kix_list_2-6>li {
            counter-increment: lst-ctn-kix_list_2-6
        }

        ol.lst-kix_list_7-3.start {
            counter-reset: lst-ctn-kix_list_7-3 0
        }

        .lst-kix_list_11-7>li {
            counter-increment: lst-ctn-kix_list_11-7
        }

        .lst-kix_list_9-2>li:before {
            content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". "
        }

        ol.lst-kix_list_5-7.start {
            counter-reset: lst-ctn-kix_list_5-7 0
        }

        .lst-kix_list_12-5>li {
            counter-increment: lst-ctn-kix_list_12-5
        }

        .lst-kix_list_5-5>li {
            counter-increment: lst-ctn-kix_list_5-5
        }

        .lst-kix_list_9-0>li:before {
            content: "(" counter(lst-ctn-kix_list_9-0, lower-roman) ") "
        }

        ol.lst-kix_list_10-7 {
            list-style-type: none
        }

        .lst-kix_list_9-6>li:before {
            content: "" counter(lst-ctn-kix_list_9-6, decimal) ". "
        }

        ol.lst-kix_list_10-8 {
            list-style-type: none
        }

        ol.lst-kix_list_10-3 {
            list-style-type: none
        }

        .lst-kix_list_9-4>li:before {
            content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". "
        }

        ol.lst-kix_list_10-4 {
            list-style-type: none
        }

        ol.lst-kix_list_10-5 {
            list-style-type: none
        }

        .lst-kix_list_11-3>li:before {
            content: "" counter(lst-ctn-kix_list_11-3, decimal) ". "
        }

        ol.lst-kix_list_10-6 {
            list-style-type: none
        }

        .lst-kix_list_6-3>li {
            counter-increment: lst-ctn-kix_list_6-3
        }

        ol.lst-kix_list_10-0 {
            list-style-type: none
        }

        ol.lst-kix_list_10-1 {
            list-style-type: none
        }

        ol.lst-kix_list_10-2 {
            list-style-type: none
        }

        ol.lst-kix_list_12-1.start {
            counter-reset: lst-ctn-kix_list_12-1 0
        }

        .lst-kix_list_11-5>li:before {
            content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". "
        }

        ol.lst-kix_list_6-1.start {
            counter-reset: lst-ctn-kix_list_6-1 0
        }

        .lst-kix_list_9-8>li:before {
            content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". "
        }

        .lst-kix_list_1-1>li:before {
            content: "o  "
        }

        .lst-kix_list_11-7>li:before {
            content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". "
        }

        .lst-kix_list_8-5>li {
            counter-increment: lst-ctn-kix_list_8-5
        }

        .lst-kix_list_1-3>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_10-5.start {
            counter-reset: lst-ctn-kix_list_10-5 0
        }

        .lst-kix_list_1-7>li:before {
            content: "o  "
        }

        ol.lst-kix_list_2-7.start {
            counter-reset: lst-ctn-kix_list_2-7 0
        }

        .lst-kix_list_1-5>li:before {
            content: "\\0025aa   "
        }

        ol.lst-kix_list_9-1.start {
            counter-reset: lst-ctn-kix_list_9-1 0
        }

        .lst-kix_list_5-6>li {
            counter-increment: lst-ctn-kix_list_5-6
        }

        ol.lst-kix_list_7-5.start {
            counter-reset: lst-ctn-kix_list_7-5 0
        }

        .lst-kix_list_2-1>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) " "
        }

        .lst-kix_list_2-3>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) " "
        }

        .lst-kix_list_11-8>li {
            counter-increment: lst-ctn-kix_list_11-8
        }

        .lst-kix_list_9-1>li {
            counter-increment: lst-ctn-kix_list_9-1
        }

        ol.lst-kix_list_7-7.start {
            counter-reset: lst-ctn-kix_list_7-7 0
        }

        .lst-kix_list_3-1>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_3-2>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_8-1>li:before {
            content: "" counter(lst-ctn-kix_list_8-1, lower-latin) ". "
        }

        .lst-kix_list_8-2>li:before {
            content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ". "
        }

        .lst-kix_list_6-0>li {
            counter-increment: lst-ctn-kix_list_6-0
        }

        .lst-kix_list_3-5>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_12-0>li {
            counter-increment: lst-ctn-kix_list_12-0
        }

        ol.lst-kix_list_12-3.start {
            counter-reset: lst-ctn-kix_list_12-3 0
        }

        ol.lst-kix_list_11-5.start {
            counter-reset: lst-ctn-kix_list_11-5 0
        }

        .lst-kix_list_8-5>li:before {
            content: "" counter(lst-ctn-kix_list_8-5, lower-roman) ". "
        }

        .lst-kix_list_11-1>li {
            counter-increment: lst-ctn-kix_list_11-1
        }

        .lst-kix_list_8-6>li:before {
            content: "" counter(lst-ctn-kix_list_8-6, decimal) ". "
        }

        .lst-kix_list_2-0>li {
            counter-increment: lst-ctn-kix_list_2-0
        }

        .lst-kix_list_3-6>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_5-0.start {
            counter-reset: lst-ctn-kix_list_5-0 0
        }

        .lst-kix_list_11-2>li:before {
            content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". "
        }

        ol.lst-kix_list_11-6.start {
            counter-reset: lst-ctn-kix_list_11-6 0
        }

        ol.lst-kix_list_12-4.start {
            counter-reset: lst-ctn-kix_list_12-4 0
        }

        ol.lst-kix_list_10-1.start {
            counter-reset: lst-ctn-kix_list_10-1 0
        }

        ol.lst-kix_list_6-4.start {
            counter-reset: lst-ctn-kix_list_6-4 0
        }

        ol.lst-kix_list_5-6.start {
            counter-reset: lst-ctn-kix_list_5-6 0
        }

        .lst-kix_list_7-3>li {
            counter-increment: lst-ctn-kix_list_7-3
        }

        ol.lst-kix_list_7-8.start {
            counter-reset: lst-ctn-kix_list_7-8 0
        }

        .lst-kix_list_12-4>li {
            counter-increment: lst-ctn-kix_list_12-4
        }

        ol.lst-kix_list_11-0.start {
            counter-reset: lst-ctn-kix_list_11-0 0
        }

        ol.lst-kix_list_6-3.start {
            counter-reset: lst-ctn-kix_list_6-3 0
        }

        ol.lst-kix_list_10-2.start {
            counter-reset: lst-ctn-kix_list_10-2 0
        }

        .lst-kix_list_12-7>li {
            counter-increment: lst-ctn-kix_list_12-7
        }

        ol.lst-kix_list_5-5.start {
            counter-reset: lst-ctn-kix_list_5-5 0
        }

        .lst-kix_list_6-2>li {
            counter-increment: lst-ctn-kix_list_6-2
        }

        .lst-kix_list_2-6>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) "." counter(lst-ctn-kix_list_2-3, decimal) "." counter(lst-ctn-kix_list_2-4, decimal) "." counter(lst-ctn-kix_list_2-5, decimal) "." counter(lst-ctn-kix_list_2-6, decimal) " "
        }

        .lst-kix_list_7-1>li:before {
            content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". "
        }

        .lst-kix_list_7-5>li:before {
            content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". "
        }

        .lst-kix_list_9-6>li {
            counter-increment: lst-ctn-kix_list_9-6
        }

        ol.lst-kix_list_5-4.start {
            counter-reset: lst-ctn-kix_list_5-4 0
        }

        ol.lst-kix_list_11-1.start {
            counter-reset: lst-ctn-kix_list_11-1 0
        }

        .lst-kix_list_13-6>li:before {
            content: "\\0025cf   "
        }

        ol.lst-kix_list_5-1.start {
            counter-reset: lst-ctn-kix_list_5-1 0
        }

        .lst-kix_list_6-7>li {
            counter-increment: lst-ctn-kix_list_6-7
        }

        .lst-kix_list_10-6>li {
            counter-increment: lst-ctn-kix_list_10-6
        }

        .lst-kix_list_11-6>li {
            counter-increment: lst-ctn-kix_list_11-6
        }

        ol.lst-kix_list_10-0.start {
            counter-reset: lst-ctn-kix_list_10-0 0
        }

        .lst-kix_list_7-5>li {
            counter-increment: lst-ctn-kix_list_7-5
        }

        .lst-kix_list_15-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_11-4>li {
            counter-increment: lst-ctn-kix_list_11-4
        }

        ol.lst-kix_list_6-8.start {
            counter-reset: lst-ctn-kix_list_6-8 0
        }

        .lst-kix_list_10-2>li:before {
            content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". "
        }

        ol.lst-kix_list_6-5.start {
            counter-reset: lst-ctn-kix_list_6-5 0
        }

        .lst-kix_list_4-2>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_4-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_list_15-2>li:before {
            content: "\\0025aa   "
        }

        .lst-kix_list_10-8>li {
            counter-increment: lst-ctn-kix_list_10-8
        }

        .lst-kix_list_10-6>li:before {
            content: "" counter(lst-ctn-kix_list_10-6, decimal) ". "
        }

        .lst-kix_list_9-1>li:before {
            content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". "
        }

        ol.lst-kix_list_12-7.start {
            counter-reset: lst-ctn-kix_list_12-7 0
        }

        ol.lst-kix_list_6-7.start {
            counter-reset: lst-ctn-kix_list_6-7 0
        }

        .lst-kix_list_12-2>li {
            counter-increment: lst-ctn-kix_list_12-2
        }

        .lst-kix_list_9-5>li:before {
            content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". "
        }

        .lst-kix_list_12-2>li:before {
            content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". "
        }

        ol.lst-kix_list_12-8.start {
            counter-reset: lst-ctn-kix_list_12-8 0
        }

        .lst-kix_list_11-6>li:before {
            content: "" counter(lst-ctn-kix_list_11-6, decimal) ". "
        }

        ol.lst-kix_list_11-3.start {
            counter-reset: lst-ctn-kix_list_11-3 0
        }

        .lst-kix_list_1-2>li:before {
            content: "\\0025aa   "
        }

        .lst-kix_list_10-1>li {
            counter-increment: lst-ctn-kix_list_10-1
        }

        .lst-kix_list_8-8>li {
            counter-increment: lst-ctn-kix_list_8-8
        }

        .lst-kix_list_1-6>li:before {
            content: "\\0025cf   "
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        .lst-kix_list_12-6>li:before {
            content: "" counter(lst-ctn-kix_list_12-6, decimal) ". "
        }

        ol.lst-kix_list_11-4.start {
            counter-reset: lst-ctn-kix_list_11-4 0
        }

        .lst-kix_list_2-2>li:before {
            content: "" counter(lst-ctn-kix_list_2-0, upper-roman) "." counter(lst-ctn-kix_list_2-1, decimal) "." counter(lst-ctn-kix_list_2-2, decimal) " "
        }

        .lst-kix_list_13-2>li:before {
            content: "\\0025aa   "
        }

        ol.lst-kix_list_5-2.start {
            counter-reset: lst-ctn-kix_list_5-2 0
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c20 {
            border-right-style: solid;
            padding: 0pt 5.4pt 0pt 5.4pt;
            border-bottom-color: #2a2a44;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #2a2a44;
            vertical-align: middle;
            border-right-color: #2a2a44;
            border-left-width: 1pt;
            border-top-style: solid;
            background-color: #f2f2f2;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 141.8pt;
            border-top-color: #2a2a44;
            border-bottom-style: solid
        }

        .c36 {
            border-right-style: solid;
            padding: 0pt 5.4pt 0pt 5.4pt;
            border-bottom-color: #2a2a44;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #2a2a44;
            vertical-align: middle;
            border-right-color: #2a2a44;
            border-left-width: 1pt;
            border-top-style: solid;
            background-color: #c0504d;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 283.4pt;
            border-top-color: #2a2a44;
            border-bottom-style: solid
        }

        .c29 {
            border-right-style: solid;
            padding: 0pt 5.4pt 0pt 5.4pt;
            border-bottom-color: #2a2a44;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #2a2a44;
            vertical-align: middle;
            border-right-color: #2a2a44;
            border-left-width: 1pt;
            border-top-style: solid;
            background-color: #c0504d;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 141.8pt;
            border-top-color: #2a2a44;
            border-bottom-style: solid
        }

        .c14 {
            border-right-style: solid;
            padding: 0pt 5.4pt 0pt 5.4pt;
            border-bottom-color: #2a2a44;
            border-top-width: 1pt;
            border-right-width: 1pt;
            border-left-color: #2a2a44;
            vertical-align: middle;
            border-right-color: #2a2a44;
            border-left-width: 1pt;
            border-top-style: solid;
            background-color: #ffffff;
            border-left-style: solid;
            border-bottom-width: 1pt;
            width: 283.4pt;
            border-top-color: #2a2a44;
            border-bottom-style: solid
        }

        .c8 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 10pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c27 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c1 {
            background-color: #ffffff;
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify;
            height: 11pt
        }

        .c28 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c3 {
            margin-left: 36pt;
            padding-top: 0pt;
            padding-left: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c48 {
            padding-top: 0pt;
            border-bottom-width: 3pt;
            padding-bottom: 1pt;
            line-height: 1.1500000000000001;
            border-bottom-style: dotted;
            text-align: justify
        }

        .c18 {
            color: #ffffff;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Calibri";
            font-style: normal
        }

        .c11 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Calibri";
            font-style: normal
        }

        .c22 {
            -webkit-text-decoration-skip: none;
            font-weight: 400;
            text-decoration: underline;
            vertical-align: baseline;
            text-decoration-skip-ink: none;
            font-family: "Calibri";
            font-style: normal
        }

        .c0 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Calibri";
            font-style: normal
        }

        .c4 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c12 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Calibri";
            font-style: normal
        }

        .c2 {
            background-color: #ffffff;
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c6 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Calibri";
            font-style: italic
        }

        .c9 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Calibri";
            font-style: normal
        }

        .c5 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: justify
        }

        .c41 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c7 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1500000000000001;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c30 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c23 {
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c40 {
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Calibri";
            font-style: normal
        }

        .c35 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.0;
            text-align: left
        }

        .c37 {
            border-spacing: 0;
            border-collapse: collapse;
            margin-right: auto
        }

        .c33 {
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-family: "Calibri"
        }

        .c50 {
            margin-left: 32.2pt;
            padding-left: 3.8pt
        }

        .c19 {
            border: 1px solid black;
            margin: 5px
        }

        .c47 {
            max-width: 425.2pt;
            padding: 70.9pt 85pt 70.9pt 85pt
        }

        .c43 {
            orphans: 2;
            widows: 2
        }

        .c21 {
            padding: 0;
            margin: 0
        }

        .c32 {
            margin-left: 35.4pt;
            text-indent: -35.4pt
        }

        .c34 {
            height: 36.7pt
        }

        .c26 {
            font-weight: 700
        }

        .c44 {
            height: 29.5pt
        }

        .c10 {
            height: 30.4pt
        }

        .c31 {
            height: 38.7pt
        }

        .c16 {
            color: #000000
        }

        .c49 {
            margin-left: 18pt
        }

        .c42 {
            color: #ffffff
        }

        .c13 {
            height: 55.9pt
        }

        .c24 {
            font-style: italic
        }

        .c39 {
            height: 11pt
        }

        .c38 {
            font-size: 11pt
        }

        .c45 {
            margin-left: 36pt
        }

        .c17 {
            font-size: 12pt
        }

        .c15 {
            background-color: #ffffff
        }

        .c25 {
            height: 27pt
        }

        .c46 {
            margin-left: 17.9pt
        }

        .title {
            padding-top: 24pt;
            color: #000000;
            font-weight: 700;
            font-size: 36pt;
            padding-bottom: 6pt;
            font-family: "Calibri";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 18pt;
            color: #666666;
            font-size: 24pt;
            padding-bottom: 4pt;
            font-family: "Georgia";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Calibri"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Calibri"
        }

        h1 {
            padding-top: 24pt;
            color: #000000;
            font-weight: 700;
            font-size: 24pt;
            padding-bottom: 6pt;
            font-family: "Calibri";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-weight: 700;
            font-size: 18pt;
            padding-bottom: 4pt;
            font-family: "Calibri";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 14pt;
            color: #000000;
            font-weight: 700;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Calibri";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 12pt;
            color: #000000;
            font-weight: 700;
            font-size: 12pt;
            padding-bottom: 2pt;
            font-family: "Calibri";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 11pt;
            color: #000000;
            font-weight: 700;
            font-size: 11pt;
            padding-bottom: 2pt;
            font-family: "Calibri";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 10pt;
            color: #000000;
            font-weight: 700;
            font-size: 10pt;
            padding-bottom: 2pt;
            font-family: "Calibri";
            line-height: 1.1500000000000001;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c15 c47 doc-content">
    <div>
        <p class="c35 c43 c39"><span class="c9"></span></p>
        <p class="c35 c39 c43"><span class="c9"></span></p>
    </div>
    <p class="c2"><span class="c26 c16 c40">POL&Iacute;TICA DE PRIVACIDADE</span></p>
    <p class="c2" id="h.gjdgxs"><span class="c0">Atualizada em 19 de janeiro de 2024</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15" id="h.30j0zll"><span class="c0">Caro usu&aacute;rio, obrigada por nos visitar.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15" id="h.1fob9te"><span class="c0">N&oacute;s, da </span><span class="c12">CMP &ndash; METALGRAPHICA
            PAULISTA LTDA</span><span class="c0">, com sede na Av. Ribeir&atilde;o dos Cristais, 500 Sala
            01,</span><span class="c16 c17 c23">&nbsp;</span><span class="c0">Bairro: Tabo&atilde;o CEP: 07775-240
            Cidade: Cajamar SP inscrita no CNPJ/MF sob o n&ordm; 61.161.139/0001-01 (&ldquo;</span><span
            class="c22 c16 c17">Empresa</span><span class="c0">&rdquo; ou &ldquo;</span><span
            class="c22 c16 c17">n&oacute;s</span><span class="c0">&rdquo;)</span><sup><a href="#cmnt1"
                id="cmnt_ref1">[a]</a></sup><span class="c0">, reconhecemos a import&acirc;ncia de proteger a sua
            privacidade e levamos essa obriga&ccedil;&atilde;o a s&eacute;rio, cumprindo ou excedendo as
            regulamenta&ccedil;&otilde;es de privacidade promulgadas nos termos da legisla&ccedil;&atilde;o brasileira,
            incluindo, mas n&atilde;o se limitando, &agrave; Lei Geral de Prote&ccedil;&atilde;o de Dados, Lei n&ordm;
            13.709/2018 (&ldquo;</span><span class="c16 c17 c22">LGPD</span><span class="c0">&rdquo;).</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Por esta raz&atilde;o, preparamos esta Pol&iacute;tica de Privacidade, que
            explica como tratamos os seus dados pessoais quando voc&ecirc; navega em nossa plataforma e quando utiliza
            os servi&ccedil;os que oferecemos.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">POR FAVOR, LEIA ATENTAMENTE A PRESENTE POL&Iacute;TICA, POIS O ACESSO &Agrave;
            PLATAFORMA IMPLICA AO USU&Aacute;RIO A ACEITA&Ccedil;&Atilde;O, SEM LIMITA&Ccedil;&Atilde;O OU
            QUALIFICA&Ccedil;&Atilde;O, DOS SEUS TERMOS E CONDI&Ccedil;&Otilde;ES. </span></p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0 start" start="1">
        <li class="c3 c15 li-bullet-0"><span class="c12">DEFINI&Ccedil;&Otilde;ES:</span></li>
    </ol>
    <p class="c1 c45"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">Para facilitar a leitura e compreens&atilde;o desta Pol&iacute;tica de
            Privacidade, seguem algumas defini&ccedil;&otilde;es importantes de termos que aparecer&atilde;o por
            aqui.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5"><span class="c26 c17">Dados Pessoais:</span><span class="c0">&nbsp;&Eacute; uma
            informa&ccedil;&atilde;o relacionada a uma pessoa f&iacute;sica e que seja capaz de identificar a pessoa ou
            tornar poss&iacute;vel a sua identifica&ccedil;&atilde;o. S&atilde;o exemplos de dados pessoais que podem
            permitir a sua identifica&ccedil;&atilde;o: Nome, CPF, RG, telefone, e-mail, etc.</span></p>
    <p class="c5"><span class="c26 c17">Tratamento</span><span class="c0">: &Eacute; toda forma de uso que podemos fazer
            dos seus Dados Pessoais, incluindo, mas n&atilde;o se limitando &agrave;s seguintes atividades: coleta,
            armazenamento, consulta, uso pr&oacute;prio e por terceiros, compartilhamento, transmiss&atilde;o,
            classifica&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, exclus&atilde;o, comercializa&ccedil;&atilde;o para
            terceiros e avalia&ccedil;&atilde;o. Este termo (e outros derivados dele) n&atilde;o ser&atilde;o utilizados
            com letras mai&uacute;sculas neste documento.</span></p>
    <p class="c5"><span class="c26 c17">Titular</span><span class="c0">: &Eacute; voc&ecirc;, a pessoa f&iacute;sica a
            quem os Dados Pessoais se referem.</span></p>
    <p class="c5"><span class="c26 c17">Empresa</span><span class="c17">: Somos n&oacute;s, a </span><span
            class="c17">[NOME EMPRESARIAL]</span><sup><a href="#cmnt2" id="cmnt_ref2">[b]</a></sup><span
            class="c0">.</span></p>
    <p class="c5"><span class="c17 c26">Pol&iacute;tica</span><span class="c0">: Esta pol&iacute;tica de
            privacidade.</span></p>
    <p class="c5 c39"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="2">
        <li class="c3 c15 li-bullet-0"><span class="c12">QUE TIPO DE DADOS PESSOAIS A EMPRESA TRATA E COMO S&Atilde;O
                COLETADOS?</span></li>
    </ol>
    <p class="c1 c45"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">Os tipos de Dados Pessoais e a forma como os coletamos dependem de como
            voc&ecirc; se relaciona com a Empresa e por que. Por exemplo, os Dados Pessoais coletados ser&atilde;o
            diferentes caso voc&ecirc; seja um cliente ou caso apenas navegue em nossa plataforma, sem se cadastrar em
            quaisquer servi&ccedil;os que possamos oferecer.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Para facilitar a sua compreens&atilde;o, listamos abaixo algumas
            situa&ccedil;&otilde;es em que n&oacute;s podemos tratar seus Dados Pessoais a depender de sua
            rela&ccedil;&atilde;o com a Empresa:</span></p>
    <p class="c1"><span class="c0"></span></p><a id="t.9b30e134ea95d90b4fdb8386571c44c162723cf3"></a><a id="t.0"></a>
    <table class="c37">
        <tr class="c25">
            <td class="c29" colspan="1" rowspan="1">
                <p class="c7"><span class="c42">Finalidades de Tratamento</span><sup><a href="#cmnt3"
                            id="cmnt_ref3">[c]</a></sup></p>
            </td>
            <td class="c36" colspan="1" rowspan="1">
                <p class="c7"><span class="c18">Tipo de Dados Pessoais Utilizados</span></p>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Contato via Servi&ccedil;o de Atendimento ao Consumidor (SAC)</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <ul class="c21 lst-kix_list_13-0 start">
                    <li class="c3 li-bullet-0"><span class="c9">Nome</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">E-mail</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">Telefone</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">Endere&ccedil;o</span></li>
                    <li class="c8 li-bullet-0"><span class="c9">Informa&ccedil;&otilde;es selecionadas e enviadas
                            exclusivamente por voc&ecirc;</span></li>
                </ul>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Contato via Atendimento Virtual</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <ul class="c21 lst-kix_list_13-0">
                    <li class="c3 li-bullet-0"><span class="c9">Nome</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">E-mail</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">Telefone</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">Endere&ccedil;o</span></li>
                    <li class="c8 li-bullet-0"><span class="c9">Informa&ccedil;&otilde;es selecionadas e enviadas
                            exclusivamente por voc&ecirc;</span></li>
                </ul>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c30"><span class="c11">Cadastro na Plataforma</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <ul class="c21 lst-kix_list_13-0">
                    <li class="c3 li-bullet-0"><span class="c9">Nome</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">E-mail</span></li>
                    <li class="c8 li-bullet-0"><span class="c9">CPF</span></li>
                </ul>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Efetiva&ccedil;&atilde;o/contrata&ccedil;&atilde;o de compras e
                        servi&ccedil;os</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <ul class="c21 lst-kix_list_13-0">
                    <li class="c3 li-bullet-0"><span class="c9">Nome</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">E-mail</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">Telefone</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">CPF</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">Endere&ccedil;o</span></li>
                    <li class="c8 li-bullet-0"><span class="c9">Dados financeiros e banc&aacute;rios, por exemplo, dados
                            de cart&atilde;o de cr&eacute;dito ou de d&eacute;bito</span></li>
                </ul>
            </td>
        </tr>
        <tr class="c34">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c30"><span class="c11">Publica&ccedil;&atilde;o de coment&aacute;rios e
                        avalia&ccedil;&otilde;es</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <ul class="c21 lst-kix_list_13-0">
                    <li class="c3 li-bullet-0"><span class="c9">Nome</span></li>
                    <li class="c8 li-bullet-0"><span class="c9">E-mail</span></li>
                </ul>
            </td>
        </tr>
        <tr class="c34">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Envio de e-mails marketing sobre produtos e servi&ccedil;os</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <ul class="c21 lst-kix_list_13-0">
                    <li class="c3 li-bullet-0"><span class="c9">Nome</span></li>
                    <li class="c3 li-bullet-0"><span class="c9">Telefone</span></li>
                    <li class="c8 li-bullet-0"><span class="c9">E-mail</span></li>
                </ul>
            </td>
        </tr>
    </table>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Al&eacute;m disso, cumpre apontar que a Empresa registra os dados de acesso e uso
            de sua plataforma quando voc&ecirc; de qualquer forma acessa a plataforma. A Empresa utiliza as
            informa&ccedil;&otilde;es de acesso, as informa&ccedil;&otilde;es do dispositivo e os endere&ccedil;os de IP
            (</span><span class="c33 c16 c24 c17">Internet Protocol</span><span class="c0">) para identificar o Titular
            e/ou para registrar a atividade que &eacute; realizada na plataforma.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Caso voc&ecirc; esteja acessando a plataforma da Empresa como representante de
            uma pessoa jur&iacute;dica, voc&ecirc; deve estar autorizado e ter todos os poderes necess&aacute;rios para
            tomar ci&ecirc;ncia, aceitar e agir em conformidade com esta Pol&iacute;tica de Privacidade em nome da
            pessoa jur&iacute;dica. </span></p>
    <p class="c1"><span class="c12"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="3">
        <li class="c41 c15 c50 li-bullet-1" id="h.3znysh7"><span class="c12">COM QUEM A EMPRESA COMPARTILHA SEUS DADOS
                PESSOAIS?</span></li>
    </ol>
    <p class="c27 c15"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">A Empresa poder&aacute; compartilhar os Dados Pessoais coletados por meio de
            nossa plataforma conforme as seguintes situa&ccedil;&otilde;es: </span></p>
    <p class="c1"><span class="c0"></span></p>
    <ul class="c21 lst-kix_list_15-0 start">
        <li class="c3 c15 li-bullet-0"><span class="c12">Empresas do grupo</span><span class="c0">: A Empresa
                poder&aacute; compartilhar os dados pessoais com qualquer uma das empresas afiliadas,
                subsidi&aacute;rias e/ou controladoras da Empresa, nacionais e/ou estrangeiras, sempre que tal
                compartilhamento for importante para a opera&ccedil;&atilde;o da plataforma, para o fornecimento dos
                produtos e servi&ccedil;os, para o cumprimento de obriga&ccedil;&otilde;es legais ou regulat&oacute;rias
                e/ou forem do seu interesse</span></li>
    </ul>
    <p class="c1"><span class="c0"></span></p>
    <ul class="c21 lst-kix_list_15-0">
        <li class="c3 c15 li-bullet-0"><span class="c12">Parceiros de neg&oacute;cios</span><span class="c0">: A Empresa
                poder&aacute; compartilhar os dados pessoais coletados com empresas, organiza&ccedil;&otilde;es ou
                indiv&iacute;duos que prestam servi&ccedil;os em nome da Empresa para, por exemplo, auxiliar no
                fornecimento/entrega dos produtos e servi&ccedil;os ofertados ou implementar medidas de
                seguran&ccedil;a. Esses parceiros receber&atilde;o apenas as informa&ccedil;&otilde;es
                necess&aacute;rias para prestar o servi&ccedil;o adequadamente e ser&atilde;o orientadas por
            </span><span class="c9">n</span><span class="c0">&oacute;s sobre como tratar seus dados, mant&ecirc;-los
                seguros e cumprir a lei.</span><span class="c9">&nbsp;</span></li>
    </ul>
    <p class="c1"><span class="c0"></span></p>
    <ul class="c21 lst-kix_list_15-0">
        <li class="c3 c15 li-bullet-0"><span class="c12">Auditorias externas</span><span class="c0">: Os dados pessoais
                poder&atilde;o ser compartilhados com empresas que prestam servi&ccedil;os de auditoria externa de
                nossas opera&ccedil;&otilde;es, sobretudo para an&aacute;lises quanto &agrave; observ&acirc;ncia de
                par&acirc;metros de privacidade e prote&ccedil;&atilde;o de dados e seguran&ccedil;a da
                informa&ccedil;&atilde;o.</span></li>
    </ul>
    <p class="c1"><span class="c0"></span></p>
    <ul class="c21 lst-kix_list_15-0">
        <li class="c3 c15 li-bullet-0"><span class="c12">Demandas judiciais e cumprimento de obriga&ccedil;&otilde;es
                legais e regulat&oacute;rios</span><span class="c0">: A Empresa poder&aacute; compartilhar os dados
                pessoais com autoridades e/ou &oacute;rg&atilde;os p&uacute;blicos, ag&ecirc;ncias regulat&oacute;rias,
                autoridades legais, autarquias, &oacute;rg&atilde;os de defesa do consumidor, &oacute;rg&atilde;os do
                governo e/ou do judici&aacute;rio para cumprir obriga&ccedil;&otilde;es legais, regulat&oacute;rias ou
                qualquer determina&ccedil;&atilde;o perante &oacute;rg&atilde;os do governo e do judici&aacute;rio, bem
                como para resguardar direitos, prevenir responsabilidades da Empresa e/ou investigar ou tomar medidas
                relacionadas a atividades ilegais e poss&iacute;veis viola&ccedil;&otilde;es de direitos da Empresa e/ou
                de terceiros.</span></li>
    </ul>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Ainda, a plataforma da Empresa pode conter hiperlinks</span><span
            class="c33 c16 c24 c17">&nbsp;</span><span class="c0">para sites de terceiros (como Facebook, Instagram e
            LinkedIn). Informamos que n&atilde;o somos respons&aacute;veis pelo conte&uacute;do ou pr&aacute;ticas de
            privacidade de sites de terceiros. Assim, aconselhamos a rever as declara&ccedil;&otilde;es de privacidade
            dos sites que voc&ecirc; escolheu para visitar, para que voc&ecirc; possa entender como esses sites coletam,
            usam e compartilham suas informa&ccedil;&otilde;es.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Quanto aos reposit&oacute;rios que a Empresa utiliza, &eacute; importante apontar
            que a Empresa processa dados, incluindo Dados Pessoais, dentro do Brasil e em outros pa&iacute;ses, sendo
            poss&iacute;vel que alguns destes pa&iacute;ses n&atilde;o possuam ou tenham leis menos protetivas em
            rela&ccedil;&atilde;o &agrave; prote&ccedil;&atilde;o de dados pessoais e privacidade.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="4">
        <li class="c28 c15 li-bullet-0"><span class="c6">COOKIES</span><sup><a href="#cmnt4"
                    id="cmnt_ref4">[d]</a></sup></li>
    </ol>
    <p class="c41 c39 c15"><span class="c6"></span></p>
    <p class="c5" id="h.2et92p0"><span class="c24 c17">Cookies</span><span class="c17">&nbsp;s&atilde;o arquivos
            instalados no dispositivo de um usu&aacute;rio que permitem a coleta de determinadas
            informa&ccedil;&otilde;es, por exemplo, data e hor&aacute;rio de visita a determinada
        </span><span>plataforma</span><span class="c17">. Neste sentido, a utiliza&ccedil;&atilde;o de </span><span
            class="c24 c17">cookies</span><span class="c17">&nbsp;em nossa </span><span>plataforma</span><span
            class="c0">&nbsp;tem o objetivo de ajudar a personalizar a experi&ecirc;ncia dos titulares e tamb&eacute;m
            tem fins estat&iacute;sticos. </span></p>
    <p class="c5 c39"><span class="c0"></span></p>
    <p class="c5"><span class="c17">Deste modo, seguem abaixo as categorias de </span><span
            class="c24 c17">cookies</span><span class="c17">&nbsp;utilizados em nossa
        </span><span>plataforma</span><span class="c0">&nbsp;pela Empresa ou por terceiros:</span></p>
    <p class="c5 c39"><span class="c0"></span></p><a id="t.9d6e9b0a7a5d15b7f997c4921fd443cf756b9c06"></a><a
        id="t.1"></a>
    <table class="c37">
        <tr class="c25">
            <td class="c29" colspan="1" rowspan="1">
                <p class="c7"><span class="c42">Tipo de </span><span class="c42 c24">cookies</span></p>
            </td>
            <td class="c36" colspan="1" rowspan="1">
                <p class="c7"><span class="c18">O que eles fazem</span></p>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Necess&aacute;rios</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c24">Cookies</span><span class="c9">&nbsp;necess&aacute;rios s&atilde;o
                        aqueles utilizados para que a plataforma realize fun&ccedil;&otilde;es b&aacute;sicas e opere
                        corretamente. Por isso, a coleta &eacute; essencial para assegurar o funcionamento da
                        plataforma.</span></p>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Anal&iacute;ticos</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c24">Cookies</span><span class="c9">&nbsp;anal&iacute;ticos ajudam a entender
                        como os usu&aacute;rios utilizam a plataforma, quais p&aacute;ginas visitam com mais
                        frequ&ecirc;ncia, a ocorr&ecirc;ncia de erros ou informa&ccedil;&otilde;es sobre o
                        pr&oacute;prio desempenho da plataforma.</span></p>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Funcionalidade </span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c24">Cookies</span><span class="c9">&nbsp;de funcionalidade s&atilde;o usados
                        para fornecer os servi&ccedil;os solicitados pelo usu&aacute;rio e possibilitam lembrar
                        prefer&ecirc;ncias da plataforma.</span></p>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Publicidade</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c24">Cookies</span><span class="c9">&nbsp;de publicidades s&atilde;o
                        utilizados para rastrear os usu&aacute;rios da plataforma com o intuito de exibir
                        an&uacute;ncios que podem ser relevantes e atrativos para o usu&aacute;rio.</span></p>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">N&atilde;o classificados</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c24">Cookies</span><span>&nbsp;n&atilde;o classificados s&atilde;o
                    </span><span class="c24">cookies</span><span>&nbsp;que est&atilde;o em processo de
                        classifica&ccedil;&atilde;o junto aos seus provedores dos </span><span
                        class="c24">cookies</span><span>&nbsp;individuais.</span></p>
            </td>
        </tr>
    </table>
    <p class="c5 c39"><span class="c0"></span></p>
    <p class="c5"><span class="c17">Assim, ao acessar nossa</span><span>&nbsp;plataforma</span><span class="c17">,
            voc&ecirc; autoriza o uso de </span><span class="c17 c24">cookies</span><span class="c17">&nbsp;nos termos
            desta pol&iacute;tica. Caso n&atilde;o concorde, voc&ecirc; poder&aacute; ajustar as
            configura&ccedil;&otilde;es do seu navegador para n&atilde;o permitir o uso de </span><span
            class="c24 c17">cookies</span><span class="c0">. </span></p>
    <p class="c5 c39"><span class="c0"></span></p>
    <p class="c5"><span class="c17">&Eacute; importante que voc&ecirc; saiba que, se voc&ecirc; alterar as
            defini&ccedil;&otilde;es e bloquear determinados </span><span class="c24 c17">cookies</span><span
            class="c17">, talvez n&atilde;o consiga utilizar na &iacute;ntegra algumas funcionalidades da
            nossa</span><span>&nbsp;plataforma</span><span class="c0">&nbsp;e poderemos n&atilde;o conseguir
            disponibilizar alguns conte&uacute;dos para voc&ecirc;.</span></p>
    <p class="c5 c39"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="5">
        <li class="c28 c15 li-bullet-0"><span class="c12">COMO PROTEGEMOS SEUS DADOS PESSOAIS?</span></li>
    </ol>
    <p class="c15 c27"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">Todos os Dados Pessoais s&atilde;o protegidos por tecnologia de seguran&ccedil;a
            avan&ccedil;ada e consistentemente atualizada, em um esfor&ccedil;o para proteg&ecirc;-los.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">N&oacute;s nos esfor&ccedil;amos para proteger a privacidade de seus Dados
            Pessoais, mas infelizmente n&atilde;o podemos garantir total seguran&ccedil;a. Entradas e usos n&atilde;o
            autorizados de terceiros com informa&ccedil;&otilde;es suas, falhas de </span><span
            class="c16 c24 c17 c33">hardware</span><span class="c0">&nbsp;ou </span><span
            class="c33 c16 c24 c17">software</span><span class="c0">&nbsp;que n&atilde;o estejam sob o controle da
            Empresa e outros fatores externos podem comprometer a seguran&ccedil;a dos seus Dados Pessoais. </span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Por isso, sua atua&ccedil;&atilde;o &eacute; fundamental para a
            manuten&ccedil;&atilde;o de um ambiente seguro para todos. Caso voc&ecirc; identifique ou tome conhecimento
            de qualquer fator que comprometa a seguran&ccedil;a dos seus dados na sua rela&ccedil;&atilde;o com a
            Empresa, por favor entre em contato conosco por meio das informa&ccedil;&otilde;es de contato indicadas
            abaixo.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="6">
        <li class="c28 c15 li-bullet-0"><span class="c12">QUAIS S&Atilde;O OS SEUS DIREITOS COMO TITULAR DE DADOS
                PESSOAIS?</span></li>
    </ol>
    <p class="c41 c39 c15 c49"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">Os Dados Pessoais que tratamos s&atilde;o seus e a lei brasileira lhe garante uma
            s&eacute;rie de direitos relacionados a eles. N&oacute;s estamos comprometidos com o cumprimento desses
            direitos e, nessa se&ccedil;&atilde;o, vamos explicar quais s&atilde;o esses direitos e como voc&ecirc; pode
            exerc&ecirc;-los. Veja a seguir:</span></p>
    <p class="c1"><span class="c0"></span></p><a id="t.b1a8287d866ef24bee8b26835b3b6aeebd8da34b"></a><a id="t.2"></a>
    <table class="c37">
        <tr class="c25">
            <td class="c29" colspan="1" rowspan="1">
                <p class="c7"><span class="c18">Seus Direitos</span></p>
            </td>
            <td class="c36" colspan="1" rowspan="1">
                <p class="c7"><span class="c18">Como exerc&ecirc;-los</span></p>
            </td>
        </tr>
        <tr class="c25">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span>Confirma&ccedil;&atilde;o e Acesso</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span>Voc&ecirc; pode solicitar &agrave; Empresa a confirma&ccedil;&atilde;o sobre a
                        exist&ecirc;ncia de tratamento dos seus Dados Pessoais para que, em caso positivo, voc&ecirc;
                        possa acess&aacute;-los, inclusive por meio de solicita&ccedil;&atilde;o de c&oacute;pias dos
                        registros que temos sobre voc&ecirc;.</span></p>
            </td>
        </tr>
        <tr class="c10">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Corre&ccedil;&atilde;o</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c9">Voc&ecirc; pode solicitar a corre&ccedil;&atilde;o dos seus Dados
                        Pessoais caso estes estejam incompletos, inexatos ou desatualizados.</span></p>
            </td>
        </tr>
        <tr class="c34">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o </span>
                </p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span>Voc&ecirc; pode solicitar </span><span class="c26">(a)</span><span>&nbsp;a
                        anonimiza&ccedil;&atilde;o dos seus Dados Pessoais, de forma que eles n&atilde;o possam mais ser
                        relacionados a voc&ecirc; e, portanto, deixem de ser Dados Pessoais; </span><span
                        class="c26">(b)</span><span>&nbsp;o bloqueio dos seus Dados Pessoais, suspendendo
                        temporariamente a sua possibilidade de os tratarmos para certas finalidades; e </span><span
                        class="c26">(c)</span><span class="c9">&nbsp;a elimina&ccedil;&atilde;o dos seus Dados Pessoais,
                        caso em que deveremos apagar todos os seus Dados Pessoais sem possibilidade de
                        revers&atilde;o.</span></p>
            </td>
        </tr>
        <tr class="c44">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c16">Portabilidade</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c9">Voc&ecirc; pode solicitar que a Empresa forne&ccedil;a os seus Dados
                        Pessoais em formato estruturado e interoper&aacute;vel visando &agrave; sua transfer&ecirc;ncia
                        para um terceiro.</span></p>
            </td>
        </tr>
        <tr class="c31">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Informa&ccedil;&atilde;o sobre o compartilhamento</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5" id="h.tyjcwt"><span class="c9">Voc&ecirc; tem o direito de saber quais s&atilde;o as
                        entidades p&uacute;blicas e privadas com as quais a Empresa realiza uso compartilhado dos seus
                        Dados Pessoais. Manteremos, no item III desta Pol&iacute;tica, uma indica&ccedil;&atilde;o das
                        nossas rela&ccedil;&otilde;es com terceiros que podem envolver o compartilhamento de Dados
                        Pessoais. Em todo caso, se voc&ecirc; tiver d&uacute;vidas ou quiser mais detalhes, voc&ecirc;
                        tem o direito de nos solicitar tais informa&ccedil;&otilde;es. </span></p>
            </td>
        </tr>
        <tr class="c31">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o
                        consentir</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c9">Voc&ecirc; tem o direito de receber informa&ccedil;&otilde;es claras e
                        completas sobre a possibilidade e as consequ&ecirc;ncias de n&atilde;o fornecer consentimento,
                        quando ele for solicitado pela Empresa. O seu consentimento, quando necess&aacute;rio, deve ser
                        livre e informado. Portanto, sempre que pedirmos seu consentimento, voc&ecirc; ser&aacute; livre
                        para neg&aacute;-lo sendo poss&iacute;vel, nesses casos, que alguns servi&ccedil;os n&atilde;o
                        possam ser prestados.</span></p>
            </td>
        </tr>
        <tr class="c31">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c16">Revoga&ccedil;&atilde;o do consentimento</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c9">Caso voc&ecirc; tenha consentido com alguma finalidade de tratamento dos
                        seus Dados Pessoais, voc&ecirc; pode sempre optar por retirar o seu consentimento. No entanto,
                        isso n&atilde;o afetar&aacute; a legalidade de qualquer tratamento realizado anteriormente
                        &agrave; revoga&ccedil;&atilde;o. Se voc&ecirc; retirar o seu consentimento, &eacute;
                        poss&iacute;vel que fiquemos impossibilitados de lhe prestar certos servi&ccedil;os, mas iremos
                        avis&aacute;-lo caso isto ocorra.</span></p>
            </td>
        </tr>
        <tr class="c13">
            <td class="c20" colspan="1" rowspan="1">
                <p class="c7"><span class="c11">Oposi&ccedil;&atilde;o</span></p>
            </td>
            <td class="c14" colspan="1" rowspan="1">
                <p class="c5"><span class="c9">A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu
                        consentimento ou um contrato conosco. Nestas situa&ccedil;&otilde;es, somente trataremos seus
                        Dados Pessoais se tivermos motivos leg&iacute;timos para tanto. Caso voc&ecirc; n&atilde;o
                        concorde com alguma finalidade de tratamento dos seus Dados Pessoais, voc&ecirc; poder&aacute;
                        apresentar oposi&ccedil;&atilde;o, solicitando a sua interrup&ccedil;&atilde;o.</span></p>
            </td>
        </tr>
    </table>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Para garantir sua seguran&ccedil;a e a privacidade de todos, sempre que
            voc&ecirc; apresentar uma requisi&ccedil;&atilde;o para exercer seus direitos, a Empresa poder&aacute;
            solicitar algumas informa&ccedil;&otilde;es e/ou documentos complementares para que possamos comprovar a sua
            identidade, buscando impedir fraudes.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Em alguns casos, a Empresa pode ter motivos leg&iacute;timos para deixar de
            atender a uma solicita&ccedil;&atilde;o de exerc&iacute;cio de direitos. Estas situa&ccedil;&otilde;es
            incluem, por exemplo, casos em que uma revela&ccedil;&atilde;o de informa&ccedil;&otilde;es
            espec&iacute;ficas poderia violar direitos de propriedade intelectual ou segredos de neg&oacute;cio da
            Empresa ou de terceiros, bem como casos em que pedidos de exclus&atilde;o de dados n&atilde;o possam ser
            atendidos em raz&atilde;o da exist&ecirc;ncia de obriga&ccedil;&atilde;o da Empresa de reter dados, seja
            para cumprir obriga&ccedil;&otilde;es legais, regulat&oacute;rias ou para possibilitar a defesa da Empresa
            ou de terceiros em disputas de qualquer natureza.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Ainda, algumas solicita&ccedil;&otilde;es podem n&atilde;o ser respondidas de
            forma imediata, mas nos comprometemos a responder todas as requisi&ccedil;&otilde;es em um prazo
            razo&aacute;vel e sempre em conformidade com a legisla&ccedil;&atilde;o aplic&aacute;vel.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="7">
        <li class="c28 c15 li-bullet-0"><span class="c12">POR QUANTO TEMPO ARMAZENAMOS SEUS DADOS PESSOAIS?</span></li>
    </ol>
    <p class="c27 c15"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">A Empresa possui uma pol&iacute;tica de reten&ccedil;&atilde;o de Dados Pessoais
            alinhada com a lei aplic&aacute;vel. Dados Pessoais s&atilde;o armazenados somente pelo tempo que forem
            necess&aacute;rios para cumprir com as finalidades para as quais foram coletados, salvo se houver qualquer
            outra raz&atilde;o para sua manuten&ccedil;&atilde;o como, por exemplo, cumprimento de quaisquer
            obriga&ccedil;&otilde;es legais, regulat&oacute;rias, contratuais, entre outras permitidas sob a lei.</span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Sempre fazemos uma an&aacute;lise t&eacute;cnica para determinar o per&iacute;odo
            de reten&ccedil;&atilde;o adequado para cada tipo de Dado Pessoal coletado, considerando a sua natureza,
            necessidade de coleta e finalidade para a qual ele ser&aacute; tratado, bem como eventuais necessidades de
            reten&ccedil;&atilde;o para o cumprimento de obriga&ccedil;&otilde;es ou o resguardo de direitos.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="8">
        <li class="c15 c28 li-bullet-0"><span class="c12">COMO FALAR SOBRE DADOS PESSOAIS COM A EMPRESA?</span></li>
    </ol>
    <p class="c27 c15"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">Se voc&ecirc; acredita que seus Dados Pessoais foram tratados de maneira
            incompat&iacute;vel com esta Pol&iacute;tica ou com as suas escolhas enquanto Titular dos seus Dados
            Pessoais, ou, ainda, se voc&ecirc; tiver d&uacute;vidas, coment&aacute;rios ou sugest&otilde;es relacionadas
            a esta Pol&iacute;tica e &agrave; forma como tratamos seus Dados Pessoais, entre em contato conosco. </span>
    </p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">N&oacute;s temos um </span><span class="c0">Encarregado</span><sup><a
                href="#cmnt5" id="cmnt_ref5">[e]</a></sup><span class="c0">&nbsp;que est&aacute; &agrave;
            disposi&ccedil;&atilde;o nos seguintes endere&ccedil;os de contato:</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15 c32"><span class="c12">Telefone: </span><span class="c0">11 4447-7250</span></p>
    <p class="c41 c15"><span class="c12">E-mail para contato</span><span class="c0">: dpo@cmp.ind.br</span></p>
    <p class="c39 c15 c41"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="9">
        <li class="c28 c15 li-bullet-0" id="h.3dy6vkm"><span class="c12">PRIVACIDADE ONLINE DE CRIAN&Ccedil;AS E
                ADOLESCENTES</span></li>
    </ol>
    <p class="c27 c15"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">A Empresa est&aacute; em conformidade com a Lei de Prote&ccedil;&atilde;o de
            Privacidade Online da Crian&ccedil;a (COPPA), que estipula que os operadores da plataforma n&atilde;o podem
            coletar Dados Pessoais de crian&ccedil;as menores de 13 anos sem permiss&atilde;o parental
            verific&aacute;vel, al&eacute;m das disposi&ccedil;&otilde;es previstas na LGPD.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Crian&ccedil;as e adolescentes n&atilde;o s&atilde;o eleg&iacute;veis para usar
            qualquer uma de nossas funcionalidades contidas nesta plataforma. Por isso, n&atilde;o coletamos ou
            utilizamos propositalmente Dados Pessoais de usu&aacute;rios menores de 18 anos. </span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">Caso voc&ecirc; seja menor de idade, voc&ecirc; pode usar nossas funcionalidades
            somente em conjunto com seus pais ou respons&aacute;veis, que dever&atilde;o estar cientes e de acordo com
            os termos desta Pol&iacute;tica de Privacidade e ser&atilde;o considerados respons&aacute;veis por sua
            a&ccedil;&atilde;o.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <ol class="c21 lst-kix_list_2-0" start="10">
        <li class="c3 c15 li-bullet-0"><span class="c12">ALTERA&Ccedil;&Otilde;ES NA POL&Iacute;TICA DE
                PRIVACIDADE</span></li>
    </ol>
    <p class="c1 c45"><span class="c12"></span></p>
    <p class="c5 c15"><span class="c0">Com o fim de buscar melhorar a forma como operamos, reservamo-nos o direito de
            alterar esta Pol&iacute;tica de Privacidade a qualquer momento. Sempre que isso ocorrer, publicaremos um
            aviso em nossa plataforma, ou o avisaremos por e-mail.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c5 c15"><span class="c0">De todo modo, recomendamos que voc&ecirc; visite esta p&aacute;gina
            periodicamente para que tenha conhecimento sobre eventuais altera&ccedil;&otilde;es.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c43 c39 c15 c48"><span class="c0"></span></p>
    <p class="c7 c39 c46"><span class="c0"></span></p>
    <p class="c1"><span class="c0"></span></p>
    <div class="c19">
        <p class="c35"><a href="#cmnt_ref1" id="cmnt1">[a]</a><span class="c4">&Agrave; CMP: Inserir os dados do
                parceiro.</span></p>
    </div>
    <div class="c19">
        <p class="c35"><a href="#cmnt_ref2" id="cmnt2">[b]</a><span class="c4">&Agrave; CMP: Inserir nome empresarial do
                parceiro.</span></p>
    </div>
    <div class="c19">
        <p class="c35"><a href="#cmnt_ref3" id="cmnt3">[c]</a><span class="c4">&Agrave; CMP: Favor confirmar se
                realmente s&atilde;o apenas esses dados, bem como se haver&aacute; algum dado complementar quando a
                plataforma for disponibilizada.</span></p>
    </div>
    <div class="c19">
        <p class="c35"><a href="#cmnt_ref4" id="cmnt4">[d]</a><span class="c4">SMA Advogados: Recomendamos que insiram
                um banner de cookies no site. Caso necessitem de aux&iacute;lio na elabora&ccedil;&atilde;o deste
                banner, por favor nos avisem.</span></p>
    </div>
    <div class="c19">
        <p class="c35"><a href="#cmnt_ref5" id="cmnt5">[e]</a><span class="c4">&Agrave; CMP: Recomendamos que seja
                inserido o e-mail do Encarregado (DPO) do parceiro.</span></p>
    </div>
</body>

</html>
`;