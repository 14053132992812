const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    if (hours < 10) {
        hours = '0' + hours;
    }

    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    return day + '/' + month + '/' + year + ' - ' + hours + ':' + minutes + ':' + seconds;
};

const formatSimpleDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getUTCMonth() + 1),
            day = '' + d.getUTCDate(),
            year = d.getUTCFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return day + '/' + month + '/' + year;
};

const formatSimpleDateLine = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return year + '-' + month + '-' + day;
};

const formatDateFromApi = (date) => {
    let parts = date.split('-');
    return new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]));
}

function formateApiDate(date) {

    let dateFrom = date.getFullYear() + "-";
    if ((date.getMonth() + 1) < 10) {
        dateFrom = dateFrom + "0" + (date.getMonth() + 1) + "-"
    } else {
        dateFrom = dateFrom + (date.getMonth() + 1) + "-"
    }
    if (date.getDate() < 10) {
        dateFrom = dateFrom + "0" + date.getDate();
    } else {
        dateFrom = dateFrom + date.getDate();
    }

    return dateFrom;

}

function formatStringDate(date) {

    if (date) {

        let dateShow = date?.getDate();
        let dateMonth = (date?.getMonth() + 1);
        if (dateShow < 10) {
            dateShow = "0" + dateShow;
        }
        if (dateMonth < 10) {
            dateMonth = "0" + dateMonth;
        }
        return dateShow + "/" + dateMonth + "/" + date?.getFullYear();

    }


}

const maskDate = value => {
    debugger
    if (value.length > 10) {
        return value.slice(0, 10)
    }


    let v = value.replace(/\D/g, '').slice(0, 10);
    if (v.length >= 5) {
        return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    }
    else if (v.length >= 3) {
        return `${v.slice(0, 2)}/${v.slice(2)}`;
    }

    return v


}

const DateFunctions = { 
    formatDate: formatDate, 
    formatSimpleDate : formatSimpleDate, 
    formatSimpleDateLine: formatSimpleDateLine, 
    formateApiDate: formateApiDate, 
    formatStringDate: formatStringDate,
    maskDate: maskDate, 
    formatDateFromApi : formatDateFromApi 
}

export default DateFunctions;