import { Menu } from "@mui/icons-material";
import { useState } from 'react';
import './header.css';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { logout } from "../../slices/authSlice";
import { useNavigate } from "react-router";
import SideBar from '../Sidebar/SideBar';

import arrowDownBlack from '../../images/arrow_down_black.png'
import perfilImg from '../../images/perfil.png'
import polygonImg from '../../images/polygon.png'

const MenuOpt = ({leftCloseSideBar = '260px'} : {leftCloseSideBar? : string}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
    const { basicUserInfo } = useAppSelector((state) => state.auth);
    const [isSideBar, setSideBar] = useState(false);

    const handleLogout = async () => {
        dispatch(logout());
        navigate("/login");
    };

    function showOpt() {
        let doc = document.getElementById('menuOptions')!;
        if (doc) {
            if (doc.style.display == 'block') {
                doc.style.display = 'none'
            } else {
                doc.style.display = 'block'
            }
        }
    }


    return (

        <div style={{ backgroundColor: 'white' }}>
            <div className="menuHome">
                <Menu onClick={() => setSideBar(!isSideBar)} />
                <div className="userRow" onClick={() => { setSideBar(true) }}>
                    <img src={perfilImg} alt='perfilImg' style={{ marginRight: 5 }} />
                    <span className="menuTitle">{userInfo?.email}</span>
                    <img src={arrowDownBlack} alt='menuArrow' />
                </div>
            </div>



            {
                isSideBar ? (
                    <SideBar active={setSideBar} left={leftCloseSideBar} />
                ) : null
            }


        </div>
    );
}

export default MenuOpt;



