import { Outlet, useNavigate } from "react-router-dom";
import "./layouts.css";
import { Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { useAppSelector } from "../hooks/redux-hooks";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { getUser } from "../slices/userSlice";
import { useAppDispatch } from "../hooks/redux-hooks";

const ProtectedLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { basicUserInfo, status, error, userProfile } = useAppSelector((state) => state.auth);
  const { userMarket } = useAppSelector((state) => state.user);
  const { token } = useAppSelector((state) => state.auth);
  const { height, width } = useWindowDimensions();
  let location = useLocation();
  const sessionToken = localStorage.getItem("token");

  useEffect(() => {
    dispatch(getUser(true));
    if (basicUserInfo && userProfile) {
      if(userProfile != "Administrador" && userProfile != "Gestor" && userProfile != "Promotor") {
        if(!userMarket) {
          navigate('/market-choice');
        }
      }
    }
  }, []);

  if (!token && !sessionToken) {
    return <Navigate replace to={"/login"} />
  }

  return (
    // <div style={{ maxWidth: 900, overflowX: 'hidden', margin: 'auto' }}>
    <div style={{ overflowX: 'hidden', margin: 'auto' }}>
      <div
        className="rootDiv"
        style={{
          maxWidth: '100%',
          minWidth: '100%',
          maxHeight: height,
          minHeight: height,
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedLayout;





// import { Outlet } from "react-router-dom";
// import "./layouts.css";
// import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { useAppSelector } from "../hooks/redux-hooks";
// import useWindowDimensions from "../hooks/useWindowDimensions";


// const ProtectedLayout = () => {
//   const { token, userProfile } = useAppSelector((state) => state.auth);
//   const { height, width } = useWindowDimensions();
//   let location = useLocation();
//   const navigate = useNavigate();
//   const sessionToken = localStorage.getItem("token");

//   console.log("userProfile ", userProfile)

//   if (!token && !sessionToken) {

//     return <Navigate replace to={"/login"} />

//   } else if (userProfile == "Administrador") {
//     navigate('/admin/home')
//   } else if (userProfile == "Promotor") {
//     console.log("navigatingggggg")
//     navigate('/promoter/home')
//   } else {
//     return (
//       <div
//         className="rootDiv"
//         style={{
//           maxWidth: width,
//           minWidth: width,
//           maxHeight: height,
//           minHeight: height,
//         }}
//       >
//         <Outlet />
//       </div>
//     );

//   }





// };

// export default ProtectedLayout;
