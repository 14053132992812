
export function enumStatusSchedule(status : string) {
    switch(status) {
        case 'Finalized':
            return {
                title: 'Finalizado',
                color: '#d0bc20',
            };
        
        case 'Scheduled':
            return {
                title: 'Confirmado',
                color: '#20d064',
            };

        case 'Canceled':
            return {
                title: 'Cancelado',
                color: '#D02030',
            };
        
        default:
            return {
                title: status,  
                color: '#D02030'
            };
    }
}