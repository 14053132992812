import {
    Button
} from "@mui/material";
import colors from "../../utils/colors";
import calendarImg from '../../images/calendar.png'
import clockImg from '../../images/relogio.png'
import redClock from '../../images/redclock.png'
const ImgButton = ({ title, click, color = colors.red, disabled = false, img, textColor = 'white' }:
    { title: string, click: any, color?: string | any, disabled?: boolean | any, img: string, textColor?: string }) => {



    function renderImg() {


        if (img == 'calendar') {
            return (
                <img src={calendarImg} />
            )
        }

        if (img == 'clock') {
            return (
                <img src={clockImg} />
            )
        }

        if (img == 'redClock') {
            return (
                <img src={redClock} />
            )
        }

        return (
            < >
            </>
        )
    }

    return (

        <div style={{ width: '100%' }}>

            <Button
                disabled={disabled}
                style={{
                    width: '100%',
                    marginTop: 5, marginBottom: 5,
                    backgroundColor: color,
                    maxHeight: 42
                }}
                onClick={disabled ? null : click} ><span style={{ color: textColor, marginTop: 2, textTransform: 'none', fontSize: 13 }}>
                    {renderImg()} &nbsp;
                    {title} </span> </Button>
        </div>
    );
}

export default ImgButton;
