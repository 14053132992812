import { Close, AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './sideBar.css';

import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks';
import { logout } from '../../slices/authSlice';



const SideBar = ({ active, left = '260px' }: { active: any, left?: string }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { basicUserInfo } = useAppSelector((state) => state.auth);
    const { userData } = useAppSelector((state) => state.user);


    const handleLogout = async () => {
        dispatch(logout());
        navigate('/login');
    }

    const onClose = () => {
        active(false);
    }

    return (
        <div className='sideBar' >
            <Close className='sideBarCloseIcon' onClick={() => onClose()} style={{ left: left }} />
            <div className='containerSideBar'>
                <AccountCircle className='userIcon' />
                {
                    userData?.name ? (
                        <span>
                            {userData.name}
                        </span>
                    ) : <span>não encontrado</span>
                }
                <button onClick={() => navigate('/')} >Início</button>
                <button onClick={() => navigate('/user-form')}>Meu perfil</button>
                <button onClick={() => navigate('/password-form')}>Trocar de senha</button>
                <button onClick={() => handleLogout()} >Sair</button>
            </div>
        </div>
    );
}

export default SideBar;