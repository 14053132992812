import { useState } from 'react';
import { useNavigate } from "react-router";
import { Menu } from '@mui/icons-material';
import './menu.css'

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { logout } from "../../slices/authSlice";

import SideBar from "../Sidebar/SideBar";
import arrowDownBlack from '../../images/arrow_down_black.png'
import perfilImg from '../../images/perfil.png'
import polygonImg from '../../images/polygon.png'


function MenuHeader() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { basicUserInfo } = useAppSelector((state) => state.auth);

  const [isSideBar, setSideBar] = useState(false);

  const handleLogout = async () => {
    dispatch(logout());
    navigate("/login");
  };

  function hideOpt() {
    let doc = document.getElementById('menuOptions')!;
    if (doc) {
      doc.style.display = 'none';
    }
  }

  function showOpt() {
    let doc = document.getElementById('menuOptions')!;
    if (doc) {
      doc.style.display = 'block';
    }
  }

  return (

    <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
      <div className="menuHome">
        <Menu onClick={() => setSideBar(!isSideBar)} />
        <div className="userRow" onClick={() => { setSideBar(true) }}>
          <img src={perfilImg} alt='perfilImg' style={{ marginRight: 5 }} />
          <span className="menuTitle">{basicUserInfo?.email}</span>
          <img src={arrowDownBlack} alt='menuArrow' />
        </div>
      </div>



      {
        isSideBar ? (
          <SideBar active={setSideBar} left='125px' />
        ) : null
      }

    </div>
  );
}

export default MenuHeader;
