import { useState, useEffect } from "react";
import { ArrowBack, PriorityHigh, KeyboardReturn } from "@mui/icons-material";
import { useNavigate } from 'react-router';

import Header from "../../../components/Header/Header";
import Search from "../../../components/Search/Search";
import Loading from "../../../components/Loading/Loading";

import { enumStatusSchedule } from '../../../utils/enum';
import DateFunctions from '../../../utils/DateFunctions';
import { getAllSchedulesFinalized, getAllSchedulesConfirmed, getAllSchedulesCanceled, Schedule } from '../../../slices/meetSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import './CallList.css';

const CallList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const [schedules, setSchedules] = useState([] as Schedule[]);
    const [showList, setShowList] = useState<boolean>(false);

    const { listResponse, status } = useAppSelector((state) => state.meet);

    useEffect(() => {
        if(!showList) {
            dispatch(getAllSchedulesConfirmed());
        } else {
            dispatch(getAllSchedulesFinalized());
        }
    }, [dispatch, showList]);

    useEffect(() => {
        setSchedules(listResponse);
    }, [listResponse]);

    useEffect(() => {
        if(!search) {
            setSchedules(listResponse);
        } else {
            let result = listResponse.filter((item : Schedule) => (
                    DateFunctions.formatSimpleDate(item.scheduleDate).indexOf(search.toLowerCase()) != -1 ||
                    item.status.toLowerCase().indexOf(search.toLowerCase()) != -1 ||
                    item.time.toString().indexOf(search.toLowerCase()) != -1 
                )
            );
            setSchedules(result);
        }
    }, [search]);

    return (
        <div className="callListMain">
            <Header />
            <div className="callListTitle" >
                <ArrowBack onClick={() => navigate('/admin/home')} style={{ cursor: "pointer" }} />
                <h1>Lista</h1>
            </div>

            <Search 
                value={search}
                setValue={setSearch}
            />

            <div style={{ paddingBottom: "45px" }} >
                        <div className="rewardListSelect">
                            <button 
                                style={!showList ? { borderBottom: '3px solid #D02030', background: '#FFEDEF' } : {}} 
                                onClick={() => {setShowList(false)}}
                            >
                                Confirmadas
                            </button>
                            <button 
                                style={showList ? { borderBottom: '3px solid #D02030', background: '#FFEDEF' } : {}} 
                                onClick={() => {setShowList(true)}}
                            >
                                Finalizadas
                            </button>
                        </div>
                
                {
                    schedules.length > 0 && schedules.map((schedule, index) => (
                        <div className='scheduleCardList' key={index} >
                            <h3>Chamada</h3>
                            <label><strong>Horário:</strong> {schedule.time.toString()}</label>
                            <label><strong>Data:</strong> {DateFunctions.formatSimpleDate(schedule.scheduleDate)}</label>
                            <div className="scheduleCardtime" style={{ backgroundColor: `${enumStatusSchedule(schedule.status).color}` }}>
                                <label>{enumStatusSchedule(schedule.status).title}</label>
                            </div>
                        </div>
                    ))
                }
            </div>

            <Loading isOpen={status === 'loading'} />
        </div>
    )
}

export default CallList;