import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse } from "../utils/types";
import { buildUrl } from "../api/services";
import { CmpAxios } from "../api/CmpAxios";
import { MessageError, Error } from "../utils/ErrorResponse";
import { NotificationType } from "./notificationSlice";


export type Profile = {
    id: number | null,
    name: string
}

export type PromotionBase64 = {
    id: number,
    name: string,
    initDate: string,
    endDate: string,
    // image: any,
    // imageUrl: string,
    imageBase64: string,
    punctuation: number,
    market: string,
    profile: Profile[],
    description: string,
    Ops: string[],
}

export type Promotion = {
    id: number,
    name: string,
    initDate: string,
    endDate: string,
    image: any,
    imageUrl: string,
    punctuation: number,
    market: string,
    profile: Profile[],
    description: string,
    Ops: string[],
}

type PromotionApiState = {
    listResponse: Promotion[],
    listResponseBase64: PromotionBase64[],
    getResponse: Promotion,
    status: "idle" | "loading" | "failed" | 'ready',
    error: Error | null,
    promotionDelete: boolean | null,
    promotionUpdate: boolean | null,
    promotionCreated: boolean | null,
    promotionImageUpdate: boolean | null,
    // listImages: any[]
};

const initialState: PromotionApiState = {
    listResponse: [],
    listResponseBase64: [],
    getResponse: {} as Promotion,
    status: "idle",
    error: null,
    promotionDelete: null,
    promotionCreated: null,
    promotionUpdate: null,
    promotionImageUpdate: null
    // listImages: [],
};

export const createPromotion = createAsyncThunk(
    "promotion/create",
    async (data: Partial<Promotion>, { rejectWithValue }) => {
        let body = new FormData();
        if (
            data.name &&
            data.initDate &&
            data.endDate &&
            data.description &&
            data.market &&
            data.punctuation &&
            data.profile &&
            data.image &&
            data.Ops
        ) {
            let profile: string = '' + data.profile.map(prof => prof.name);
            let ops: string = '' + data.Ops.map(op => op);
            body.append('name', data.name);
            body.append('initDate', data.initDate);
            body.append('endDate', data.endDate);
            body.append('description', data.description);
            body.append('market', data.market);
            body.append('punctuation', data.punctuation.toString());
            body.append('profile', profile);
            body.append('OPs', ops);
            body.append('image', data.image);

            let baseRequest = await buildUrl('createPromotion');
            let token = localStorage.getItem('token');

            const request = await CmpAxios(
                baseRequest.route,
                baseRequest.method,
                body,
                token
            );

            if (!request.error) {
                return request.response;
            } else {
                return rejectWithValue(request.response);
            }

        }
    }
);


export const clearResponse = createAsyncThunk(
    "promotion/clearResponse",
    async (data: any) => {
        return true;
    }
);


export const listPromotion = createAsyncThunk(
    "promotion/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listPromotion');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );

            //const response = { data: mockPromotions };
            // const response = await axiosInstance.get(`/`);
            //return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const listPromotionOps = createAsyncThunk(
    "promotionOps/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listPromotionOps');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const listPromotionOpsBase64 = createAsyncThunk(
    "promotionOpsBase64/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listPromotionOpsBase64');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const listCurrentPromotion = createAsyncThunk(
    "currentPromotion/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listCurrentPromotion');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );

            //const response = { data: mockPromotions };
            // const response = await axiosInstance.get(`/`);
            //return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const listCurrentPromotionOps = createAsyncThunk(
    "currentPromotionOps/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listCurrentPromotionOps');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const listCurrentPromotionOpsBase64 = createAsyncThunk(
    "currentPromotionOpsBase64/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listCurrentPromotionOpsBase64');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getPromotion = createAsyncThunk(
    "promotion/get",
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getPromotion');
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );

            //const response = { data: mockPromotions.find(prom => prom.id === id) ?? {} as Promotion };
            // const response = await axiosInstance.get(`/`);
            //return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;

                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);

export const deletePromotion = createAsyncThunk(
    "promotion/delete",
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('deletePromotion');
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );

            //const response = { data : mockPromotions.find(promot => promot.id === id) ?? {} as Promotion };
            //const response = await axiosInstance.delete(`/delete/${id}`);
            //return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const updatePromotion = createAsyncThunk(
    "promotion/update",
    async (data: Partial<Promotion>, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('updatePromotion');
            let token = localStorage.getItem('token');
            let body = {
                id: data.id,
                name: data.name,
                description: data.description,
                endDate: data.endDate,
                initDate: data.initDate,
                market: data.market,
                punctuation: data.punctuation,
                profiles: data.profile?.map(prof => prof.name),
                OPs: data.Ops?.map(op => op),
            }

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                body,
                token
            );

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const updatePromotionImage = createAsyncThunk(
    "promotion/update/image",
    async (data: Partial<Promotion>, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('updatePromotionImage');
            let token = localStorage.getItem('token');
            let body = {
                id: data.id,
                image: data.image
            }

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                body,
                token
            );

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

const promotionSlice = createSlice({
    name: "promotion",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createPromotion.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createPromotion.fulfilled, (state, action) => {
                state.status = "ready";
                state.promotionCreated = true;
                state.getResponse = action.payload;
            })
            .addCase(createPromotion.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar criar a promoção!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })

            .addCase(updatePromotion.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(updatePromotion.fulfilled, (state, action) => {
                state.status = "ready";
                state.promotionUpdate = true;
                state.getResponse = action.payload;
            })
            .addCase(updatePromotion.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar atualizar a promoção!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })

            .addCase(updatePromotionImage.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(updatePromotionImage.fulfilled, (state, action) => {
                state.status = "ready";
                state.promotionImageUpdate = true;
                state.getResponse = action.payload;
            })
            .addCase(updatePromotionImage.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar atualizar a imagem da promoção!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })

            .addCase(listPromotion.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listPromotion.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(listPromotion.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar listar as promoções!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })


            
            .addCase(listPromotionOpsBase64.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listPromotionOpsBase64.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponseBase64 = action.payload;
            })
            .addCase(listPromotionOpsBase64.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar listar as promoções!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })


            
            .addCase(listPromotionOps.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listPromotionOps.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(listPromotionOps.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar listar as promoções!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })



            .addCase(listCurrentPromotion.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listCurrentPromotion.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(listCurrentPromotion.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar listar as promoções!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })



            .addCase(listCurrentPromotionOps.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listCurrentPromotionOps.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(listCurrentPromotionOps.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar listar as promoções!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })



            .addCase(listCurrentPromotionOpsBase64.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listCurrentPromotionOpsBase64.fulfilled, (state, action) => {
                state.status = "ready";
                state.listResponseBase64 = action.payload;
            })
            .addCase(listCurrentPromotionOpsBase64.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar listar as promoções!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })



            .addCase(getPromotion.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getPromotion.fulfilled, (state, action) => {
                state.status = "idle";
                state.getResponse = action.payload;
            })
            .addCase(getPromotion.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar buscar as informações da promoção!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })

            .addCase(deletePromotion.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(deletePromotion.fulfilled, (state, action) => {
                state.status = "ready";
                state.promotionDelete = true;
                state.getResponse = action.payload;
            })
            .addCase(clearResponse.fulfilled, (state, action) => {

                state.getResponse = {} as Promotion;
            })
            .addCase(deletePromotion.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error =
                        MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar deletar a promoção!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })
    },
});

export default promotionSlice.reducer;