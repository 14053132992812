import { useState, useEffect } from "react";
import { ImportContacts } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import './PromoterHome.css'
import { confirmMeet, finalizeMeet, getPromoterConfirmeds, getPromoterPending, getTimes, refuseMeet } from "../../../slices/meetSlice";
import DateFunctions from "../../../utils/DateFunctions";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";

import PromoterMeet from "../../../components/PromoterMeet/PromoterMeet";
import MenuOpt from "../../../components/Header/MenuOpt";

const Promoter = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [selected, setSelected] = useState('');
    const [initialDate, setInitialDate] = useState<Date>();
    const dispatch = useAppDispatch();
    const { promoterMeetsConfirm, meetRefused, availableTimes, success, pendingMeets, promoterPendingList, meetConfirmed, meetFinalized } = useAppSelector((state) => state.meet);

    useEffect(() => {
        refresh()
    }, []);


    useEffect(() => {
        if (success || meetConfirmed || meetRefused || meetFinalized) {
            refresh()
        }
    }, [success, meetConfirmed, meetRefused, meetFinalized]);


    useEffect(() => {

        if (initialDate) {
            let formDate = initialDate.toISOString().split('T')[0];
            dispatch(getTimes({ date: formDate }))
        }


    }, [initialDate]);

    function refresh() {
        setStep(1);
        let todayDate = new Date()
        let formDate = todayDate.toISOString().split('T')[0]
        dispatch(getPromoterConfirmeds(true))
        dispatch(getTimes({ date: formDate }))
        dispatch(getPromoterPending(true))
    }


    function meetAction({ id, action }: { id: number, action: string }) {

        if (action == 'ACCEPT') {
            dispatch(confirmMeet({ id: id }))
        }

        if (action == 'REFUSE') {
            dispatch(refuseMeet({ id: id }))
        }

        if (action == 'FINALIZE') {
            dispatch(finalizeMeet({ id: id }))
        }

    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100vh', backgroundColor: '#F7F7F7' }}>
            <MenuOpt />
            <div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 25, marginTop: 20, marginBottom: 20 }} >
                Agendamentos
            </div>

            {
                promoterPendingList && promoterPendingList.length > 0 && promoterPendingList.map((meetShow: any, index: number) => {
                    return (
                        <div key={index} style={{ marginRight: 5, marginLeft: 5, justifyContent: 'center', alignContent: 'center' }}>
                            <PromoterMeet 
                                meetAction={meetAction} 
                                select={setSelected} 
                                selected={selected} 
                                id={meetShow.id} 
                                day={DateFunctions.formatSimpleDate(meetShow.scheduleDate)} 
                                status="PENDING" 
                                time={meetShow.time.substring(0, meetShow.time.length - 3)} 
                            />
                        </div>
                    )
                })
            }

            {
                promoterMeetsConfirm && promoterMeetsConfirm.length > 0 && promoterMeetsConfirm.map((meetShow: any, index: number) => {
                    return (
                        <div key={index} style={{ marginRight: 5, marginLeft: 5, justifyContent: 'center', alignContent: 'center' }}>
                            <PromoterMeet 
                                meetAction={meetAction} 
                                select={setSelected} 
                                selected={selected} 
                                id={meetShow.id} 
                                day={DateFunctions.formatSimpleDate(meetShow.scheduleDate)} 
                                status="ACTIVE" 
                                time={meetShow.time.substring(0, meetShow.time.length - 3)} 
                            />
                        </div>
                    )
                })
            }

            {
                (!promoterMeetsConfirm || promoterMeetsConfirm.length == 0) && (!promoterPendingList || promoterPendingList.length == 0) && (
                    <div>
                        <div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: 17, marginTop: 50, marginBottom: 20 }} >
                            Nenhuma conferência agendada
                        </div>
                    </div>
                )
            }


            <button className="promoterHistoricBtn" onClick={() => navigate('/promoter/schedule-finalized-list')}>
                <ImportContacts/>
            </button>
        </div>
    );
};

export default Promoter;
