import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse } from "../utils/types";
import { buildUrl } from "../api/services";
import { CmpAxios } from "../api/CmpAxios";
import { MessageError, Error } from "../utils/ErrorResponse";
import { NotificationType } from "./notificationSlice";

type OpApiState = {
    listResponse: Array<string[]>,
    getResponse: string[],
    status: "idle" | "loading" | "failed" | 'ready',
    error: Error | null,   
}

const initialState: OpApiState = {
    listResponse: [],
    getResponse: [],
    status: "idle",
    error: null,
}

export const getOps = createAsyncThunk(
    "op/get",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getOps');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;

                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);

const OpSlice = createSlice({
    name: "op",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getOps.pending, (state) => {
            state.status = "loading";
            state.error = null;
        })
        .addCase(getOps.fulfilled, (state, action) => {
            state.status = "ready";
            state.getResponse = action.payload;
        })
        .addCase(getOps.rejected, (state, action) => {
            state.status = "failed";
            if (action.payload) {
                state.error = MessageError(({ message: action.payload } as ErrorResponse).message) ||
                    {
                        response: "",
                        message: "Falha ao tentar buscar as informações de op!",
                        notificationType: NotificationType.Error
                    } as Error;
            }
        })
    }
});

export default OpSlice.reducer;