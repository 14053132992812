import { Outlet } from "react-router-dom";
import './layouts.css'

import useWindowDimensions from "../hooks/useWindowDimensions";


const DefaultLayout = () => {
  const { height, width } = useWindowDimensions();

  return (
    // <div className='rootDiv' style={{ maxWidth: 900, overflowX: 'hidden', margin: 'auto' }}>
    <div className='rootDiv'>

      <div className="main">
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>

  );
};

export default DefaultLayout;


