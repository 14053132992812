import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { formatInput } from "../../utils/input-mask";
import "./simpleInput.css";

function SimpleInput({
  value,
  type = "text",
  label,
  placeholder = " ",
  setValue,
  isBrl = false,
  isDate = false,
  isDoc = false,
  isPhone = false,
  isCep = false,
  isCpf = false,
  isPassword = false,
}: {
  value: string | number | undefined;
  type?: string;
  label: string;
  placeholder?: string;
  setValue: any;
  isBrl?: boolean;
  isDate?: boolean;
  isDoc?: boolean;
  isPhone?: boolean;
  isCep?: boolean;
  isCpf?: boolean;
  isPassword?: boolean;
}) {

  const [typePassword, setTypePassword] = useState(type);

  const changeTypePassword = () => {
    if (typePassword === 'password') {
      setTypePassword('text');
    } else {
      setTypePassword('password');
    }
  }

  return (
    <div className="simpleInput">
      <label className="simpleInput">{label}</label>
      {
        isPassword ? (
          <div className='simpleInputPasswordView' >
            <input
              className="simpleInput"
              value={value}
              placeholder={placeholder}
              type={typePassword}
              onChange={(e) =>
                setValue(formatInput(e, { isBrl, isDate, isDoc, isPhone, isCep, isCpf }))
              }
            />  
            <button onClick={changeTypePassword} >
              {
                typePassword === 'password' ? (
                  <Visibility/>
                ): (
                  <VisibilityOff/>
                )
              }
            </button>
          </div>
        ) : (
          <input
            className="simpleInput"
            value={value}
            placeholder={placeholder}
            type={type}
            onChange={(e) =>
              setValue(formatInput(e, { isBrl, isDate, isDoc, isPhone, isCep, isCpf }))
            }
          />
        )
      }
    </div>
  );
}

export default SimpleInput;
