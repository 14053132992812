import { useState, useEffect } from "react";
import { ArrowBack, Remove, Class } from "@mui/icons-material";
import { useNavigate } from 'react-router';

import Header from "../../../components/Header/Header";
import Search from "../../../components/Search/Search";
import Loading from "../../../components/Loading/Loading";

import { enumStatusSchedule } from "../../../utils/enum";
import DateFunctions from '../../../utils/DateFunctions';
import { getFinalizedMeets, Schedule } from '../../../slices/meetSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

import './ScheduleFinalizedList.css';

const ScheduleFinalizedList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const [schedules, setSchedules] = useState([] as Schedule[]);

    const { listResponse, status } = useAppSelector((state) => state.meet);

    useEffect(() => {
        dispatch(getFinalizedMeets());
    }, [dispatch]);

    useEffect(() => {
        setSchedules(listResponse);
    }, [listResponse]);

    useEffect(() => {
        if(!search) {
            setSchedules(listResponse);
        } else {
            let result = listResponse.filter((item : Schedule) => (
                    DateFunctions.formatSimpleDate(item.scheduleDate).indexOf(search.toLowerCase()) != -1 ||
                    item.status.toLowerCase().indexOf(search.toLowerCase()) != -1 ||
                    item.time.toString().indexOf(search.toLowerCase()) != -1 
                )
            );
            setSchedules(result);
        }
    }, [search]);

    return (
        <div className="scheduleFinalizedListMain">
            <Header/>
            <div className="scheduleFinalizedListTitle" >
                <ArrowBack onClick={() => navigate('/promoter/home')} />
                <h1>Histórico</h1>
            </div>

            <Search 
                value={search}
                setValue={setSearch}
            />

            <div style={{ paddingBottom: "45px" }} >
                {
                    schedules.length > 0 && schedules.map((schedule, index) => (
                        <div className='schedulePromoterCardList' key={index} >
                            <h3>Chamada</h3>
                            <label><strong>Horário:</strong> {schedule.time.toString()}</label>
                            <label><strong>Data:</strong> {DateFunctions.formatSimpleDate(schedule.scheduleDate)}</label>
                            <div className="schedulePromoterCardtime" style={{ backgroundColor: `${enumStatusSchedule(schedule.status).color}` }}>
                                <label>{enumStatusSchedule(schedule.status).title}</label>
                            </div>
                        </div>
                    ))
                }
            </div>

            <Loading isOpen={status === 'loading'} />
        </div>
    );
}

export default ScheduleFinalizedList;