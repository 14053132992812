import './promotionHome.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../../components/Header/Header';
import Button from '../../../components/Button/Button';
import Carousel from '../../../components/Carousel/Carousel';
import TextArea from '../../../components/TextArea/TextArea';

// import { listReward } from '../../../slices/rewardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import Qrscan from '../../../components/QrScan/QrScan';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';

const PromotionHome = () => {
    const navigate = useNavigate();
    const [descriptionTin, setDescriptionTin] = useState('');
    const dispatch = useAppDispatch();
    const { listResponse } = useAppSelector((state) => state.reward);
    const [scan, setScan] = useState(false);

    // useEffect(() => {

    //     setScan(false)
    // }, [dispatch]);


    useEffect(() => {
        setScan(false)
        // dispatch(listReward());
    }, []);

    return (
        <div className='PromotionHomeContainer' >
            <Header />
            <div className='promotionHomeTitle' >
                <h1>Programa </h1>
            </div>
            <div className='promotionHomeOptions' >
                <div style={{ width: '180px' }} >
                    <Button
                        title='Lista de promoções'
                        click={() => navigate('/admin/promotion-list')}
                    />
                </div>
                <div style={{ width: '180px' }} >
                    <Button
                        title='Cadastro de promoções'
                        click={() => navigate('/admin/promotion-form')}
                    />
                </div>
            </div>
            <h2>Alterar resgate de prêmios</h2>
            {/* <Carousel>
                {
                    listResponse && listResponse.map((item, index) => (
                        <div className='carrouselAward' key={index} >
                            <span>{item.score}</span>
                            <img src={item.image} alt="reward image" className='image' />
                        </div>
                    ))
                }
            </Carousel> */}
            {/* <h2>Informações da lata</h2>
            <div className='promotionHomeTextArea' >
                <TextArea
                    placeholder='Informações distintas sobre a lata configurada pelo cliente'
                    setValue={() => setDescriptionTin}
                    value={descriptionTin}
                    disabled={true}
                />
            </div>
            <h2 style={{ color: '#D02030' }} >Escaneie outros QR codes</h2>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
                <div style={{ minWidth: '150px', marginBottom: '30px' }} >
                    <Button
                        click={() => setScan(true)}
                        title='Escanear'
                    />
                </div>
            </div> */}

            {scan && <Qrscan />}
        </div>
    );
}

export default PromotionHome;