import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse } from "../utils/types";
import { buildUrl } from "../api/services";
import { CmpAxios } from "../api/CmpAxios";

export type Tutorial = {
    id : number,
    url : string,
    title : string,
    description : string,
    market : string,
    profile: string
}

type TutorialApiState = {
    listResponse: Tutorial[],
    getResponse: Tutorial,
    status: "idle" | "loading" | "failed" | "ready";
    error: string | null,
    tutorialCreated: boolean | null,
    tutorialUpdate: boolean | null,
    tutorialDelete: boolean | null
};

const initialState : TutorialApiState = {
    listResponse: [],
    getResponse: {} as Tutorial,
    status: "idle",
    error: null,
    tutorialCreated: null,
    tutorialDelete: null,
    tutorialUpdate: null
};

export const createTutorial = createAsyncThunk(
    "tutorial/create",
    async (data: Partial<Tutorial>, {rejectWithValue}) => {
        let baseRequest = await buildUrl('createTutorial');
        let token = localStorage.getItem('token');
        const request = await CmpAxios(
            baseRequest.route,
            baseRequest.method,
            data,
            token
        );

        if (request.error) {
            return rejectWithValue(request.response);
        } else {
            return request.response;
        }
    }
);

export const listTutorial = createAsyncThunk(
    "tutorial/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listTutorial');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );

            //const response = { data: mockTutorials };
            //const response  = await axiosInstance.get(`/`);
            //return response.data;
        } catch (error) {
            if(error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getTutorial = createAsyncThunk(
    "tutorial/get",
    async (id : number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getTutorial');
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );

            //const response = { data: mockTutorials.find(tutor => tutor.id === id) ?? {} as Tutorial };
            // const response = await axiosInstance.get(`/`);
            //return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const deleteTutorial = createAsyncThunk(
    "tutorial/delete",
    async (id :  number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('deleteTutorial');
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
            
            //const response = {data : mockTutorials.find(tutor => tutor.id === id) ?? {} as Tutorial};
            //const response = await axiosInstance.delete(`/${id}`);
            //return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const updateTutorial = createAsyncThunk(
    "tutorial/update",
    async (data : Partial<Tutorial>, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('updateTutorial');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                data,
                token
            );
            
            //const response = await axiosInstance.put('/', data);
            //return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

const tutorialSlice = createSlice({
    name: "tutorial",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createTutorial.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createTutorial.fulfilled, (state, action) => {
                state.status = "ready";
                state.tutorialCreated = true;
            })
            .addCase(createTutorial.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = (action.payload as ErrorResponse).message || "Create tin data failed";
                }
            })

            .addCase(listTutorial.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listTutorial.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(listTutorial.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = 
                        (action.payload as ErrorResponse).message ||
                        "Get list tutorial data failed";
                } else {
                    state.error = action.error.message || "Get list tutorial data failed";
                }
            })

            .addCase(getTutorial.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getTutorial.fulfilled, (state, action) => {
                state.status = "idle";
                state.getResponse = action.payload;
            })
            .addCase(getTutorial.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = 
                        (action.payload as ErrorResponse).message ||
                        "Get tutorial data failed";
                } else {
                    state.error = action.error.message || "Get tutorial data failed";
                }
            })

            .addCase(deleteTutorial.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(deleteTutorial.fulfilled, (state, action) => {
                state.status = "ready";
                state.tutorialDelete = true;
                state.getResponse = action.payload;
            })
            .addCase(deleteTutorial.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = 
                        (action.payload as ErrorResponse).message ||
                        "Delete tutorial data failed";
                } else {
                    state.error = action.error.message || "Delete tutorial data failed";
                }
            })

            .addCase(updateTutorial.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(updateTutorial.fulfilled, (state, action) => {
                state.status = "ready";
                state.tutorialUpdate = true;
                //state.getResponse = action.payload;
            })
            .addCase(updateTutorial.rejected, (state, action) => {
                state.status = "idle";
                if (action.payload) {
                    state.error = (action.payload as ErrorResponse).message || "Update tutorial data failed";
                } else {
                    state.error = action.error.message || "Update tutorial data failed";
                }
            })
    },
});

export default tutorialSlice.reducer;