import { ArrowBack, Add, Remove } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./promotionForm.css";

import CustomSelect from "../../../components/CustomSelect/customSelect";
import DatePicker from "../../../components/DatePicker/DatePicker";
import FileSelector from "../../../components/FileSelector/FileSelector";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import SimpleInput from "../../../components/SimpleInput/SimpleInput";
import Loading from "../../../components/Loading/Loading";
import TextArea from "../../../components/TextArea/TextArea";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { createPromotion, updatePromotion, PromotionBase64, Promotion, Profile, updatePromotionImage } from "../../../slices/promotionSlice";
import { getOps } from "../../../slices/opSlice";
import { AUTOMOTIVE_PROFILES, HOUSE_PROFILES, MARKET_LIST } from '../../../utils/profiles';
import DateFunctions from "../../../utils/DateFunctions";
import { NotificationType, showNotification } from "../../../slices/notificationSlice";

const PromotionForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [name, setName] = useState("");
  const [initialDate, setInitialDate] = useState<Date>();
  const [finalDate, setFinalDate] = useState<Date>();
  const [picture, setPicture] = useState();
  const [score, setScore] = useState(0);
  const [market, setMarket] = useState("");
  const [saveMarket, setSaveMarket] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [profiles, setProfiles] = useState([] as Profile[]);
  const [ops, setOps] = useState([] as string[]);
  const [disabled, setDisabled] = useState(true);

  const { listResponseBase64, status, error, promotionUpdate, promotionCreated, promotionImageUpdate } = useAppSelector((state) => state.promotion);
  const { getResponse: opResponse, status: opStatus, error: opError } = useAppSelector((state) => state.op);

  useEffect(() => {
    dispatch(getOps());
    if (state && state.id) {
      let promotionResponse : PromotionBase64 | undefined = listResponseBase64.find((promotion) => {
        return promotion.id === state.id;
      });

      if (promotionResponse) {
        const { 
          name, 
          punctuation, 
          profile, 
          market, 
          initDate, 
          endDate, 
          description,
          imageBase64,
          // image,
          // imageUrl,
          Ops,
        } = promotionResponse;

        setName(name);
        setInitialDate(DateFunctions.formatDateFromApi(initDate));
        setFinalDate(DateFunctions.formatDateFromApi(endDate));
        // setPicture(image);
        setScore(punctuation);
        setProfiles(profile);
        setMarket(market); 
        setSaveMarket(market);
        setDescription(description);
        setImageUrl(imageBase64);
        setOps(Ops);
      }
    }
  }, []);

  useEffect(() => {
    
    if (state && state.id) {
      setDisabled(
        Boolean(
          !(
            name &&
            initialDate &&
            finalDate &&
            score &&
            market &&
            profiles &&
            ops
          )
        )
      );
    } else {
      setDisabled(
        Boolean(
          !(
            name &&
            initialDate &&
            finalDate &&
            picture &&
            score &&
            market &&
            profiles &&
            ops
          )
        )
      );
    }
  }, [finalDate, initialDate, market, name, picture, profiles, ops, score]);

  const handleClick = () => {
    if (initialDate && finalDate && score) {
      if (state && state.id) {
        let id: number = state.id;
        dispatch(updatePromotion({
          id : id,
          name : name,
          initDate : DateFunctions.formatSimpleDateLine(initialDate),
          endDate : DateFunctions.formatSimpleDateLine(finalDate),
          // image : picture,
          punctuation : score,
          description : description,
          market : market,
          profile : profiles,
          Ops: ops,
        } as Partial<Promotion>));
      } else {
        dispatch(
          createPromotion({
            name : name,
            initDate : DateFunctions.formatSimpleDate(initialDate),
            endDate : DateFunctions.formatSimpleDate(finalDate),
            image : picture,
            punctuation : score,
            description : description,
            market : market,
            profile : profiles,
            Ops: ops,
          } as Partial<Promotion>)
        );
      }
    }

    if (picture && state && state.id) {
      let id = state.id;
      dispatch(
        updatePromotionImage({
          id: id,
          image: picture
        } as Partial<Promotion>)
      );
    }
  };

  const updateProfileList = (value : string) => {
    let isEqual : boolean = false;

    profiles.map((prof) => {
      if(prof.name === value) {
        isEqual = true
      }
    })
    if(!isEqual) {
      setProfiles([...profiles, { id : null, name : value }]);
    }
  }

  const removeProfileList = (value : string) => {
    let profFilter = profiles.filter(prof => prof.name != value);
    setProfiles(profFilter);
  }

  const updateOpList = (value : string) => {
    let isEqual : boolean = false;

    ops.map((op) => {
      if(op === value) {
        isEqual = true
      }
    })
    if(!isEqual) {
      setOps([...ops, value]);
    }
  }

  const removeOpList = (value : string) => {
    let opsFilter = ops.filter(op => op != value);
    setOps(opsFilter);
  }

  useEffect(() => {
    if (status === 'ready') {
      navigate('/admin/promotion-list');
    }
  }, [status, promotionUpdate, promotionCreated, promotionImageUpdate]);

  useEffect(() => {
    if(market != saveMarket) {
      setProfiles([]);
      setSaveMarket(market);
    }
  }, [market]);

  useEffect(() => {
    if (error && status === 'failed') {
      dispatch(showNotification({
        message: error.message,
        type: error.notificationType as NotificationType,
      }))
    }
  }, [error, status]);

  return (
    <div className="promFormContainer">
      <div className="promotionForm">
        <div className="headerPromotionForm">
          {/* <button onClick={() => navigate("../../admin/promotion-list")}>
            <ArrowBack />
          </button> */}
          <h1 className="title">{state && state.id ? "Edição" : "Cadastro"}</h1>
        </div>

        <SimpleInput label="Nome" value={name} setValue={setName} />

        <div className="datesLine">
          <DatePicker
            label="Data de início"
            value={initialDate}
            setValue={setInitialDate}
          />
          <DatePicker
            label="Data do fim"
            value={finalDate}
            setValue={setFinalDate}
          />
        </div>

        {
          !(state && state.id) ? (
            <FileSelector
              label="Carregar foto"
              setValue={setPicture}
              multiple={false}
            />
          ) : (
            <div className="promotionFormSelectImg">
              <div>
                <img 
                  // src={imageUrl} 
                  src={`data:image/jpeg;base64,${imageUrl}`} 
                  alt="Imagem atual"
                  style={{ width : 100, maxHeight : 100, borderRadius: 8, border: '3px solid #EBEBEB' }}
                />
                <FileSelector
                  label="Carregar nova foto"
                  setValue={setPicture}
                  multiple={false}
                  secondStyle={true}
                />
              </div>
            </div>
          )
        }

        <SimpleInput
          type="number"
          label="Pontuação"
          value={score}
          setValue={setScore}
        />

        <CustomSelect
          value={market}
          label="Mercado"
          setValue={setMarket}
          listArray={MARKET_LIST}
        />

        {
          profiles != undefined && profiles.map((profile, index) => (
            <div className="selectedItems" key={index}>
              <label>Perfil selecionado:</label>
              <div className="selecteditemsContainer">
                <label>
                  {profile.name}
                </label>
                <button className="promotionFormProfileAddBtn" onClick={() => removeProfileList(profile.name)}>
                  <Remove/>
                </button>
              </div>
            </div>
          ))
        }

        <CustomSelect
          value={''}
          label="Selecionar Perfil"
          setValue={updateProfileList}
          listArray={
            market === MARKET_LIST[0].customValue 
            ? HOUSE_PROFILES 
            : market === MARKET_LIST[1].customValue 
            ? AUTOMOTIVE_PROFILES
            : []
          }
        />

        {
          !market ? (
            <label className="profileWarning" >
              Selecione primeiro um mercado para selecionar um perfil
            </label>
          ) : null
        }

        {
          ops != undefined && ops.map((op, index) => (
            <div className="selectedItems" key={index}>
              <label>OP selecionado:</label>
              <div className="selecteditemsContainer">
                <label>{op}</label>
                <button className="promotionFormProfileAddBtn" onClick={() => removeOpList(op)}>
                  <Remove/>
                </button>
              </div>
            </div>
          ))
        }

        <CustomSelect
          value=""
          label="Selecionar ordem de produção (OP)"
          setValue={updateOpList}
          listArray={ opResponse.map((item) => ({ customValue: item, customLabel: item })) }
        />

        <div className="promotionFormTextArea" >
          <TextArea
            placeholder=""
            setValue={setDescription}
            value={description}
            backgraundColor="#EBEBEB"
            label="Descrição"
            width="100%"
          />
        </div>
      </div>

      <PrimaryButton
        label={state && state.id ? "Salvar alteração" : "Cadastrar"}
        onClick={handleClick}
        padding="30px 0"
        disabled={disabled}
      />

      <Loading isOpen={ status === 'loading' } />
    </div>
  );
};

export default PromotionForm;
