import { ThemeProvider } from "@emotion/react";
import createTheme from "@mui/material/styles/createTheme";
import { DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import "./datePicker.css";
import { mockTimes } from "../../utils/mocks";
import ImgButton from "../Button/ImgButton";
import colors from "../../utils/colors";
const DateTimePicker = ({
  label,
  value,
  selectFunc,
  selected,
  setValue,
  availables,
}: {
  label: string;
  value: any;
  selectFunc: any;
  selected: string;
  setValue: Dispatch<SetStateAction<any>>;
  availables: any;
}) => {

  let todayDate = dayjs(new Date());

  return (
    <div className="calendar" style={{ marginTop: 9 }}>
      <div className="dateTimeCalendar">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={newTheme}>
            <DateCalendar
              value={dayjs(value)}
              minDate={todayDate}
              onChange={(ev: string | any) => {
                if (ev) {
                  const formatedValue = dayjs(ev, "DD-MM-YYYY").toDate();
                  setValue(formatedValue);
                }
              }}
            />
          </ThemeProvider>
        </LocalizationProvider>

        <div style={{ display: 'flex', flexWrap: 'wrap', overflowX: 'scroll', height: 90, justifyContent: 'center' }}>

          {availables && availables.length > 0 && availables?.map((timeShow: any, index: number) => {
            return (
              <div key={index} style={{ marginRight: 5, marginLeft: 5, justifyContent: 'center', alignContent: 'center', minWidth: 77, maxWidth: 77 }}>
                <ImgButton textColor={selected == timeShow.id ? 'white' : colors.red} color={selected == timeShow.id ? colors.red : colors.grey} img={selected == timeShow ? 'clock' : 'redclock'} title={timeShow.startTime.substring(0, timeShow.startTime.length - 3)} click={() => { selectFunc(timeShow.id) }} />
              </div>
            )
          })}
        </div>
      </div>

    </div>
  );
};

export default DateTimePicker;

const newTheme = (theme: any) =>
  createTheme({
    ...theme,
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            width: "fit-content",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderWidth: 0,
            height: "fit-content",
            width: "fit-content",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              borderRadius: 0,
              backgroundColor: "#D01235",
            },
            "&.Mui-selected:hover": {
              borderRadius: 0,
              backgroundColor: "#D01235",
            },

            borderRadius: 0,
          },
        },
      },
    },
  });
