import { Dispatch, SetStateAction, useMemo, useState } from "react";
import "./fileSelector.css";
import image from "./fileSelector.png";

const FileSelector = ({
  label,
  setValue,
  multiple,
  secondStyle = false,
}: {
  label: string;
  setValue: Dispatch<SetStateAction<any>>;
  multiple: boolean;
  secondStyle?: boolean;
}) => {
  const [files, setFiles] = useState<File | null>(null);

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      let fileNames = files[0].name;
      for (let index = 1; index < files.length; index++) {
        fileNames = fileNames + ", " + files[index].name;
      }

      setFiles(files[0]);
      setValue(files[0]);
    }
  };

  return (
    <div style={secondStyle ? { display: 'flex', flexDirection: 'column', width: '100%', marginLeft: 10, alignItems: 'start' } : {} }>
      <label className="loadFilesTitle" style={secondStyle ? { margin: 0 } : {}} >{label}</label>
      <div className="fileSelector" style={secondStyle ? { flexDirection: 'column', gap: 0, alignItems: 'start', width: '100%' } : {}}>
        <label className="labelBtn" htmlFor="imagem" style={secondStyle ? { marginBottom: 10 } : {}} >
          <img src={image} className="logo" alt={label}/>
          {label == 'Arquivo:' ? 'Arquivo' : 'Imagem'}
        </label>
        <input
          type="file"
          id="imagem"
          multiple={multiple}
          onChange={handleFiles}
        />
        <label className="files">{files?.name}</label>
      </div>
    </div>
  );
};

export default FileSelector;
