import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./showcases.css";

import playImg from "../../images/playbtn.png";
import rewardImg from "../../images/reward.png";
import sacImg from "../../images/sac.png";
import faqImg from "../../images/faq.png";
import phone from "../../images/phone.png";

import colors from "../../utils/colors";
import { useAppSelector } from '../../hooks/redux-hooks';
import { buildPermissions } from "../../utils/Permissions";
import { SAC_URL } from "../../utils/constants";

const Showcases = () => {

  const navigate = useNavigate();
  const { userMarket } = useAppSelector((state) => state.user);
  const { userProfile } = useAppSelector((state) => state.auth);

  const [permissions, setPermissions] = useState<any | null>(null);

  useEffect(() => {
    if (userMarket && userProfile) {
      let permBuilded = buildPermissions(userMarket, userProfile);
      setPermissions(permBuilded);
    }
  }, [userMarket, permissions]);


  return (
    <div
      style={{
        backgroundColor: colors.greyBackground,
        flex: 0.2,
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        maxWidth: "1000px",
        width: "100%",
        margin: "auto",
      }}
    >

      <div
        style={{
          justifyContent: "space-around",
          flexDirection: "row",
          display: "flex",
        }}
      >
        {
          permissions && permissions.indexOf('tutorial') !== -1 &&
            <div
              onClick={() => navigate("/tutorial")}
              style={{
                position: "relative",
                backgroundColor: "white",
                width: 50,
                height: 50,
                borderRadius: 8,
                cursor: "pointer",
              }}
            >
              <center>
                <img src={playImg} alt="playbtn" style={{ marginTop: 13.5 }} />
              </center>
            </div>
        }

        {
          permissions && permissions.indexOf('reward') !== -1 &&
            <div
              onClick={() => navigate("/home-rewards")}
              style={{
                position: "relative",
                backgroundColor: "white",
                width: 50,
                height: 50,
                borderRadius: 8,
                cursor: "pointer",
              }}
            >
              <center>
                <img src={rewardImg} alt="reward" style={{ marginTop: 12.5 }} />
              </center>
            </div>
        }


        {
          permissions && permissions.indexOf('sac') !== -1 &&
            <div
              onClick={() => window.open(SAC_URL, '_blank', 'rel=noopener noreferrer')}
              style={{
                position: "relative",
                backgroundColor: "white",
                width: 50,
                height: 50,
                borderRadius: 8,
                cursor: "pointer",
              }}
            >
              <center>
                <img src={sacImg} alt="sac" style={{ marginTop: 12.5 }} />
              </center>
            </div>
        }
        <div
          onClick={() => navigate("/faq")}
          style={{
            position: "relative",
            backgroundColor: "white",
            width: 50,
            height: 50,
            borderRadius: 8,
            cursor: "pointer",
          }}
        >
          <center>
            <img src={faqImg} alt="faq" style={{ marginTop: 15.5 }} />
          </center>
        </div>

        {
          permissions && permissions.indexOf('conference') !== -1 &&
            <div
              onClick={() => navigate('/schedule')}
              style={{
                position: "relative",
                backgroundColor: "white",
                width: 50,
                height: 50,
                borderRadius: 8,
                cursor: "pointer",
              }}
            >

              <center>
                <img src={phone} alt="phone" style={{ marginTop: 13 }} />
              </center>
            </div>
        }
      </div>

      <div
        style={{
          justifyContent: "space-around",
          flexDirection: "row",
          display: "flex",
        }}
      >
        {
          permissions && permissions.indexOf('tutorial') !== -1 &&
            <div style={{ width: "100%", maxHeight: 30,   fontWeight: 'bold', textAlign: "center" }}>
              <span style={{ textAlign: "center", fontSize: 10 }}>Tutoriais</span>
            </div>
        }

        {
          permissions && permissions.indexOf('reward') !== -1 &&
            <div style={{ width: "100%", maxHeight: 30,   fontWeight: 'bold', textAlign: "center" }}>
              <span style={{ textAlign: "center", fontSize: 10 }}>Recompensas</span>
            </div>
        }

        {
          permissions && permissions.indexOf('sac') !== -1 &&
            <div style={{ width: "100%", maxHeight: 30,   fontWeight: 'bold', textAlign: "center" }}>
              <span style={{ textAlign: "center", fontSize: 10 }}>Sac</span>
            </div>
        }


        <div style={{ width: "100%", maxHeight: 30,   fontWeight: 'bold', textAlign: "center" }}>
          <span style={{ textAlign: "center", fontSize: 10 }}>Faq</span>
        </div>


        {
          permissions && permissions.indexOf('conference') !== -1 &&
            <div style={{ width: "100%", maxHeight: 30,  fontWeight: 'bold', textAlign: "center",  display: 'flex', alignItems: "center", justifyContent: "center" }}>
              <span style={{ textAlign: "center", fontSize: 10 }}>
                Fale com promotor
              </span>
            </div>
        }
      </div>
    </div>
  );
};

export default Showcases;

