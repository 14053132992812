import './faq.css'
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import arrowDown from '../../images/arrow_down.png'
import arrowUp from '../../images/arrow_up.png'
import menu from '../../images/menu.png'

import { useAppSelector } from '../../hooks/redux-hooks';
import { faqQuestions } from '../../utils/constants'
import FaqExpand from '../../components/FaqExpand/FaqExpand';
import BoschBtn from '../../components/Button/Button';
import SimpleRedHeader from '../../components/MenuHeader/SimpleRedHeader';

const Faq = () => {
  const { userMarket } = useAppSelector((state) => state.user);
  const [faqOpen, setFaqOpen] = useState(0);
  const navigate = useNavigate();


  function updateFaq(faqId: number) {
    if (faqOpen == faqId) {
      setFaqOpen(0)
    } else {
      setFaqOpen(faqId)
    }
  }

  return (
    <div style={{ backgroundColor: '#F7F7F7', height: '100vh', width: '100%' }}>

      <SimpleRedHeader title='FAQ.' />

      <div onClick={() => { updateFaq(1) }} className="helpBtn" style={{ marginTop: 20, marginBottom: 20 }}>
        <span className="btnText">Como posso participar do programa de prêmios?</span>
        <img alt='ar1' className="arrow" src={faqOpen == 1 ? arrowUp : arrowDown} />
      </div>
      {
        faqOpen === 1 ? (
          <div className="textHelp">
            Compre ou Utilize: Geralmente, as promoções requerem que você compre um produto, serviço ou realize uma ação
            específica,
            como preencher um formulário, seguir uma página nas redes sociais ou enviar uma foto. Certifique-se de cumprir
            todos os
            requisitos.
            <br />  <br />
            Guarde os Comprovantes: Mantenha todos os recibos, comprovantes de compra, ou qualquer documentação necessária
            como
            prova de participação na promoção. Isso pode ser necessário se você for o vencedor.
            <br /><br />
          </div>
        ) : null
      }


      {
        faqQuestions.map((question) => {
          return (
            <div key={question.id} >
              <FaqExpand 
                id={question.id} 
                faqOpen={faqOpen} 
                setFaqOpen={setFaqOpen} 
                text={question.text} 
                title={question.title} 
              />
            </div>
          )
        })
      }

      {
        userMarket && userMarket === 'Automotivo' ? (
          <>
            <br />
            <div style={{ width: '80%', margin: 'auto' }}>
              <BoschBtn 
                title='Ainda precisa de ajuda?' 
                click={() => { navigate('/schedule') }} 
              />
            </div>
          </>
        ) : null
      }
      
      <br />
      <br />

    </div>
  );
};

export default Faq;
