import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router';
import { useEffect, useState } from 'react';

import { MARKET_LIST } from '../../../utils/profiles';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { createTin, updateTin, updateTinImage, Tin, getTinQr, getTinBase64, TinBase64 } from '../../../slices/tinSlice';
import { NotificationType, showNotification } from '../../../slices/notificationSlice';

import Header from '../../../components/Header/Header';
import SimpleInput from '../../../components/SimpleInput/SimpleInput';
import CustomSelect from '../../../components/CustomSelect/customSelect';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import FileSelector from '../../../components/FileSelector/FileSelector';
import Loading from '../../../components/Loading/Loading';

import './tinForm.css';

const TinForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const [disabled, setDisabled] = useState(false);
    const { listResponse, status, tinCreated, tinUpdate, canImage, error, getResponseBase64 } = useAppSelector((state) => state.tin);

    const [url, setUrl] = useState('');
    const [image, setImage] = useState();
    const [information, setInformation] = useState('');
    const [market, setMarket] = useState('');
    const [op, setOp] = useState('');

    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        if (state && state.id) {
            dispatch(getTinBase64(state.id));
        }
    }, []);
    
    useEffect(() => {
        if (getResponseBase64 && status === "finish") {

            // let tinResponse: Tin | undefined = listResponse.find((tin) => tin.id === state.id);

            if (getResponseBase64 != {} as TinBase64) {
                const {
                    url,
                    // image,
                    information,
                    market,
                    imageBase64,
                    op,
                } = getResponseBase64;

                setUrl(url);
                // setImage(image);
                setInformation(information);
                setMarket(market);
                setImageUrl(imageBase64);
                let urlId = parseInt(url.split("?ID=")[1]);
                dispatch(getTinQr(urlId));
                setOp(op);
            }
        }
    }, [getResponseBase64]);

    useEffect(() => {
        if (state && state.id) {
            setDisabled(
                Boolean(!(
                    url &&
                    information &&
                    market &&
                    op
                ))
            );
        } else {
            setDisabled(
                Boolean(!(
                    url &&
                    image &&
                    information &&
                    market &&
                    op
                ))
            );
        }
    }, [url, image, information, market, op]);

    const handleClick = () => {
        if (url && information && market) {
            if (state && state.id) {
                let id: number = state.id;
                dispatch(
                    updateTin({
                        id,
                        url,
                        information,
                        market,
                        op
                    } as Partial<Tin>)
                );
            } else {
                dispatch(
                    createTin({
                        url,
                        image,
                        information,
                        market,
                        status: 'new',
                        op
                    } as Partial<Tin>)
                );
            }
        }

        if (image && state && state.id) {
            let id = state.id;
            dispatch(
                updateTinImage({
                    id: id,
                    image: image
                } as Partial<Tin>)
            );
        }
    }

    useEffect(() => {
        if (status === 'ready') {
            navigate('/admin/tin-list');
        }
    }, [status, tinCreated, tinUpdate]);

    // useEffect(() => {
    //     if (error && status === 'failed') {
    //         dispatch(showNotification({
    //             message: error.message,
    //             type: error.notificationType as NotificationType
    //         }))
    //     }
    // }, [error, status]);

    useEffect(() => {
        if (error && status === 'failed') {
            dispatch(showNotification({
                message: error.message,
                type: error.notificationType as NotificationType
            }))
        }
    }, [error, status]);

    return (
        <div className='tinFormMain' >
            <Header />
            <div className='tinFormTitle' >
                <ArrowBack onClick={() => navigate('/admin/tin-list')} style={{ cursor: "pointer" }} />
                <h1>{state && state.id ? "Edição " : "Cadastro "}</h1>
            </div>
            <div className='tinFormContent' >
                <SimpleInput
                    label='URL:'
                    value={url}
                    setValue={setUrl}
                />
                {
                    !(state && state.id) ? (
                        <FileSelector
                            label='Imagem:'
                            multiple={false}
                            setValue={setImage}
                        />
                    ) : (
                        <div className="tinFormSelectImg">
                            <div>
                                <img
                                    // src={imageUrl}
                                    src={`data:image/jpeg;base64,${imageUrl}`} 
                                    alt="Imagem atual"
                                    style={{ width: 100, maxHeight: 100, borderRadius: 8, border: '3px solid #EBEBEB' }}
                                />
                                <FileSelector
                                    label="Carregar nova foto"
                                    setValue={setImage}
                                    multiple={false}
                                    secondStyle={true}
                                />
                            </div>
                        </div>
                    )
                }
                <SimpleInput
                    label='Informaçôes:'
                    value={information}
                    setValue={setInformation}
                />
                <CustomSelect
                    label='Mercado:'
                    value={market}
                    setValue={setMarket}
                    listArray={MARKET_LIST}
                />
                <SimpleInput
                    label='Código de ordem de produção (OP):'
                    value={op.toUpperCase()}
                    setValue={setOp}
                />

                {
                    state && state.id ? (
                        <>
                            <label className="loadFilesTitle"  >QR Code:</label>
                            <img 
                                alt='image' 
                                style={{ width: 133, height: 133, marginTop: -10 }} 
                                src={`data:image/jpeg;base64,${canImage}`} 
                            />
                        </>
                    ) : null
                }

                <PrimaryButton
                    label='Finalizar'
                    onClick={handleClick}
                    disabled={disabled}
                    padding='30px 0'
                />
            </div>
            <Loading isOpen={status === 'loading'} />
        </div>
    );
}

export default TinForm;