import DateFunctions from "./DateFunctions";
import NumberFunctions from "./NumberFunctions";

export const formatInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    {
        isBrl = false,
        isDate = false,
        isDoc = false,
        isPhone = false,
        isCep = false,
        isCpf = false
    }: {
        isBrl?: boolean,
        isDate?: boolean,
        isDoc?: boolean,
        isPhone?: boolean,
        isCep?: boolean,
        isCpf?: boolean
    }
) => {
    if (isBrl) {
        return NumberFunctions.formatNumberToBRL(e?.target?.value);
    } else if (isDate) {
        return DateFunctions.maskDate(e.target.value);
    } else if (isDoc) {
        return NumberFunctions.cpfCnpjMask(e.target.value);
    } else if (isCpf) {
        return NumberFunctions.cpfMask(e.target.value);
    } else if (isPhone) {
        return NumberFunctions.maskPhone(e.target.value);
    } else if (isCep) {
        return NumberFunctions.cepMask(e.target.value);
    } else {
        return e.target.value;
    }
};
