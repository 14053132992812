export const BACKEND_BASE_URL = "latadigital.com.br:8443";

export const SAC_URL = "https://www.cmp.ind.br/sac-app/";




export const faqQuestions = [

    {
        title: 'A Tinta Emborrachada pode ser aplicada em caixa dágua e ou piscinas?',
        text: 'Por dentro não pode, por fora sim.',
        id: 2
    },

    {
        title: 'A Tinta para Piso pode ser aplicada sobre asfalto ?',
        text: 'Não vai ter boa aderência, não indicamos uso para este fim.Utilizar Tinta para Demarcação.',
        id: 3
    },

    {
        title: 'Após aplicar convertedor de ferrugem como proceder a pintura ?',
        text: 'O convertedor irá converter a ferrugem formando uma película protetora.Após a cura e ou secagem final pode - se aplicar um Fundo Laranja para proporcionar maior cobertura do Esmalte Sintético Imobiliário.Caso você queira encurtar etapas, você pode aplicar diretamente sobre a ferrugem o MetalShield da nossa marca.Eliminando o uso do convertedor e do fundo laranja.E detalhe, o nosso MetalShield já é acabamento.',
        id: 4
    },

    {
        title: 'Após aplicar o Removedor de ferrugem como proceder a pintura ?',
        text: 'O removedor deverá ser aplicado e após secagem final, conforme indicação do fabricante, eliminar restos de ferrugem que ficou com escovas e lixas e ainda deverá fazer limpeza com solução desengraxante.Para então aplicar Fundo Laranja e a tinta acabamento desejada, no caso Esmalte Sintético.',
        id: 5
    },
    {
        title: 'Como proceder após aplicação sela trinca para pintura em alvenaria ?',
        text: 'Após aplicação aguardar secagem final conforme indicação do fabricante.Sobre esta aplicação, usar Fundo Preparador de Paredes, Selador Acrílico e por último Tinta Acrílica(aguardar secagem dos produtos subsequentes conforme indicação do fabricante).',
        id: 6
    },

    {
        title: 'O que é indicado para aplicação de pisos de madeira ?',
        text: 'Selador Epóxi PDA, Primer Epóxi PDA e Esmalte Epóxi PDA.',
        id: 7
    },

    {
        title: 'O Removedor remove tinta Emborrachada, Massas Acrílicas e / ou Corridas ?',
        text: 'Pode até remover, mas não recomendamos, pois o uso incorreto pode trazer problemas.Por exemplo, o removedor pode penetrar no reboco após a retirada da tinta e / ou massa e permanecer no cimento, podendo ocorrer reações com a tinta e prejudicar secagem e ou manchamento.',
        id: 8
    },

    {
        title: 'Para renovar a pintura de casas aplicadas com Esmalte Sintético Imobiliário posso aplicar uma demão de Verniz ?',
        text: 'Não é um produto indicado para aplicação sobre Esmaltes, podendo ocorrer trincas e amarelamento.',
        id: 9
    },
    {
        title: 'Posso aplicar Tinta Acrílica e / ou Emborrachada em canos ?',
        text: 'Tinta Acrílica não indicamos, não terá boa aderência.Emborrachada pode ser usada.',
        id: 10
    },

    {
        title: 'Posso aplicar Tinta Emborrachada em telhas de acrílico e ou tipo fibra ?',
        text: 'Em telhas de fibra sim, desde que faça um prévio lixamento, antes da aplicação.Em telhas de acrílico efetuar teste antes do uso, não recomendamos, pois a aderência vai depender da composição da telha.',
        id: 11
    },

    {
        title: 'Posso aplicar zarcão para eliminar a nó de pinhos ?',
        text: 'Não pode ser usado.O ideal é utilizar um produto específico para esta função.',
        id: 12
    },

    {
        title: 'Posso usar o Esmalte Metálico em madeira ?',
        text: 'Não, a madeira pode trabalhar e ocorrer trincas na aplicação.',
        id: 13
    },
    {
        title: 'Posso usar Seladora 8030 para eliminar nó de pinhos ?',
        text: 'Não, não tem poder de isolamento.O ideal é utilizar um produto específico para esta função.',
        id: 14
    },

    {
        title: 'Posso usar Verniz PU Marítimo no assoalho ?',
        text: 'Em relação a aplicabilidade sim.Porém, o mesmo pode descascar / desgastar muito rápido, pois não apresenta resistência ao tráfego de pessoas.',
        id: 15
    },

    {
        title: 'Se as peças estiverem envernizadas o que devo fazer para pintá - las com a cor desejada ?',
        text: 'O correto é retirar o Verniz com equipamento mecânico ou raspadeiras manuais.Se não retirar e só lixar e pintar, pode ocorrer desplacamento.',
        id: 16
    },



]

export const rewardsRequestOptions = [
    {
        value: 'New',
        label: 'Em espera',
    },
    {
        value: 'Rejected',
        label: 'Rejeitado',
    },
    {
        value: 'Accepted',
        label: 'Aceitar',
    },
];