import './header.css';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

import colors from "../../utils/colors";

import tintsImg from '../../images/tints.png'
import MenuOpt from "./MenuOpt";
import { useAppSelector } from '../../hooks/redux-hooks';

import BoschBtn from "../../components/Button/Button";
import SimpleModal from '../SimpleModal/SimpleModal';



const HomeHeader = ({havePermission} : {havePermission : boolean}) => {
    const navigate = useNavigate();
    const [showModalPermission, setShowModalPermission] = useState(false);
    const { userProfile } = useAppSelector((state) => state.auth);

    const checkPermission = () => {
        if (havePermission) {
            navigate('/home-rewards');
        } else {
            setShowModalPermission(true);
        }
    }

    return (
        <div className="homeSection">
            <MenuOpt  leftCloseSideBar='130px' />
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <div style={{ display: 'flex', flex: 0.4, alignSelf: 'center' }}>
                    <img src={tintsImg} alt='tints' style={{ width: 137, height: 132 }} />
                </div>

                <div className="borderSection">
                    <span style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '8px' }}>Programa de recompensas</span>
                    <br />
                    <span style={{ color: colors.infoTextGrey, fontWeight: 'bold', fontSize: 13 }}>
                        Acesse o nosso catálogo de premios e descubra um mundo 
                        de possibilidades. Oferecemos premios de todos os tipos!
                    </span>
                    <br />
                    
                    <div style={{ width: "180px" }}>
                        <BoschBtn 
                            color={colors.pinkRed} 
                            click={() => checkPermission()} 
                            title="Confira já!" 
                        />
                    </div>
                    <br />
                </div>
            </div>
            <SimpleModal isOpen={showModalPermission} onClose={() => setShowModalPermission(false)} >
                <h2>Sem acesso!</h2>
                <span style={{ color: colors.infoTextGrey, fontWeight: 'bold', fontSize: 14 }} >
                    O perfil {userProfile ? userProfile : 'com que você realizou login'} não tem 
                    acesso a essa funcionalidade. Caso queira explorar essa ferramenta da plataforma 
                    tente mudar de perfil ou mercado!
                </span>
            </SimpleModal>
        </div>
    );
}

export default HomeHeader;



