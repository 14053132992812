import './search.css'
import searchIcon from '../../images/search.png';

const Search = ({ value, setValue, border = false } : {value : string, setValue : any, border? : boolean}) => {
    return (
        <div className='search'>
            <input 
                value = {value} 
                onChange = {
                    (e) => {
                        setValue(e.target.value);
                    }
                } 
                type="text" 
                className='searchInput' 
                style={{ border: border ? '1px solid #000' : '1px solid #FFF' }}
            />
            <img src={searchIcon} alt="search" className='searchIcon' />
        </div>
    );
}

export default Search;