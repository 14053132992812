import { useState } from 'react';
import DateFunction from '../../utils/DateFunctions';

import PrimaryButton from "../PrimaryButton/PrimaryButton";
import './banner.css';

const Banner = (
    {
        id,
        title, 
        description, 
        onChange, 
        validity, 
        imagem 
    } : {
        id : number,
        title : string, 
        description : string, 
        onChange : Function, 
        validity : string, 
        imagem : string 
    }) => {
        const [disabled, setDisabled] = useState<boolean>(false);

    return(
        <div className="bannerActivePromotion">
            <div className='bannerContainer' >
                <h3>{title}</h3>
                <p>
                    {description}
                </p>
                <div className="bannerContent">
                    <div>
                        <PrimaryButton
                            onClick={() => {
                                onChange(id);
                                setDisabled(true);
                            }}
                            padding="0px 0px"
                            label="Pontuar"
                            disabled={disabled}
                        />
                    </div>
                    <label>Válido até: {DateFunction.formatSimpleDate(validity)}</label>
                </div>
            </div>
            <img
                // src={imagem}
                src={`data:image/jpeg;base64,${imagem}`} 
                alt="Imagem da promoção"
            />
        </div>
    );
}

export default Banner;

