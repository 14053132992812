import './login.css'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LockOpen, MailOutline } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { login } from "../../slices/authSlice";
import { showNotification, NotificationType } from "../../slices/notificationSlice";
import { cleanUserMarket, clearUserAll } from '../../slices/userSlice';
import { saveQueryId, getTinUrl } from '../../slices/tinSlice';
import { saveUserMarket } from '../../slices/userSlice';

import logo_img from '../../images/logo.png'

import BoschInput from '../../components/Input/BoschInput';
import BoschBtn from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import SimpleModal from '../../components/SimpleModal/SimpleModal';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isOpenModal, setOpenModal] = useState(false);

  const { basicUserInfo, status, error, userProfile } = useAppSelector((state) => state.auth);
  const { getResponse, tinQueryId } = useAppSelector((state) => state.tin);


  useEffect(() => {
    if (error && status === 'failed') {
      dispatch(showNotification({
        message: error.message,
        type: error.notificationType as NotificationType,
      }))

      if (error.message.indexOf('Usuário bloqueado!') != -1) {
        setOpenModal(true);
      }
    }
  }, [error]);

  useEffect(() => {
    dispatch(clearUserAll(true));
    dispatch(cleanUserMarket());
  }, []);

  useEffect(() => {
    let currentPath = window.location.href;
    if (currentPath.toUpperCase().indexOf("?ID=") !== -1) {
      let id = currentPath.toUpperCase().split('?ID=')[1];
      const resultId = id.replace(/\D/g, '');
      if (resultId) {
        dispatch(saveQueryId({ id: parseInt(resultId) }));
      }

    }
  }, []);


  useEffect(() => {
    if (status === 'ready') {
      if (basicUserInfo && userProfile) {
        if (tinQueryId) {
          dispatch(getTinUrl(tinQueryId)).then(()=> {
            dispatch(saveUserMarket({ market : getResponse.market })).then(() => {
              if (userProfile == "Administrador" || userProfile == "Gestor") {
                navigate('/admin/home');
              } else if (userProfile == "Promotor") {
                navigate('/promoter/home');
              } else {
                navigate('/');
              }
            });
          });
        } else {
          if (userProfile == "Administrador" || userProfile == "Gestor") {
            navigate('/admin/home');
          } else if (userProfile == "Promotor") {
            navigate('/promoter/home');
          } else {
            navigate('/');
          }
        }
      }
    }
  }, [basicUserInfo, status, userProfile]);


  const handleLogin = async () => {
    if (email && password) {
      dispatch(login({ email, password }));
    } else {
      dispatch(
        showNotification({
          message: "Preencha os campos email e senha.",
          type: NotificationType.Warning,
        })
      );
    }
  };

  return (

    <div className='mainLogin'>

      <div>
        <img src={logo_img} alt='logo' />
      </div>

      <div className='loginContent'>
        <br />
        <span className="titleText">Login</span>
        <br />
        <span className="infoText">faça seu login</span>

        <BoschInput label='Email' value={email} setValue={setEmail} icon={<MailOutline/>} />
        
        <BoschInput type='password' label='Senha' value={password} setValue={setPassword} icon={<LockOpen/>} />

        <div className='spanRow'>
          <span 
            onClick={() => { 
              dispatch(clearUserAll(true)); 
              navigate('/register') 
            }}
          >
            Cadastrar
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        <BoschBtn title='Login' click={handleLogin} />
        <Loading isOpen={status === 'loading'} />
      </div>

      <SimpleModal isOpen={isOpenModal} onClose={() => { setOpenModal(!isOpenModal) }} >
        <div className='bloqUserModal' >
          <h2>Usuário bloqueado</h2>
          <label>O usuário com o e-mail: {email}, está bloqueado! Para mais informações entre em contato com o administrador da plataforma através do nosso SAC.</label>
          <BoschBtn
            title='SAC'
            click={() => window.open('https://www.cmp.ind.br/sac-app/', '_blank', 'rel=noopener noreferrer')}
          />
        </div>
      </SimpleModal>

    </div>
  );
};

export default Login;
