import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate } from "react-router-dom";
import './qrscan.css';
 
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { NotificationType, showNotification } from '../../slices/notificationSlice';
import { cleanPoints, pontuateQr } from '../../slices/pontuationSlice';
import Loading from '../Loading/Loading';
import { getUser } from '../../slices/userSlice';
import { saveQueryId } from '../../slices/tinSlice';
import colors from '../../utils/colors';

const Qrscan = () => {
	const dispatch = useAppDispatch();
	const [result, setResult] = useState('');
	const [divKey, setDivKey] = useState('a1p');
	const navigate = useNavigate();
	const [isShown, setShowQr] = useState(true);
	const [zoomCustom, setZoomCustom] = useState(2.0);
	const { errorPoints, pontuated, error, status } = useAppSelector((state) => state.pontuation);
	const handleError = (err) => {
		console.err(err)
	}

	useEffect(() => {
		setDivKey(zoomCustom + 'a2cm')
	}, [zoomCustom]);


	const previewStyle = {
		height: 320,
		width: 320,
	}


	async function handleResult(result) {
		if (result && result.text) {
			if (result.text.indexOf("?ID=") !== -1) {
				let id = result.text.split('?ID=')[1];
				setResult(id);
				dispatch(pontuateQr({ id: id }));
				dispatch(saveQueryId({ id : id }));
			}
		}
	}

	useEffect(() => {

		if (errorPoints) {
			dispatch(showNotification({
				message: error.message,
				type: error.notificationType,
			}))
			dispatch(cleanPoints(true));
			dispatch(getUser(true));
			navigate('/home-rewards');	
			
		} else if(pontuated) {
			dispatch(showNotification({
				message: 'Promoção pontuada com sucesso!',
				type: NotificationType.Success,
			}))
			dispatch(cleanPoints(true));
			dispatch(getUser(true));
			navigate('/home-rewards');	
		}
	}, [pontuated, errorPoints]);

	return (

		<div
            style={{
                overflow: 'hidden',
                backgroundColor: colors.grey,
                width: '100%',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
			{
				!result &&
				<>
					<div
						style={{
							position: 'absolute',
							top: 0,
							height: '40px',
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							backgroundColor: colors.red,
							justifyContent: 'space-between',
						}}
						onClick={() => { setShowQr(false); navigate('/home-rewards') }}
					>
						< ArrowBackIcon boxSize={24} style={{ marginLeft: '15px', color: '#FFF', cursor: "pointer" }} />

						<h1 style={{ fontSize: '18px', fontWeight: "bold", color: '#FFF', marginRight: '15px' }} >
							Escanear QR code
						</h1>
					</div>
					<div style={{ width: '300px', height: '300px' }}>
						<QrReader
							constraints={{
								facingMode: 'environment', zoom: zoomCustom,
								focusMode: "continuous"
							}}
							delay={500}
							style={previewStyle}
							onError={handleError}
							onResult={handleResult}
						/>
					</div>
					<span style={{ color: '#000', fontSize: '14px', textAlign: 'center' }} >
						Centralize o QRCode da lata na câmera para explorar as ofertas!
					</span>
				</>
			}

			<Loading isOpen={status === "loading"} />
		</div>
	);
}
 
export default Qrscan;

