import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { CmpAxios } from "../api/CmpAxios";
import { buildUrl } from "../api/services";
import { MessageError, Error } from "../utils/ErrorResponse";
import { ErrorResponse } from "../utils/types";
import { NotificationType } from "./notificationSlice";


export type User = {
  id: number,
  name: string,
  email: string,
  password: string,
  contact: string,
  cpf: string,
  city: string,
  profile: string,
  points: number,
  active: boolean
}

type UserApiState = {
  listResponse: User[],
  getResponse: User,
  status: "idle" | "loading" | "failed" | "ready",
  error: Error | null,
  userCreated: boolean | null,
  userData: any,
  userUpdated: boolean | null,
  userDeleted: boolean | null,
  userMarket: string | null,
}

const initialState: UserApiState = {
  listResponse: [],
  getResponse: {} as User,
  status: "idle",
  error: null,
  userCreated: null,
  userData: null,
  userUpdated: null,
  userDeleted: null,
  userMarket: null
}

export const saveUserMarket = createAsyncThunk(
  "user/saveUserMarket",
  async (data: any) => {
    return data.market;
  }
)

export const cleanUserMarket = createAsyncThunk(
  "user/cleanUserMarket",
  async () => {
    return true;
  }
)

export const listUser = createAsyncThunk(
  "user/list",
  async (_, { rejectWithValue }) => {
    try {
      let baseRequest = await buildUrl('listUser');
      let token = localStorage.getItem('token');
      return CmpAxios(
        baseRequest.route,
        baseRequest.method,
        null,
        token
      );

      //const response = { data: mockUsersList };
      //const response  = await axiosInstance.get('/');
      //return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const listRemovedUser = createAsyncThunk(
  "user/removed/list",
  async (_, { rejectWithValue }) => {
    try {
      let baseRequest = await buildUrl('listRemovedUser');
      let token = localStorage.getItem('token');
      return CmpAxios(
        baseRequest.route,
        baseRequest.method,
        null,
        token
      );
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const getUser = createAsyncThunk(
  "user/get",
  async (data: any, { rejectWithValue }) => {
    try {

      let user = JSON.parse(localStorage.getItem("userInfo") as string);
      let id = user.id;
      let params = [{ param: 'USER_ID', value: id }]
      let baseRequest = await buildUrl('getUser', params)
      let token = localStorage.getItem("token");
      return CmpAxios(baseRequest.route, baseRequest.method, null, token)

    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      let baseRequest = await buildUrl('deleteUser');
      let token = localStorage.getItem('token');
      return CmpAxios(
        `${baseRequest.route}${id}`,
        baseRequest.method,
        null,
        token
      );

      //const response = { data: mockUsersList.find(user => user.id === id) ?? {} as User };
      //const response = await axiosInstance.delete(`delete/${id}`);
      //return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const removeUser = createAsyncThunk(
  "user/remove",
  async (id: number, { rejectWithValue }) => {
    try {
      let baseRequest = await buildUrl('removeUser');
      let token = localStorage.getItem('token');
      return CmpAxios(
        `${baseRequest.route}${id}`,
        baseRequest.method,
        null,
        token
      );
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const restoreUser = createAsyncThunk(
  "user/restore",
  async (id: number, { rejectWithValue }) => {
    try {
      let baseRequest = await buildUrl('restoreUser');
      let token = localStorage.getItem('token');
      return CmpAxios(
        `${baseRequest.route}${id}`,
        baseRequest.method,
        null,
        token
      );
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const createUser = createAsyncThunk(
  "user/create",
  async (data: Partial<User>, { rejectWithValue }) => {
    let baseRequest = await buildUrl('createUser')
    let token = localStorage.getItem("token");
    const request = await CmpAxios(
      baseRequest.route,
      baseRequest.method,
      data,
      token
    );

    if (!request.error) {
      return request.response;
    } else {
      return rejectWithValue(request.response);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (data: any, { rejectWithValue }) => {
    try {
      let baseRequest = await buildUrl('updateUser')
      let token = localStorage.getItem("token");
      return CmpAxios(baseRequest.route, baseRequest.method, data, token)
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const clearUserState = createAsyncThunk(
  "user/clearUserState",
  async (data: any, { rejectWithValue }) => {
    return true;
  }
);

export const clearUserAll = createAsyncThunk(
  "user/clearUserAll",
  async (data: any, { rejectWithValue }) => {
    return true;
  }
);



const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(listUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.listResponse = action.payload;
      })
      .addCase(listUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            MessageError(({ message: action.payload } as ErrorResponse).message) ||
            {
              response: "",
              message: "Falha ao tentar listar os usuários!",
              notificationType: NotificationType.Error
            } as Error;
        }
      })

      .addCase(listRemovedUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(listRemovedUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.listResponse = action.payload;
      })
      .addCase(listRemovedUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            MessageError(({ message: action.payload } as ErrorResponse).message) ||
            {
              response: "",
              message: "Falha ao tentar listar os usuários bloqueados!",
              notificationType: NotificationType.Error
            } as Error;
        }
      })

      .addCase(getUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.userData = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = "ready";
        state.userDeleted = true;
        state.getResponse = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            MessageError(({ message: action.payload } as ErrorResponse).message) ||
            {
              response: "",
              message: "Falha ao tentar deletar o usuário!",
              notificationType: NotificationType.Error
            } as Error;
        }
      })

      .addCase(removeUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.status = "ready";
        state.userDeleted = true;
        state.getResponse = action.payload;
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            MessageError(({ message: action.payload } as ErrorResponse).message) ||
            {
              response: "",
              message: "Falha ao tentar bloquear o usuário!",
              notificationType: NotificationType.Error
            } as Error;
        }
      })

      .addCase(restoreUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(restoreUser.fulfilled, (state, action) => {
        state.status = "ready";
        state.userUpdated = true;
        state.getResponse = action.payload;
      })
      .addCase(restoreUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            MessageError(({ message: action.payload } as ErrorResponse).message) ||
            {
              response: "",
              message: "Falha ao tentar ativar o usuário!",
              notificationType: NotificationType.Error
            } as Error;
        }
      })

      .addCase(createUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = "ready";
        state.userCreated = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "failed";

        state.error =
          MessageError(({ message: action.payload } as ErrorResponse).message) ||
          {
            response: "",
            message: "Falha ao tentar criar o usuário!",
            notificationType: NotificationType.Error
          } as Error;
      })


      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "ready";
        state.userUpdated = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error =
            MessageError(({ message: action.payload } as ErrorResponse).message) ||
            {
              response: "",
              message: "Falha ao tentar atualizar as informações do usuário!",
              notificationType: NotificationType.Error
            } as Error;
        }
      })
      .addCase(clearUserState.fulfilled, (state, action) => {
        state.status = "idle";
        state.userCreated = null;
        state.userUpdated = null;
        state.error = null;
      })
      .addCase(clearUserAll.fulfilled, (state, action) => {
        state.listResponse = [];
        state.status = "idle";
        state.userCreated = null;
        state.userData = null;
        state.userUpdated = null;

      })

      .addCase(saveUserMarket.fulfilled, (state, action) => {
        state.userMarket = action.payload;
      })
      .addCase(cleanUserMarket.fulfilled, (state) => {
        state.userMarket = null;
      })
  }
});

export default userSlice.reducer;

