import './tutorialForm.css';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router';
import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { Tutorial, createTutorial, updateTutorial } from '../../../slices/tutorialSlice';
import { MARKET_LIST, HOUSE_PROFILES, AUTOMOTIVE_PROFILES } from '../../../utils/profiles';
// import { marketList } from './market.const';
// import { profileAutomotive, profileRealEstate } from './profiles.const';

import logo from '../../../images/logo_whitetext.png';

import TextArea from '../../../components/TextArea/TextArea';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import Loading from '../../../components/Loading/Loading';
import CustomSelect from '../../../components/CustomSelect/customSelect';

const TutorialForm = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useAppDispatch();

    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [market, setMarket] = useState('');
    const [profile, setProfile] = useState('');
    
    const [disabled, setDisable] = useState(true);

    const { listResponse, status } = useAppSelector((state) => state.tutorial);

    useEffect(() => {
        if (state && state.id) {
            
            let tutorialResponse : Tutorial | undefined = listResponse.find((tutorial) => {
                return tutorial.id === state.id;
            });

            if (tutorialResponse != undefined) {
                const {
                    title,
                    url,
                    description,
                    market,
                    profile,
                } = tutorialResponse;

                setTitle(title);
                setDescription(description);
                setUrl(url);
                setMarket(market);
                setProfile(profile);
            }
        }
    }, []);

    useEffect(() => {
        setDisable(
            Boolean(!(
                title && 
                url && 
                description &&
                market &&
                profile
            ))
        );
    }, [title, url, description, profile, market]);

    const handleClick = () => {
        if (title && url && description && profile && market) {
            if (state && state.id) {
                let id : number = state.id;
                dispatch(
                    updateTutorial({
                        id,
                        title,
                        url,
                        description,
                        market,
                        profile
                    })
                );
            } else {
                dispatch(
                    createTutorial({
                        title,
                        url,
                        description,
                        market,
                        profile
                    })
                );
            }
        }
    };

    useEffect(() => {
        if (status === 'ready') {
            navigate('/admin/tutorial-list');
        }
    }, [status, updateTutorial, createTutorial]);

    return (
        <div className='tutorialFormMain'>
            <div className='headerTutorialForm'>
                <button onClick={() => navigate('/admin/tutorial-list')} className='arrowback' >
                    <ArrowBack/>
                </button>
                <img className='logoImg' alt='logo' src={logo} />
            </div>

            <div className='tutorialFormTitle'>
                <h1>Cadastro de </h1>
            </div>

            <form action="">
                <h2>url</h2>
                <div className='tutorialFormTextArea'> 
                    <TextArea
                        value={url}
                        setValue={setUrl}
                        placeholder='Digite aqui o url do vídeo que deseja adicionar.'
                    />
                </div>
                <h2>Título</h2>
                <div className='tutorialFormTextArea'>
                    <TextArea
                        value={title}
                        setValue={setTitle}
                        placeholder='Digite aqui o título do vídeo que deseja adicionar.'
                    />
                </div>
                <h2>Descrição</h2>
                <div className='tutorialFormTextArea'>
                    <TextArea
                        value={description}
                        setValue={setDescription}
                        placeholder='Digite aqui a descrição do vídeo que deseja adicionar.'
                    />
                </div>
                <div className='tutorialFormInput' >
                    <h2>Mercado</h2>
                    <CustomSelect
                        label=''
                        listArray={MARKET_LIST}
                        setValue={setMarket}
                        value={market}
                        backgroundColor='#FFF'
                    />
                </div>
                <div className='tutorialFormInput' >
                    <h2>Perfil</h2>
                    <CustomSelect
                        label=''
                        setValue={setProfile}
                        value={profile}
                        backgroundColor='#FFF'
                        listArray={
                            market === MARKET_LIST[0].customValue 
                            ? HOUSE_PROFILES 
                            : market === MARKET_LIST[1].customValue
                            ? AUTOMOTIVE_PROFILES
                            : [] 
                        }
                    />
                </div>
                
                {
                    !market ? (
                        <label className='profileWarning' >
                            Selecione primeiro um mercado para selecionar um perfil
                        </label>
                    ) : null
                }
                
                <PrimaryButton
                    label='Confirmar'
                    onClick={handleClick}
                    padding='50px 0'
                    disabled={disabled}
                />
                
            </form>

            <Loading isOpen={ status === 'loading' } />

        </div>
    );
}

export default TutorialForm;