import { LockOpen, MailOutline } from '@mui/icons-material';

import { formatInput } from "../../utils/input-mask";

function BoschInput({
  value,
  type = "text",
  label,
  placeholder = " ",
  setValue,
  isBrl = false,
  isDate = false,
  isDoc = false,
  isPhone = false,
  isCep = false,
  icon = null,
}: {
  value: string;
  type?: string;
  label: string;
  placeholder?: string;
  setValue: any;
  isBrl?: boolean;
  isDate?: boolean;
  isDoc?: boolean;
  isPhone?: boolean;
  isCep?: boolean;
  icon?: any;
}) {
  return (
    <div 
      style={{ 
        flex: 1, 
        display: "flex", 
        flexDirection: "column" 
      }}
    >
      <label
        style={{
          color: "black",
          marginTop: 23,
          fontSize: 17,
          marginBottom: 15,
          fontWeight: "bold",
        }}
      >
        {label}
      </label>

      <div style={{ display: 'flex', backgroundColor: '#F5F5F5', borderRadius: '5px' }} >
        {
          icon ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '65px', color: '#878F9C' }} >
              {icon}
            </div>
          ) : null
        }
        <input
          type={type}
          title={label}
          style={{
            backgroundColor: "#F5F5F5",
            height: 55,
            width: '100%',
            color: "black",
            display: "flex",
            borderRadius: "5px",
            border: "0px",
            paddingInline: "8px"
          }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(formatInput(e, { isBrl, isDate, isDoc, isPhone, isCep }));
          }}
        />
      </div>
    </div>
  );
}

export default BoschInput;
