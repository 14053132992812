import { useState, useEffect } from "react";
import { ArrowBack, PriorityHigh, KeyboardReturn } from "@mui/icons-material";
import { useNavigate } from 'react-router';

import Header from "../../../components/Header/Header";
import Search from "../../../components/Search/Search";
import SelectStatus from "../../../components/SelectStatus/SelectStatus";
import Loading from "../../../components/Loading/Loading";

import { rewardsRequestOptions } from "../../../utils/constants";
import { getRewardOpen, getRewardAccepted, getRewardRejected, updateRewardAccept, updateRewardReject, Reward } from '../../../slices/rewardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import './RewardList.css';

const RewardList = () => {
    const navigate = useNavigate();
    const dispatch =   useAppDispatch();
    const { listResponse, status, rewardUpdate, rewardCreated } = useAppSelector((state) => state.reward);

    const [search, setSearch] = useState('');
    const [showList, setShowList] = useState<boolean>(false);
    const [showRejectList, setShowRejectList] = useState<boolean>(false);
    const [rewards, setRewards] = useState<Reward[]>([]);

    useEffect(() => {
        if(!showList) {
            dispatch(getRewardOpen());
        } else {
            dispatch(getRewardAccepted());
        }
    }, [dispatch, showList]);

    useEffect(() => {
        setRewards(listResponse);
    }, [listResponse]);

    useEffect(() => {
        if(status === "ready") {
            dispatch(getRewardOpen());
            setShowList(false);
            setShowRejectList(false);
        }
    }, [status, rewardUpdate, rewardCreated]);

    useEffect(() => {
        if(!search) {
            setRewards(listResponse);
        } else {
            let result = listResponse.filter((item) => (
                    item.userName.toLowerCase().indexOf(search.toLowerCase()) != -1 ||
                    item.points.toString().indexOf(search.toLowerCase()) != -1 ||
                    item.status.toLowerCase().indexOf(search.toLowerCase()) != -1
                )
            );
            setRewards(result);
        }
    }, [search]);

    const changeStatus = async (id : number, status : string) => {
        if(status === 'Rejected') {
            await dispatch(updateRewardReject(id));
        } else if(status === 'Accepted') {
            await dispatch(updateRewardAccept(id));
        }
        setShowRejectList(false);
        setShowList(false);
        await dispatch(getRewardOpen());
    }

    const showRejectRewards = async () => {
        setShowRejectList(true);
        await dispatch(getRewardRejected());
    }

    const showOpenRewards = async () => {
        setShowRejectList(false);
        setShowList(false);
        await dispatch(getRewardOpen());
    }

    return (
        <div className="rewardListMain" >
            <Header />
            <div className='rewardListTitle'>
                <ArrowBack onClick={() => navigate('/admin/home')} style={{ cursor: 'pointer' }} />
                <h1>Pedidos</h1>
            </div>

            <Search 
                value={search}
                setValue={setSearch}
            />
            
            <div style={{ paddingBottom: "45px" }} >
                {
                    showRejectList ? (
                        <div className="rewardListSelect">
                            <button 
                                style={{ borderBottom: '3px solid #D02030', background: '#FFEDEF' }} 
                                onClick={() => {}}
                            >
                                Pedidos rejeitados
                            </button>
                        </div>
                    ) : (
                        <div className="rewardListSelect">
                            <button 
                                style={!showList ? { borderBottom: '3px solid #D02030', background: '#FFEDEF' } : {}} 
                                onClick={() => {setShowList(false)}}
                            >
                                Em espera
                            </button>
                            <button 
                                style={showList ? { borderBottom: '3px solid #D02030', background: '#FFEDEF' } : {}} 
                                onClick={() => {setShowList(true)}}
                            >
                                Aceitos
                            </button>
                        </div>
                    )
                }

                {
                    rewards.length > 0 && rewards.map((reward, index) => (
                        <div className="rewardCardList" key={index}>
                            <label><strong>Usuário:</strong> {reward.userName}</label>
                            <label><strong>Pontuação:</strong> {reward.points}</label>
                            <div className="rewardCardSelect" >
                                <SelectStatus
                                    options={rewardsRequestOptions}
                                    value={reward.status}
                                    onChangeValue={changeStatus}
                                    reward={reward}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>

            {
                showRejectList ? (
                    <button onClick={() => showOpenRewards()} style={{ backgroundColor: '#20d064' }} className="rewardFloatButton" >
                        <KeyboardReturn/>
                    </button>
                ) : (
                    <button onClick={() => showRejectRewards()} className="rewardFloatButton" >
                        <PriorityHigh/>
                    </button>
                )
            }

            <Loading isOpen={status === 'loading'} />
        </div>
    );
}

export default RewardList;