import { configureStore } from "@reduxjs/toolkit";
import { axiosMiddleware } from "./api/middleware";

import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import notificationReducer from "./slices/notificationSlice";
import promotionReducer from "./slices/promotionSlice";
import tutorialReducer from './slices/tutorialSlice';
import rewardSlice from "./slices/rewardSlice";
import meetSlice from "./slices/meetSlice";
import tinSlice from "./slices/tinSlice";
import punctuationSlice from './slices/punctuationSlice';
import pontuationSlice from "./slices/pontuationSlice";
import opSlice from "./slices/opSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    notification: notificationReducer,
    promotion: promotionReducer,
    tutorial: tutorialReducer,
    reward: rewardSlice,
    meet: meetSlice,
    tin: tinSlice,
    punctuation: punctuationSlice,
    pontuation: pontuationSlice,
    op: opSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(axiosMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
