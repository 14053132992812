import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../utils/types';
import { buildUrl } from '../api/services';
import { CmpAxios } from '../api/CmpAxios';
import axiosInstance from '../api/axiosInstance';
import { MessageError, Error } from '../utils/ErrorResponse';
import { NotificationType } from './notificationSlice';

export type Tin = {
    id: number,
    url: string,
    image: any,
    information: string,
    market: string,
    status: string,
    registrationTimestamp: string,
    url_id: number,
    imageUrl: string,
    op: string
}

export type TinBase64 = {
    id: number,
    url: string,
    information: string,
    market: string,
    status: string,
    registrationTimestamp: string,
    url_id: number,
    imageBase64: string,
    op: string
}

type TinApiState = {
    listResponse: Tin[],
    getResponse: Tin,
    getResponseBase64: TinBase64,
    status: 'idle' | 'loading' | 'failed' | 'ready' | 'finish',
    error: Error | null,
    tinCreated: boolean | null,
    tinUpdate: boolean | null,
    tinDelete: boolean | null,
    tinImageUpdate: boolean | null,
    tinQueryId: number | null,
    canImage: any
}

const initialState: TinApiState = {
    listResponse: [],
    getResponse: {} as Tin,
    getResponseBase64: {} as TinBase64,
    status: 'idle',
    error: null,
    tinCreated: null,
    tinUpdate: null,
    tinDelete: null,
    tinQueryId: null,
    tinImageUpdate: null,
    canImage: null
}

export const saveQueryId = createAsyncThunk(
    "tin/saveQueryId",
    async (data: any) => {
        return data.id;
    }
);

export const cleanQueryId = createAsyncThunk(
    "tin/cleanQueryId",
    async (data: any) => {
        return true;
    }
);



export const createTin = createAsyncThunk(
    "tin/create",
    async (data: Partial<Tin>, { rejectWithValue }) => {
        let body = new FormData();
        body.append('image', data.image);
        body.append('information', data.information ? data.information : '');
        body.append('status', data.status ? data.status : '');
        body.append('market', data.market ? data.market : '');
        body.append('url', data.url ? data.url : '');
        body.append('op', data.op ? data.op : '');

        let baseRequest = await buildUrl('createCan');
        let token = localStorage.getItem('token');
        const request = await CmpAxios(
            baseRequest.route,
            baseRequest.method,
            body,
            token
        );

        if (!request.error) {
            return request.response;
        } else {
            return rejectWithValue(request.response);
        }
    }
);

export const getTinQr = createAsyncThunk(
    "tin/getTinQr",
    async (id: number, { rejectWithValue }) => {
        try {
            let params = [{ param: 'URL_ID', value: id }]
            let baseRequest = await buildUrl('getTinQr', params)
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getTinBase64 = createAsyncThunk(
    "tin/getTinBase64",
    async (id: number, { rejectWithValue }) => {
        try {
            // let params = [{ param: 'URL_ID', value: id }]
            let baseRequest = await buildUrl('getTinBase64')
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);


export const listTin = createAsyncThunk(
    "tin/list",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('listCan');
            let token = localStorage.getItem("token");
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getTin = createAsyncThunk(
    "tin/get",
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getCan');
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getTinUrl = createAsyncThunk(
    "tinUrl/get",
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getCanUrl');
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const deleteTin = createAsyncThunk(
    "tin/delete",
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('deleteCan');
            let token = localStorage.getItem('token');
            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const updateTin = createAsyncThunk(
    "tin/update",
    async (data: Partial<Tin>, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('updateCan');
            let token = localStorage.getItem('token');

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                data,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const updateTinImage = createAsyncThunk(
    "tin/update/image",
    async (data: Partial<Tin>, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('updateCanImage');
            let token = localStorage.getItem('token');
            let body = {
                id: data.id,
                image: data.image
            }

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                body,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

const tinSlice = createSlice({
    name: "tin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createTin.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createTin.fulfilled, (state, action) => {
                state.status = "ready";
                state.tinCreated = true;
            })
            .addCase(createTin.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = 
                    MessageError(({ message : action.payload } as ErrorResponse).message) || 
                    { 
                        response: "", 
                        message: "Falha ao tentar criar a lata!", 
                        notificationType: NotificationType.Error 
                    } as Error;
                }
            })

            .addCase(updateTin.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(updateTin.fulfilled, (state, action) => {
                state.status = "ready";
                state.tinUpdate = true;
            })
            .addCase(updateTin.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = 
                    MessageError(({ message : action.payload } as ErrorResponse).message) || 
                    { 
                        response: "", 
                        message: "Falha ao tentar atualizar a lata!", 
                        notificationType: NotificationType.Error 
                    } as Error;
                }
            })

            .addCase(updateTinImage.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(updateTinImage.fulfilled, (state) => {
                state.status = "ready";
                state.tinImageUpdate = true;
            })
            .addCase(updateTinImage.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = 
                    MessageError(({ message : action.payload } as ErrorResponse).message) || 
                    { 
                        response: "", 
                        message: "Falha ao tentar atualizar a imagem da lata!", 
                        notificationType: NotificationType.Error 
                    } as Error;
                }
            })

            .addCase(listTin.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listTin.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(listTin.rejected, (state, action) => {
                state.status = "failed";
                state.error = 
                MessageError(({ message : action.payload } as ErrorResponse).message) || 
                { 
                    response: "", 
                    message: "Falha ao tentar listar as latas!", 
                    notificationType: NotificationType.Error 
                } as Error;
            })

            .addCase(getTin.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getTin.fulfilled, (state, action) => {
                state.status = "idle";
                state.getResponse = action.payload;
            })
            .addCase(getTin.rejected, (state, action) => {
                state.status = "failed";
                state.error = 
                MessageError(({ message : action.payload } as ErrorResponse).message) || 
                { 
                    response: "", 
                    message: "Falha ao tentar buscar as informações da lata!", 
                    notificationType: NotificationType.Error 
                } as Error;
            })

            
            
            .addCase(getTinUrl.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getTinUrl.fulfilled, (state, action) => {
                state.status = "idle";
                state.getResponse = action.payload;
            })
            .addCase(getTinUrl.rejected, (state, action) => {
                state.status = "failed";
                state.error = 
                    MessageError(({ message : action.payload } as ErrorResponse).message) || 
                    { 
                        response: "", 
                        message: "Falha ao tentar buscar as informações da lata!", 
                        notificationType: NotificationType.Error 
                    } as Error;
            })



            .addCase(getTinBase64.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getTinBase64.fulfilled, (state, action) => {
                state.status = "finish";
                state.getResponseBase64 = action.payload;
            })
            .addCase(getTinBase64.rejected, (state, action) => {
                state.status = "failed";
                state.error = 
                    MessageError(({ message : action.payload } as ErrorResponse).message) || 
                    { 
                        response: "", 
                        message: "Falha ao tentar buscar as informações da lata!", 
                        notificationType: NotificationType.Error 
                    } as Error;
            })



            .addCase(deleteTin.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(deleteTin.fulfilled, (state, action) => {
                state.status = "ready";
                state.tinDelete = true;
                state.getResponse = action.payload;
            })
            .addCase(deleteTin.rejected, (state, action) => {
                state.status = "failed";
                state.error = 
                    MessageError(({ message : action.payload } as ErrorResponse).message) || 
                    { 
                        response: "", 
                        message: "Falha ao tentar deletar a lata!", 
                        notificationType: NotificationType.Error 
                    } as Error;
            })
            
            .addCase(saveQueryId.fulfilled, (state, action) => {
                state.tinQueryId = action.payload
            })
            
            .addCase(cleanQueryId.fulfilled, (state, action) => {
                state.tinQueryId = null;
                state.canImage = null;
            })

            .addCase(getTinQr.fulfilled, (state, action) => {

                state.canImage = action.payload;
            })
    }
});

export default tinSlice.reducer;
