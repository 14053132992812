import currency from "currency.js";

function formatNumberToBRL(value) {

    value = value.toString()
    if (!value) {
        value = ''
    }
    if (value === '') {
        return 'R$ 0,00';
    } else {
        let valueNum = value.replace(/[^0-9]/g, '');
        let userInputAsNumber = parseInt(valueNum, 10) / 100;
        let formattedNumber = `R$ ${userInputAsNumber.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.")}`;
        return formattedNumber;
    }
}


const formatAmount = (value) => {
    let valueFinal2 = value.replace(/\$/g, "");
    let valueFinal3 = valueFinal2.replace(/\R/g, "");
    let valueFinal4 = valueFinal3.replace(/\ /g, "");
    let valueFinal5 = currency(valueFinal4, {
        decimal: ",",
    }).toString();
    return valueFinal5
}

const cnpjMask = (value) => {

}

export function moneyFormat(valueM) {
    if (!valueM) {
        return ""
    }
    let floatM = valueM.toString()
    let finalMonthV = null

    if (floatM.indexOf('.') !== -1) {
        let fVv = floatM.split('.')[1]
        if (fVv.length > 2) {
            let first2 = fVv.slice(0, 2)
            finalMonthV = floatM.split('.')[0] + ',' + first2
        } else if (fVv.length === 1) {
            finalMonthV = floatM.split('.')[0] + ',' + fVv + '0'
        } else {
            finalMonthV = floatM.split('.')[0] + ',' + fVv
        }
    } else {
        finalMonthV = floatM + ',00'
    }

    const maskedMoneyMonth = formatAmount(finalMonthV);
    return formatNumberToBRL(maskedMoneyMonth)


}


function cpfMask(value) {

    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};


function cpfCnpjMask(value) {


    if (value.length > 14) {
        return value
            .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
            .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
    } else {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

};

function maskPhone(v) {

    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
        r = r.replace(/^(\d*)/, "($1");
    }
    return r;

}

const zipCodeMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{5})(\d)/, '$1-$2')

    if (value.length > 9) {
        return value.slice(0, 9)
    }
    return value
}

const NumberFunctions = { cpfMask: cpfMask, moneyFormat: moneyFormat, formatNumberToBRL: formatNumberToBRL, formatAmount: formatAmount, cnpjMask: cnpjMask, cpfCnpjMask: cpfCnpjMask, maskPhone: maskPhone, cepMask: zipCodeMask }

export default NumberFunctions;