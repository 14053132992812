import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse } from "../utils/types";
import { buildUrl } from "../api/services";
import { CmpAxios } from "../api/CmpAxios";
import { MessageError } from "../utils/ErrorResponse";

export type Punctuation = {
    id: number,
    canUrlId: number,
    promotionId: number,
    userId: number,
}

type PunctuationApiState = {
    listResponse: Punctuation[],
    getResponse: Punctuation,
    status: "idle" | "loading" | "failed" | "ready";
    error: string | null;
    punctuationCreated: boolean | null;
}

const initialState : PunctuationApiState = {
    listResponse: [],
    getResponse: {} as Punctuation,
    status: "idle",
    error: null,
    punctuationCreated: null
}

export const createPunctuationBanner = createAsyncThunk(
    "puctuation/create/banner",
    async (data : Partial<Punctuation>, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('pontuateBanner');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                data,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
)

export const createPunctuationQrcode = createAsyncThunk(
    "puctuation/create/qrcode",
    async (data : Partial<Punctuation>, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('pontuateQr');
            let token = localStorage.getItem('token');
            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                data,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
)

const punctuationSlice = createSlice({
    name: "puctuation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createPunctuationBanner.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createPunctuationBanner.fulfilled, (state, action) => {
                state.status = "ready";
                state.punctuationCreated = true;
                state.getResponse = action.payload;
            })
            .addCase(createPunctuationBanner.rejected, (state, action) => {
                state.status = "failed";
                state.error = MessageError(({ message: action.payload } as ErrorResponse).message) || "Falha ao pontuar a promoção!"
            })

            .addCase(createPunctuationQrcode.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createPunctuationQrcode.fulfilled, (state, action) => {
                state.status = "ready";
                state.punctuationCreated = true;
                state.getResponse = action.payload;
            })
            .addCase(createPunctuationQrcode.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = (action.payload as ErrorResponse).message || "Create punctuation QRCode data failed";
                }
            })
    }
});

export default punctuationSlice.reducer;