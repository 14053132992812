import './steper.css'
import colors from "../../utils/colors";

const Steper = ({ step }: { step: number }) => {

    const activeBorder = '2px solid #D02030'


    return (



        <div>

            <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                    <div style={{ border: activeBorder, display: 'flex', width: 30, height: 30, backgroundColor: colors.red, borderRadius: '50%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 23 }}>1</span>
                    </div>

                    <div style={{ width: 14, height: 6, backgroundColor: colors.red, marginRight: 7, marginLeft: 7 }} />

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                    <div style={{ border: activeBorder, display: 'flex', width: 30, height: 30, backgroundColor: step >= 2 ? colors.red : 'white', borderRadius: '50%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <span style={{ color: step >= 2 ? 'white' : colors.red, fontWeight: 'bold', fontSize: 23 }}>2</span>
                    </div>

                    <div style={{ width: 14, height: 6, backgroundColor: colors.red, marginRight: 7, marginLeft: 7 }} />

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                    <div style={{ border: activeBorder, display: 'flex', width: 30, height: 30, backgroundColor: step >= 3 ? colors.red : 'white', borderRadius: '50%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <span style={{ color: step >= 3 ? 'white' : colors.red, fontWeight: 'bold', fontSize: 23 }}>3</span>
                    </div>
                </div>

            </div>

        </div >



    );
};

export default Steper;
