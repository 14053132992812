import axiosInstance from "./axiosInstance";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export async function CmpAxios(url, method, body, token) {

    let config = {
        headers: {
            Authorization: "Bearer " + token,
        }
    }

    let configMultipart = {
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type" : "multipart/form-data"
        }
    }

    if (method == 'MULTIPART_POST') {
        try {
            const response = await axiosInstance.post(url, body, configMultipart);
            return { error: false, response: response };
        } catch (error) {
            const errorResponse = error.response.data;
            return { error: true, response: errorResponse };
        }
    }

    if (method == 'MULTIPART_PUT') {
        return axiosInstance.put(url, body, configMultipart);
    }

    if (method == "GET") {
        return (await axiosInstance.get(url, config)).data
    }

    if (method == "BODY_GET") {
        return (await axiosInstance.get(url, body, config)).data
    }

    if (method == "POST") {
        try {
            const response = await axiosInstance.post(url, body, config);
            return { error: false, response: response };
        } catch (error) {
            const errorResponse = error.response.data;
            return { error: true, response: errorResponse };
        }
    }
    if (method == "PUT") {
        return axiosInstance.put(url, body, config);
    }
    if (method == "DELETE") {
        return axiosInstance.delete(url, config);
    }

}