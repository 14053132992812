import polygonImg from '../../images/poly.png'
import calendarImg from '../../images/calendar_black.png'
import clockImg from '../../images/clock_black.png'
import colors from '../../utils/colors';
import MeetBtn from '../Button/MeetBtn';

import './meet.css'

const PromoterMeet = ({ meetAction, day, time, status, id, selected, select }: { meetAction: any, day: string, time: string, status: string, id: string, selected: string, select: any }) => {


    function getColor() {
        var result = status === 'PENDING' ? colors.yellow : status === 'ACTIVE' ? colors.green : colors.red;
        return result;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', justifyItems: 'center', alignItems: 'center', height: "100px", borderRadius: "6px", backgroundColor: 'white', margin: "10px 5px"}}>

            <div style={{ borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px", width: 13, backgroundColor: getColor(), height: "100%" }} />
            
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', position: "relative" }}>
                    
                <div style={{ position: "absolute", top: "10px", left: "10px" , display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', justifyItems: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <img src={calendarImg} style={{ width: 13, height: 13 }} alt='day' />
                        <span style={{ color: 'black', fontWeight: 'bold', fontSize: 15, marginLeft: 7 }}>{day}</span>

                    </div>
                    <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <img src={clockImg} style={{ width: 13, height: 13 }} alt='time' />
                        <span style={{ color: 'black', fontWeight: 'bold', fontSize: 15, marginLeft: 7 }}>{time}</span>
                    </div>
                </div>

                <div style={{ justifyContent: 'flex-start', alignContent: 'flex-start', margin: "auto 0px 5px 10px", position: 'relative' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <MeetBtn color={getColor()} title={status == 'PENDING' ? 'Pendente' : status == 'ACTIVE' ? 'ACEITA' : 'RECUSADO'} click={() => {
                            if (id == selected) {
                                select('')
                            } else {
                                select(id)
                            }
                        }} />
                    </div>

                    {
                        selected == id && (
                            <>
                                <div style={{ zIndex: 99999999, position: 'absolute', right: -99, backgroundColor: '#EFEFEF', height: "auto", padding: "5px 0px", width: 133, bottom: -30, borderRadius: 5 }}>

                                    <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '100%', flexDirection: 'column', height: '100%' }}>

                                        {
                                            status !== 'REFUSED' && (
                                                <MeetBtn 
                                                    isMenu={true} 
                                                    color={colors.red} 
                                                    title='Recusar' 
                                                    click={() => {
                                                        meetAction({ action: 'REFUSE', id: id })
                                                    }} 
                                                />
                                            )
                                        }

                                        {
                                            status !== 'ACTIVE' && (
                                                <MeetBtn 
                                                    isMenu={true} 
                                                    color={colors.green} 
                                                    title='Aceitar' 
                                                    click={() => {
                                                        meetAction({ action: 'ACCEPT', id: id })
                                                    }} 
                                                />
                                            )
                                        }

                                        {
                                            status !== 'PENDING' && (
                                                <MeetBtn 
                                                    isMenu={true} 
                                                    color={colors.yellow} 
                                                    title='Pendenciar' 
                                                    click={() => {
                                                        meetAction({ action: 'RELOAD', id: id })
                                                    }} 
                                                />
                                            )
                                        }

                                        {
                                            status === 'ACTIVE' && (
                                                <MeetBtn 
                                                    isMenu={true} 
                                                    color={colors.darkBlue} 
                                                    title='Finalizar' 
                                                    click={() => {
                                                        meetAction({ action: 'FINALIZE', id: id })
                                                    }} 
                                                />
                                            )
                                        }

                                    </div>

                                    <img src={polygonImg} alt='poly' style={{ float: 'left', marginLeft: -14, marginTop: -60 }} />
                                </div>
                            </>
                        )
                    }
                </div>

                <div style={{ position: "absolute", right: "5px", bottom: "5px" }}>
                    {
                        status == 'ACTIVE' ? (
                            <div style={{ alignSelf: 'center' }}>
                                <MeetBtn 
                                    isMenu={true} 
                                    color={colors.green} 
                                    title='Ingressar' 
                                    click={() => {
                                        window.open('https://cmp-video-chat-react-9292-dev.twil.io', '_blank', 'rel=noopener noreferrer');
                                    }} 
                                />
                            </div>
                        ) : (
                            <div style={{ alignSelf: 'center', color: 'black', fontWeight: 'bold', fontSize: 15, marginLeft: 13 }}>
                                Chamada de Vídeo
                            </div>
                        )
                    }
                </div>

            </div>
        </div>

    );
};

export default PromoterMeet;
