import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosInstance";
import { AxiosError } from "axios";
import { ErrorResponse } from "../utils/types";
import { MessageError, Error } from "../utils/ErrorResponse";
import { NotificationType } from "./notificationSlice";

type User = {
  email: string;
  password: string;
};

type NewUser = User & {
  name: string;
};

type UserBasicInfo = {
  id: number;
  name: string;
  email: string;
  admin: boolean;
  profile: string;
};

type UserProfileData = {
  name: string;
  email: string;
};

type AuthApiState = {
  basicUserInfo?: UserBasicInfo | null;
  userProfileData?: UserProfileData | null;
  status: "idle" | "loading" | "failed" | 'ready';
  error: Error | null;
  token: string | null;
  userID: string | null;
  userProfile: string | null;
};

const initialState: AuthApiState = {
  basicUserInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
  userProfileData: undefined,
  status: "idle",
  error: null,
  token: null,
  userProfile: localStorage.getItem("userInfo") 
    ? JSON.parse(localStorage.getItem("userInfo") as string ).profile 
    : null,
  userID: null,
};


export const login = createAsyncThunk(
  "login",
  async (data: User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/login', data);

      if (response) {
        localStorage.setItem("userInfo", JSON.stringify(response));
        let fullResponse = { email: data.email, data: response.data }
        return fullResponse;
      } else {
        return rejectWithValue({ message: 'Falha ao autenticar.' });
      }

    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);



export const register = createAsyncThunk(
  "register",
  async (data: NewUser, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/register", data);
      const resData = response.data;

      localStorage.setItem("userInfo", JSON.stringify(resData));

      return resData;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (_, { rejectWithValue }) => {
    try {


      localStorage.removeItem("userInfo");

      return true;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getUser = createAsyncThunk(
  "users/profile",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/users/${userId}`);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);



const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        login.fulfilled,
        (state, action: any) => {
          state.status = "ready";
          //state.basicUserInfo = {id: 1, name:'admin', email:'admin@gmail', admin:true} as UserBasicInfo;
          state.basicUserInfo = action.payload;
          // console.log("action.payload ", action.payload.data.tokenJWT);
          localStorage.setItem("token", action.payload.data.tokenJWT);
          state.token = action.payload.data.tokenJWT;
          state.userProfile = action.payload.data.userProfile;
          state.userID = action.payload.data.userID;
          let user = { 
            email: action.payload.email, 
            name: '', 
            token: action.payload.data.tokenJWT, 
            profile: action.payload.data.userProfile, 
            id: action.payload.data.userID 
          }
          localStorage.setItem("userInfo", JSON.stringify(user));

          state.userProfileData = user;
        }
      )
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = 
          MessageError(({ message : action.payload } as ErrorResponse).message) || 
          { 
              response: "", 
              message: "Falha ao tentar realizar login!", 
              notificationType: NotificationType.Error 
          } as Error;
      })

      .addCase(register.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        register.fulfilled,
        (state, action: PayloadAction<UserBasicInfo>) => {
          state.status = "idle";
          state.basicUserInfo = action.payload;
        }
      )
      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = 
          MessageError(({ message : action.payload } as ErrorResponse).message) || 
          { 
              response: "", 
              message: "Falha ao tentar realizar o registro!", 
              notificationType: NotificationType.Error 
          } as Error;
        }
      })

      .addCase(logout.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = "idle";
        state.basicUserInfo = null;
        localStorage.setItem("token", '');
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = 
          MessageError(({ message : action.payload } as ErrorResponse).message) || 
          { 
              response: "", 
              message: "Falha ao tentar sair!", 
              notificationType: NotificationType.Error 
          } as Error;
        }
      })

      .addCase(getUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.userProfileData = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = "failed";
        if (action.payload) {
          state.error = 
          MessageError(({ message : action.payload } as ErrorResponse).message) || 
          { 
              response: "", 
              message: "Falha ao tentar pegar as informações de usuário!", 
              notificationType: NotificationType.Error 
          } as Error;
        }
      });
  },
});

export default authSlice.reducer;
