import './simple.css'
import { useNavigate } from "react-router";
import { ArrowBack } from "@mui/icons-material";

const SimpleRedHeader = ({ title }: { title: string }) => {
    const navigate = useNavigate();


    return (

        <div className="menu">
            <ArrowBack 
                style={{ color: 'white', marginLeft: '15px', cursor: "pointer" }} 
                onClick={() => { navigate('/') }}
            />
            <span className="bigTitle">{title}</span>
        </div>


    );
};

export default SimpleRedHeader;
