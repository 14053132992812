import { NotificationType } from "../slices/notificationSlice";

export type Error = {
    response : string,
    message : string,
    notificationType : string
}

const errorList : Error[] = [
    {
        response : 'User is not active',
        message : 'Usuário bloqueado!',
        notificationType : NotificationType.Warning,
    },
    {
        response : 'User already exist!',
        message : 'Já existe um usuário com o email informado!',
        notificationType : NotificationType.Warning,
    },
    {
        response : 'Someone else already score this can',
        message : 'Você já foi beneficiado com essa promoção!',
        notificationType : NotificationType.Warning,
    },
    {
        response : 'Error Can already exists!',
        message : 'Já existe uma lata com a URL informada!',
        notificationType : NotificationType.Warning,
    },
    {
        response : 'Promotion already exist!',
        message : 'Já existe uma promoção com o nome informado!',
        notificationType : NotificationType.Warning,
    },
    {
        response : 'is not applicable for this promotion',
        message : 'A lata selecionada não é aplicável a promoção!',
        notificationType : NotificationType.Warning,
    },
    {
        response : 'Someone else already scored this can',
        message : 'Alguém já pontuou com essa lata!',
        notificationType : NotificationType.Warning,
    },
]

export function MessageError(response : string) {
    let errorResponse : Error | null = null;

    errorList.map((error) => {
        if (error.response.indexOf(response) !== -1) {
            errorResponse = error;
        }
    });
    
    return errorResponse;
}