import {
    Button
} from "@mui/material";
import colors from "../../utils/colors";

const BoschBtn = ({ title, click, color = colors.red, disabled = false }:
    { title: string, click: any, color?: string | any, disabled?: boolean | any }) => {

    return (

        <div style={{ width: '100%' }}>
            <Button
                disabled={disabled}
                style={{
                    width: '100%',
                    marginTop: 5, marginBottom: 5,
                    backgroundColor: color,
                    maxHeight: 42
                }}
                onClick={disabled ? null : click} 
            >
                <span style={{ color: 'white', fontSize: '12px', textTransform: 'none', fontWeight: 'bold' }}> {title} </span> 
            </Button>
        </div>
    );
}

export default BoschBtn;
