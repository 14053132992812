import { useState } from 'react';
import { Menu } from '@mui/icons-material';
import './header.css';

import SideBar from '../Sidebar/SideBar';
import logo from '../../images/logo_whitetext.png';

const Header = ({isMenu = true} : {isMenu?: boolean}) => {
  const [isSideBar, setSideBar] = useState(false);

  return (
    <div className='menu'>
      <div className='menuContainer' style={!isMenu ? { justifyContent: 'end' } : {}} >
        {
          isMenu ? (
            <Menu onClick={() => setSideBar(!isSideBar)} />
          ) : null
        }
        <img className='logoImg' alt='logo' src={logo} />
      </div>
      {
        isSideBar && <SideBar active={setSideBar} />
      }
    </div>
  );
}

export default Header;
