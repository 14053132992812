import React from "react";
import { Select } from "@chakra-ui/react";

import './selectStatus.css';
import { Reward } from "../../slices/rewardSlice";

type Option = {
    value: string;
    label: string;
}

const SelectStatus = ({options, value, onChangeValue, reward} : {options: Option[], value: string, onChangeValue: any, reward: Reward}) => {
    return (
        <Select 
            className="selectStatusMain" 
            value={value}
            onChange={(e) => onChangeValue(reward.rewardId, e.target.value)}
        >
            {
                reward.status === "New" ? (
                    options.length > 0 && options.map((item, index) => (
                        <option 
                            value={item.value}
                            className="selectStatusOption"
                            key={index}
                        >
                            {item.label}
                        </option>
                    ))
                ) : (
                    reward.status === "Accepted" ? (
                        <option value="Accepted" className="selectStatusOption" >
                            Aceito
                        </option>
                    ) : (
                        options.length > 0 && options.map((item, index) => (
                            item.value != "New" && (
                                <option 
                                    value={item.value}
                                    className="selectStatusOption"
                                    key={index}
                                >
                                    {item.label}
                                </option>
                            )
                        ))
                    )
                )
            }
        </Select>
    )
}

export default SelectStatus;