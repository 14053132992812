import './userForm.css';
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import bcrypt from "bcryptjs-react";
import { Checkbox, Dialog } from "@mui/material";

import Header from '../../components/Header/Header';
import SimpleInput from '../../components/SimpleInput/SimpleInput';
import CustomSelect from '../../components/CustomSelect/customSelect';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Loading from '../../components/Loading/Loading';

import { ALL_CLIENT_PROFILE, ALL_SPECIAL_PROFILE } from '../../utils/profiles';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { clearUserState, createUser, getUser, updateUser } from '../../slices/userSlice';
import { NotificationType, showNotification } from '../../slices/notificationSlice';
import { policie } from './politica';
import { terms } from './termos';


const UserForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { getResponse, error, userCreated, status, userData, userUpdated } = useAppSelector((state) => state.user);
    const { basicUserInfo } = useAppSelector((state) => state.auth);

    const { height, width } = useWindowDimensions();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [cpf, setCpf] = useState('');
    const [city, setCity] = useState('');
    const [profile, setProfile] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [points, setPoints] = useState(0);
    const [numPages, setNumPages] = useState();
    const [checkedPolice, setCheckedPolice] = useState(false);
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const policeRef = useRef(null)
    const termsRef = useRef(null)

    const [disable, setDisable] = useState(false);
    const [openPolice, setOpenPolice] = useState(false);

    useEffect(() => {
        if (checkedTerms) {
            termsRef.current?.scrollIntoView({ behavior: "smooth" })
        }

    }, [checkedTerms]);


    useEffect(() => {
        if (checkedPolice) {
            policeRef.current?.scrollIntoView({ behavior: "smooth" })
        }

    }, [checkedPolice]);


    useEffect(() => {
        dispatch(getUser(true));
    }, []);




    useEffect(() => {
        if (userData) {
            const {
                name,
                //password,
                email,
                contact,
                cpf,
                city,
                profile,
                points
            } = userData;

            setName(name);
            setEmail(email);
            setTelephone(contact);
            setCpf(cpf);
            setCity(city);
            setProfile(profile);
            setPoints(points);

        }
    }, [userData]);

    useEffect(() => {
        if (userData) {
            setDisable(
                Boolean(!(
                    name &&
                    email &&
                    telephone &&
                    cpf &&
                    city &&
                    profile
                ))
            );

        } else {
            setDisable(
                Boolean(!(
                    name &&
                    email &&
                    telephone &&
                    cpf &&
                    city &&
                    profile &&
                    password
                ))
            );

        }

    }, [name, email, telephone, cpf, city, profile, password]);

    const handleClick = () => {



        if (userData && userData?.id) {
            let id = userData?.id;
            dispatch(
                updateUser({
                    id,
                    name,
                    email,
                    city,
                    cpf,
                    profile,
                    contact: telephone,
                    points: points
                })
            )
        } else {
            if (password === confPassword) {
                setOpenPolice(true)
            } else {
                dispatch(showNotification({
                    message: 'As senhas não condizem!',
                    type: NotificationType.Warning
                }))
            }
        }
    }

    function saveNewUser() {
        var hashPass = bcrypt.hashSync(password, 12);

        setOpenPolice(false);
        setCheckedTerms(false);
        setShowTerms(false);
        setCheckedPolice(false)

        dispatch(
            createUser({
                name,
                email,
                password: hashPass,
                city,
                cpf,
                profile,
                contact: telephone,
            })
        )

    }

    const userRouter = () => {
        if (basicUserInfo?.admin) {
            navigate('/admin/user-list');
        } else {
            navigate('/');
        }
    }


    useEffect(() => {
        if (error && status === 'failed') {
            dispatch(showNotification({
                message: error.message,
                type: error.notificationType,
            }))
            dispatch(clearUserState(true));
        }
    }, [error, status]);



    useEffect(() => {
        if (userCreated) {

            dispatch(showNotification({
                message: 'Usuário criado com sucesso!',
                type: NotificationType.Success,
            }))
            dispatch(clearUserState(true));

            navigate('/login')
        }
    }, [userCreated]);


    useEffect(() => {
        if (userUpdated) {

            dispatch(showNotification({
                message: 'Usuário atualizado com sucesso!',
                type: NotificationType.Success,
            }))
            dispatch(clearUserState(true));
            navigate('/')
        }
    }, [userUpdated]);





    return (
        <div className='userFormMain' >
            <Header isMenu={userData && userData.id} />
            <div className='userFormTitle' >
                <ArrowBack onClick={() => userRouter()} />
                <h1>{userData && userData?.id ? "Edição" : "Cadastro"}</h1>
                <span>Informações do usuário</span>
            </div>
            <div className='userFormContainer' >
                <SimpleInput label='Nome' value={name} setValue={setName} />
                <SimpleInput label='Email' value={email} setValue={setEmail} />

                {
                    !(userData && userData.id) ? (
                        <>
                            <SimpleInput
                                label='Senha'
                                type='password'
                                value={password}
                                setValue={setPassword}
                                isPassword
                            />
                            <SimpleInput
                                label='Confirmar senha'
                                type='password'
                                value={confPassword}
                                setValue={setConfPassword}
                                isPassword
                            />
                        </>
                    ) : null
                }

                <div className='userFormLine' >
                    <SimpleInput label='Telefone' value={telephone} isPhone setValue={setTelephone} />
                    <SimpleInput label='CPF' value={cpf} isCpf setValue={setCpf} />
                </div>
                <SimpleInput label='Cidade' value={city} setValue={setCity} />
                
                {
                    !userData && (
                        <CustomSelect
                            label='Perfil'
                            value={profile}
                            setValue={setProfile}
                            listArray={ALL_CLIENT_PROFILE}
                        />
                    )
                }

                <PrimaryButton
                    label={userData?.id ? 'Salvar' : 'Finalizar'}
                    onClick={handleClick}
                    padding='48px 0'
                    disabled={disable}
                />

            </div>
            <Loading isOpen={status == 'loading'} />


            <Dialog fullWidth maxWidth='lg' onClose={() => { setCheckedTerms(false); setOpenPolice(false); setShowTerms(false); setCheckedPolice(false) }} open={openPolice}>
                <div style={{ margin: 'auto', width: '96%' }}>
                    <div dangerouslySetInnerHTML={{ __html: policie }} />

                    <br /><br />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                            <span>Li e aceito</span>
                            <Checkbox checked={checkedPolice} onChange={() => setCheckedPolice(!checkedPolice)} />
                        </div>
                        {
                            checkedPolice &&
                                <PrimaryButton
                                    label='Aceitar e continuar'
                                    onClick={() => { setShowTerms(true); setOpenPolice(false); }}
                                    padding='48px 0'
                                    disabled={false}
                                />
                        }
                    </div>
                    <div ref={policeRef} />
                </div>
            </Dialog>

            <Dialog fullWidth maxWidth='lg' onClose={() => { setOpenPolice(false); setCheckedTerms(false); setShowTerms(false); setCheckedPolice(false) }} open={showTerms}>
                <div style={{ margin: 'auto', width: '96%' }}>
                    <div dangerouslySetInnerHTML={{ __html: terms }} />

                    <br /><br />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                            <span>Li e aceito</span>
                            <Checkbox checked={checkedTerms} onChange={() => setCheckedTerms(!checkedTerms)} />
                        </div>
                        {
                            checkedTerms &&
                                <PrimaryButton
                                    label='Aceitar termos'
                                    onClick={() => { saveNewUser() }}
                                    padding='48px 0'
                                    disabled={false}
                                />
                        }
                    </div>
                    <div ref={termsRef} />
                </div>
            </Dialog>

        </div>
    );
}

export default UserForm;