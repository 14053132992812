import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./homeRewards.css";

import Banner from '../../components/Banner/Banner';
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import TextArea from '../../components/TextArea/TextArea';
import Carousel from '../../components/Carousel/Carousel';
import Loading from '../../components/Loading/Loading';
import Header from '../../components/Header/Header';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';

import { getTinUrl, saveQueryId } from '../../slices/tinSlice';
import { listCurrentPromotionOpsBase64 } from '../../slices/promotionSlice';
import { listReward, createRewardRequest, Reward } from '../../slices/rewardSlice';
import { NotificationType, showNotification } from '../../slices/notificationSlice';
import { cleanPoints, pontuateBanner } from '../../slices/pontuationSlice';
import { getUser, saveUserMarket } from '../../slices/userSlice';
import { cleanQueryId } from '../../slices/tinSlice';

export const HomeRewards = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [descriptionTin, setDescriptionTin] = useState('');
  // const [tinCachedId, setTinCachedId] = useState(0);
  const { errorPoints, pontuated } = useAppSelector((state) => state.pontuation);
  const { listResponseBase64 } = useAppSelector((state) => state.promotion);

  const { listResponse: rewardResponse, rewardCreated } = useAppSelector((state) => state.reward);
  const { userData, userMarket } = useAppSelector((state) => state.user);
  const { status, error } = useAppSelector((state) => state.pontuation);
  const { tinQueryId, getResponse } = useAppSelector((state) => state.tin);

  useEffect(() => {
    dispatch(listCurrentPromotionOpsBase64());
    dispatch(listReward());
  }, [dispatch]);

  function pontuate(promoId: any) {
    if (!tinQueryId) {
      dispatch(showNotification({
        message: 'Você precisa acessar o app via Qr Code para pontuar aqui.',
        type: NotificationType.Error,
      }))
    } else {
      dispatch(pontuateBanner({
        id: tinQueryId,
        promo: promoId
      }))
    }

  }

  useEffect(() => {
    if (pontuated) {
      dispatch(showNotification({
        message: 'Promoção pontuada com sucesso!',
        type: NotificationType.Success,
      }))
      dispatch(cleanPoints(true));
      //dispatch(cleanQueryId(true))
      dispatch(getUser(true));

    }
  }, [pontuated]);

  useEffect(() => {
    if (errorPoints && error) {
      dispatch(showNotification({
        message: error.message,
        type: error.notificationType as NotificationType,
      }))
      dispatch(cleanPoints(true))
    }
  }, [errorPoints]);

  useEffect(() => {
    let currentPath = window.location.href;
    if (currentPath.toUpperCase().indexOf("?ID=") !== -1) {
      let id = currentPath.toUpperCase().split('?ID=')[1];
      const resultId = id.replace(/\D/g, '');
      if (resultId) {
        dispatch(saveQueryId({ id: parseInt(resultId) }));
      }
    }
    // if (tinQueryId) {
    //   dispatch(getTinUrl(tinQueryId));
    // }
  }, []);

  useEffect(() => {
    if (tinQueryId) {
      dispatch(getTinUrl(tinQueryId));
    }
  }, [tinQueryId]);

  useEffect(() => {
    if (getResponse?.id) {
      setDescriptionTin(getResponse.information);
      dispatch(saveUserMarket({ market: getResponse.market }));
      // if (tinQueryId) {
      //   setTinCachedId(tinQueryId);
      //   // dispatch(cleanQueryId(true))
      // }

    }
  }, [getResponse]);

  const createRequestReward = (reward : Partial<Reward>) => {
    const body = {
      userId : userData?.id,
      points: reward.points
    }

    dispatch(createRewardRequest(body));
    
    dispatch(showNotification({
      message: "Requisição enviada com sucesso!",
      type: NotificationType.Success,
    }));
  }

  return (
    <div
      className="homeRewardsContainer"
    // style={{
    //   maxHeight: height,
    //   minHeight: height,
    // }}
    >

      <Header />
      <div className="headerScore">
        <h1>Programa de recompensas</h1>
        <div className="score">
          <div className="flagCurrentScore">Pontuação atual</div>
          <label>{userData?.points}</label>
          <div className="counter" />
        </div>
      </div>


      <Carousel>
        {
          listResponseBase64 && listResponseBase64.map((promotion, index) => (
            promotion.profile.map((profile) => (
              userData && profile.name === userData.profile && (
                getResponse && getResponse.market != undefined && getResponse.op != undefined ? (
                  tinQueryId && (getResponse.market === promotion.market || userMarket === promotion.market) && promotion.Ops.find(op => op === getResponse.op) && (
                    <div key={index} >
                      <Banner
                        id={promotion.id}
                        title={promotion.name}
                        description={promotion.description}
                        validity={promotion.endDate}
                        imagem={promotion.imageBase64}
                        onChange={pontuate}
                      />
                    </div>
                  )
                ) : (
                  userMarket === promotion.market && (
                    <div key={index} >
                      <Banner
                        id={promotion.id}
                        title={promotion.name}
                        description={promotion.description}
                        validity={promotion.endDate}
                        imagem={promotion.imageBase64}
                        onChange={pontuate}
                      />
                    </div>
                  )
                )
              )
            ))
          ))
        }
      </Carousel>

      <h2>Resgate de prêmios</h2>
      <Carousel>
        {
          rewardResponse && rewardResponse.map((item, index) => (
            <div className='carrouselAward' key={index} >
              <span>{item.points}</span>
              <img src={item.image} alt="reward image" className='image' />
              <button onClick={() => createRequestReward(item)} >
                Resgatar
              </button>
            </div>
          ))
        }
      </Carousel>

      <h2>Informações da lata</h2>
      <div className='canInformations'>
        <TextArea
          placeholder='Informações sobre a lata scaneada!'
          setValue={setDescriptionTin}
          value={descriptionTin}
          disabled={true}
        />
      </div>

      <h2 style={{ color: '#D02030' }} >Escaneie outros QRCodes</h2>
      <PrimaryButton
        onClick={() => navigate('/scan')}
        padding={""}
        label="Escanear"
      />

      <Loading isOpen={status === 'loading'} />
    </div>
  );
};

export default HomeRewards;
