import { Outlet } from "react-router-dom";
import "./layouts.css";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/redux-hooks";


const PromoterLayout = () => {
  const { userProfile } = useAppSelector((state) => state.auth);

  if (userProfile != "Promotor") {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default PromoterLayout;
