import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { buildUrl } from "../api/services";
import { CmpAxios } from "../api/CmpAxios";
import { Error, MessageError } from "../utils/ErrorResponse";
import { ErrorResponse } from "../utils/types";
import { NotificationType } from "./notificationSlice";


type scheduleApiState = {
    listResponse: Schedule[],
    getResponse: string[],
    status: "idle" | "loading" | "failed" | 'ready',
    error: Error | null,   
}


const initialState: any | scheduleApiState = {
    scheduledMeets: undefined,
    meetLoaded: false,
    success: null,
    totalMeets: null,
    meetTime: null,
    availableTimes: null,
    pendingMeets: null,
    promoterPendingList: null,
    meetConfirmed: false,
    promoterMeetsConfirm: null,
    meetRefused: false,
    meetFinalized: false,

    listResponse: [] as Schedule[],
    getResponse: [],
    error: null,
    status: "idle",
};

export type Time = {
    hour: number,
    minute: number,
    nano: number,
    second: number,
}

export type Schedule = {
    acceptedDate: string,
    customerId: number,
    id: number,
    promoterId: number,
    scheduleDate: string,
    status: string,
    time: Time,
}


export const getMeets = createAsyncThunk(
    "getMeets",
    async (data: any, { rejectWithValue }) => {
        try {
            let user = JSON.parse(localStorage.getItem("userInfo") as string);
            let id = user.id;

            let params = [{ param: 'USER_ID', value: id }]
            let baseRequest = await buildUrl('getScheduleds', params)
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);


export const getPendingMeets = createAsyncThunk(
    "getPendingMeets",
    async (data: any, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getPendingMeets')
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);

export const getFinalizedMeets = createAsyncThunk(
    "getFinalizedMeets",
    async (_, { rejectWithValue }) => {
        try {
            let user = JSON.parse(localStorage.getItem('userInfo') as string);
            let id = user.id;
            let params = [{ param: 'PROMOTER_ID', value: id }]

            let baseRequest = await buildUrl('getFinalizedMeets', params)
            let token = localStorage.getItem("token");
            return CmpAxios(
                baseRequest.route, 
                baseRequest.method,
                null, 
                token
            )

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);


export const getPromoterPending = createAsyncThunk(
    "getPromoterPending",
    async (data: any, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getPendingMeets')
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);


export const confirmMeet = createAsyncThunk(
    "confirmMeet",
    async (data: any, { rejectWithValue }) => {
        try {

            let token = localStorage.getItem("token");
            let user = JSON.parse(localStorage.getItem("userInfo") as string);
            let id = user.id;
            let params = [{ param: 'MEET_ID', value: data.id }, { param: 'USER_ID', value: id }]
            let baseRequest = await buildUrl('confirmMeet', params)
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);




export const getPromoterConfirmeds = createAsyncThunk(
    "getPromoterConfirmeds",
    async (data: any, { rejectWithValue }) => {
        try {

            let user = JSON.parse(localStorage.getItem("userInfo") as string);
            let id = user.id;
            let params = [{ param: 'USER_ID', value: id }]
            let baseRequest = await buildUrl('getPromoterConfirmeds', params)
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);


export const schedule = createAsyncThunk(
    "schedule",
    async (data: any, { rejectWithValue }) => {
        try {

            let user = JSON.parse(localStorage.getItem("userInfo") as string);
            let id = user.id;

            let params = [{ param: 'DATE_PARAM', value: data.date },
            { param: 'TIME_ID', value: data.timeId },
            { param: 'USER_ID', value: id }]

            let baseRequest = await buildUrl('schedule', params)
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);




export const getTimes = createAsyncThunk(
    "getTimes",
    async (data: any, { rejectWithValue }) => {
        try {

            let params = [{ param: 'DATE_PARAM', value: data.date }]
            let baseRequest = await buildUrl('getTimes', params)
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);




export const refuseMeet = createAsyncThunk(
    "refuseMeet",
    async (data: any, { rejectWithValue }) => {
        try {
            let params = [{ param: 'MEET_ID', value: data.id }]
            let baseRequest = await buildUrl('refuseMeet', params)
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);

export const finalizeMeet = createAsyncThunk(
    "finalizeMeet",
    async (data: any, { rejectWithValue }) => {
        try {
            let params = [{ param: 'MEET_ID', value: data.id }]
            let baseRequest = await buildUrl('finalizeMeet', params)
            let token = localStorage.getItem("token");
            return CmpAxios(baseRequest.route, baseRequest.method, null, token)

        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);



export const getAllSchedulesFinalized = createAsyncThunk(
    "schedulesAllFinalized/get",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getSchedulesFinalized');
            let token = localStorage.getItem("token");

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);

export const getAllSchedulesConfirmed = createAsyncThunk(
    "getAllSchedulesConfirmed/get",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getAllSchedulesConfirmed');
            let token = localStorage.getItem("token");

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);

export const getAllSchedulesCanceled = createAsyncThunk(
    "getAllSchedulesCanceled/get",
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getAllSchedulesCanceled');
            let token = localStorage.getItem("token");

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);


const meetSlice = createSlice({
    name: "meet",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMeets.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getMeets.fulfilled,
                (state, action: any) => {


                    state.status = "ready";
                    state.scheduledMeets = action.payload
                    state.meetLoaded = true
                }
            )
            .addCase(getMeets.rejected, (state, action) => {

                state.status = "failed";
                state.error = "Falha ao buscar agendamentos.";
            })



            .addCase(getTimes.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                getTimes.fulfilled,
                (state, action: any) => {
                    state.status = "ready";
                    state.availableTimes = action.payload
                    state.meetLoaded = true
                }
            )
            .addCase(getTimes.rejected, (state, action) => {

                state.status = "failed";
                state.error = "Falha ao buscar agendamentos.";
            })


            .addCase(schedule.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(
                schedule.fulfilled,
                (state, action: any) => {
                    state.status = "ready";
                    state.success = true

                }
            )
            .addCase(schedule.rejected, (state, action) => {

                state.status = "failed";
                state.error = "Falha ao tentar agendar.";
            })




            .addCase(
                getPromoterPending.fulfilled,
                (state, action: any) => {
                    let totalList = action.payload;
                    state.promoterPendingList = totalList
                }
            )

            .addCase(
                getPendingMeets.fulfilled,
                (state, action: any) => {

                    let totalList = action.payload;
                    let user = JSON.parse(localStorage.getItem("userInfo") as string);
                    let id = parseInt(user.id);
                    let filtredList = totalList.filter((t: { status: string; customerId: any; }) => t.status == "Waiting for promoter" && t.customerId == id)
                    state.pendingMeets = filtredList
                    state.meetConfirmed = false
                    state.success = null
                }
            )


            
            .addCase(
                confirmMeet.fulfilled,
                (state, action: any) => {
                    state.meetConfirmed = true
                }
            )
            .addCase(
                getPromoterConfirmeds.fulfilled,
                (state, action: any) => {
                    state.promoterMeetsConfirm = action.payload
                }
            )
            .addCase(
                refuseMeet.fulfilled,
                (state, action: any) => {
                    state.meetRefused = true
                }
            )
            .addCase(
                finalizeMeet.fulfilled,
                (state, action: any) => {
                    state.meetFinalized = true
                }
            )



            .addCase(getAllSchedulesFinalized.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getAllSchedulesFinalized.fulfilled, (state, action: any) => {
                    state.status = "ready";
                    state.listResponse = action.payload;
                }
            )
            .addCase(getAllSchedulesFinalized.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar buscar as informações de chamadas!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })



            .addCase(getAllSchedulesConfirmed.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getAllSchedulesConfirmed.fulfilled, (state, action: any) => {
                    state.status = "ready";
                    state.listResponse = action.payload;
                }
            )
            .addCase(getAllSchedulesConfirmed.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar buscar as informações de chamadas!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })



            .addCase(getAllSchedulesCanceled.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getAllSchedulesCanceled.fulfilled, (state, action: any) => {
                    state.status = "ready";
                    state.listResponse = action.payload;
                }
            )
            .addCase(getAllSchedulesCanceled.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar buscar as informações de chamadas!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })



            .addCase(getFinalizedMeets.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getFinalizedMeets.fulfilled, (state, action: any) => {
                    state.status = "ready";
                    state.listResponse = action.payload;
                }
            )
            .addCase(getFinalizedMeets.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = MessageError(({ message: action.payload } as ErrorResponse).message) ||
                        {
                            response: "",
                            message: "Falha ao tentar buscar as informações de chamadas!",
                            notificationType: NotificationType.Error
                        } as Error;
                }
            })

    },
});

export default meetSlice.reducer;

//scheduledMeets data[] vazio = sem agendamento


