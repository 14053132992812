import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './home.css';

import colors from "../../utils/colors";
import tutorialImg from '../../images/tutorial.png'
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { getUser, saveUserMarket } from "../../slices/userSlice";
import { buildPermissions } from "../../utils/Permissions";

import BoschBtn from "../../components/Button/Button";
import Showcases from "../../components/Showcases/Showcases";
import HomeHeader from '../../components/Header/HomeHeader';
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import { Tin, getTin, getTinUrl } from "../../slices/tinSlice";

const Home = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { basicUserInfo, status, error, userProfile } = useAppSelector((state) => state.auth);
  const { tinQueryId, getResponse : tinResponse } = useAppSelector((state) => state.tin);
  const { userMarket } = useAppSelector((state) => state.user);

  const [permissions, setPermissions] = useState<any | null>(null);
  const [showModalPermission, setShowModalPermission] = useState(false);

  useEffect(() => {
    if (basicUserInfo && userProfile) {
      if (userProfile === "Administrador" || userProfile === "Gestor") {
        navigate('/admin/home');
      } else if (userProfile === "Promotor") {
        navigate('/promoter/home');
      } else if(!userMarket && !tinQueryId) {
        navigate('/market-choice');
      }
    } else {
      navigate('/login');
    }
    
  }, [basicUserInfo, status, userProfile]);

  useEffect(() => {
    if (tinQueryId) {
      dispatch(getTinUrl(tinQueryId));
    }
  }, []);

  useEffect(() => {
    if(tinResponse != {} as Tin && !userMarket) {
      dispatch(saveUserMarket({market : tinResponse.market }));
    }
  }, [tinResponse]);

  useEffect(() => {
    if (userMarket && userProfile) {
      setPermissions(buildPermissions(userMarket, userProfile));
    }
  }, [userMarket]);

  const checkPermission = (permission : string, router : string) => {
    if (permissions && permissions.indexOf(permission) != -1) {
      navigate(router);
    } else {
      setShowModalPermission(true);
    }
  }

  return (

    <div className="homeMain" style={{ height: height }}>
      <HomeHeader havePermission={ permissions && permissions.indexOf('reward') != -1 } />
      <Showcases />

      <div className="bottomSection">
        <div style={{ width: 200, height: 1, backgroundColor: 'transparent' }} />
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div style={{ display: 'flex', flex: 0.4, alignSelf: 'center' }}>
            <img 
              src={tutorialImg} 
              alt='tints' 
              style={{ width: 137 }} 
            />
          </div>

          <div className="borderSection">
            <span style={{ fontSize: '20px', fontWeight: 'bold' }} >
              Tutoriais para assistir
            </span>

            <br />

            <span style={{ color: colors.infoTextGrey, fontWeight: 'bold', fontSize: 13 }} >
              Assista vídeos completos sobre como podemos fazer bom uso do produto adquirido!
            </span>
            <br />

            <div style={{ width: "180px" }}>
              <BoschBtn 
                color={colors.pinkRed} 
                click={() => checkPermission('tutorial', '/tutorial')} 
                title="Assista aqui!" 
              />
            </div>
            <br />

          </div>
        </div>
      </div>
      <SimpleModal isOpen={showModalPermission} onClose={() => setShowModalPermission(false)} >
        <h2>Sem acesso!</h2>
        <span style={{ color: colors.infoTextGrey, fontWeight: 'bold', fontSize: 14 }} >
          O perfil {userProfile ? userProfile : 'com que você realizou login'} não tem 
          acesso a essa funcionalidade. Caso queira explorar essa ferramenta da plataforma 
          tente mudar de perfil ou mercado!
        </span>
      </SimpleModal>
    </div>
  );
};

export default Home;
