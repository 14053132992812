import './textArea.css';

const TextArea = (
{ 
    placeholder, 
    value, 
    setValue,
    backgraundColor = '#FFF',
    label = '',
    disabled = false,
    width = '85%'
} : { 
    placeholder : string, 
    value : string, 
    setValue : any,
    backgraundColor? : string,
    label? : string,
    disabled? : boolean,
    width? : string
}) => {
    return(
        <div className='mainTextArea' style={{ width: width }}>
            <label htmlFor="">{label}</label>
            <textarea 
                disabled={disabled}
                style={{ background : backgraundColor }}
                className="textArea" 
                placeholder = {placeholder} 
                value={value}
                onChange={
                    (e) => {
                        setValue(e.target.value);
                    }
                }
            />
        </div>
    );
}

export default TextArea;