import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { logout } from "../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import './menu.css'
import { ArrowBack } from "@mui/icons-material";
import arrowDown from '../../images/arrow_down.png'
import perfilImg from '../../images/perfil.png'
import polygonImg from '../../images/polygon.png'
import colors from "../../utils/colors";


function MenuHeaderRed({
  isSmall = false

}: { isSmall?: any }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.basicUserInfo);

  const handleLogout = async () => {
    dispatch(logout());
    navigate("/login");
  };

  function hideOpt() {
    let doc = document.getElementById('menuOptions')!;
    if (doc) {
      doc.style.display = 'none'
    }
  }

  function showOpt() {
    let doc = document.getElementById('menuOptions')!;
    if (doc) {
      doc.style.display = 'block'
    }
  }




  return (

    <div style={{ flexDirection: 'column', display: 'flex', flex: 1, alignItems: 'center', backgroundColor: colors.red, minHeight: isSmall ? 60 : 99, maxHeight: isSmall ? 60 : 99 }}>


      <div className="menuHome" style={{ marginTop: 7 }}>

        <div onClick={() => { navigate('/') }}>
          <ArrowBack style={{ color: 'white' }} />
        </div>
        <div className="userRow" onClick={showOpt}>
          <img src={perfilImg} alt='perfilImg' style={{ marginRight: 5 }} />
          <span className="menuTitle" style={{ color: 'white' }}>{basicUserInfo?.email}</span>
          <img src={arrowDown} alt='menuArrow' />
        </div>
      </div>

      <div onMouseLeave={hideOpt} className="homeoptions" id='menuOptions'>
        <img src={polygonImg} alt='poly' className="polymage" />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="spanMenu" >Perfil</span>
          <hr style={{ width: 120 }} />
          <span onClick={handleLogout} className="spanMenu">Sair</span>
        </div>
      </div>

      {!isSmall &&
        <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
          <div style={{ fontSize: 19, fontWeight: 'bold', color: 'white' }}>Agendamento&nbsp;</div>
          <div style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>de atendimento</div>
        </div>
      }




    </div>

  );
}

export default MenuHeaderRed;
