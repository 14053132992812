import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ArrowBack, Edit, Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./promotionList.css";

import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import Loading from "../../../components/Loading/Loading";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { listPromotionOpsBase64, deletePromotion } from "../../../slices/promotionSlice";

const PromotionList = () => {
  const { height } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectId, setSelectId] = useState(0);

  const { listResponseBase64, status, promotionDelete } = useAppSelector((state) => state.promotion);

  const openDeleteModal = (id : number) => {
    setOpenDeleteModal(true);
    setSelectId(id);
}

  const deleteClick = async () => {
    if(selectId) {
      await dispatch(deletePromotion(selectId));
    }
  }

  // useEffect(() => {
  //   if (status === 'ready') {
  //     dispatch(listPromotionOpsBase64());
  //   }
  // }, [status, promotionDelete]);

  useEffect(() => {
    dispatch(listPromotionOpsBase64());
  }, [dispatch, promotionDelete]);

  return (
    <div
      className="promotionListContainer"
      // style={{
      //   maxHeight: height,
      //   minHeight: height,
      // }}
    >
      <div className="headerPromotionList">
        <button onClick={() => navigate("/admin/home")} style={{ cursor: 'pointer' }} >
          <ArrowBack />
        </button>
        <h1> de promoções</h1>
      </div>
      {
        listResponseBase64 && listResponseBase64.map((item) => {
          return (
            <div className="promotionListItem" key={item.id}>
              <label>{item.name}</label>

              <div className="promotionListItemOptions" >
                  <Edit onClick={() => navigate("/admin/promotion-form", { state: { id: item.id } })} />
                  <Delete onClick={() => openDeleteModal(item.id)} />
              </div>
            </div>
          );
        })
      }

      <PrimaryButton
        label="Adicionar promoções"
        onClick={() => navigate("/admin/promotion-form")}
        padding={"45px 0"}
        startIcon={<AddCircleOutlineIcon />}
      />

      <DeleteModal
        isOpen={isOpenDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={deleteClick}
        text="Deseja realmente excluir este item?"
      />

      <Loading isOpen={status === 'loading'} />
    </div>
  );
};

export default PromotionList;
