import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import './home.css';

import tutorialImg from '../../../images/tutorial.png';

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import AdminShowcases from "../../../components/Showcases/AdminShowcases";
import BoschBtn from "../../../components/Button/Button";
import MenuHeader from "../../../components/MenuHeader/MenuHeader";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { getUser } from "../../../slices/userSlice";
import colors from "../../../utils/colors";


const AdminHome = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { basicUserInfo, userProfile} = useAppSelector((state) => state.auth);
  const { height, width } = useWindowDimensions();

  // useEffect(() => {
  //   dispatch(getUser(true));
  // }, []);

  return (

    <div className="homeMain" style={{ height: height }}>

      <div className="homeAdminSection">
        <MenuHeader />
      </div>
      <h1>{userProfile}</h1>
      <AdminShowcases />

      <div className="bottomSectionAdmin">

        <div style={{ width: 200, height: 1, backgroundColor: 'transparent' }} />
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div style={{ display: 'flex', flex: 0.4, alignSelf: 'center' }}>
            <img src={tutorialImg} alt='tints' style={{ width: 137 }} />
          </div>

          <div className="borderSection">
            <span style={{ fontSize: 23, fontWeight: 'bold', marginBottom: '10px' }}>
              Tutoriais para assistir
            </span>
            <span style={{ color: colors.infoTextGrey, fontWeight: 'bold', fontSize: '12px', marginBottom: '10px' }}>
              Assista vídeos completos sobre como podemos fazer bom uso do produto adquirido!
            </span>

            <div style={{ width: '130px' }} >
              <BoschBtn color={colors.pinkRed} click={() => navigate("/admin/tutorial-list")} title="Assista aqui!" />
            </div>
          </div>

        </div>
      </div>

    </div>


  );
};

export default AdminHome;
