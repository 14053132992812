import './meet.css'
import calendarImg from '../../images/calendar_black.png'
import clockImg from '../../images/clock_black.png'

const MeetRow = ({ day, time, status }: { day: string, time: string, status: string }) => {

    const activeBorder = '2px solid #28C95F'
    const pendingBorder = '2px solid #E1DA3F'

    return (



        <div>

            <div style={{ marginTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', justifyItems: 'center', alignItems: 'center', border: status == 'PENDING' ? pendingBorder : activeBorder, minHeight: 44 }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <img src={calendarImg} style={{ width: 13, height: 13 }} />
                    <span style={{ color: 'black', fontWeight: 'bold', fontSize: 15, marginLeft: 7 }}>{day}</span>

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <img src={clockImg} style={{ width: 13, height: 13 }} />
                    <span style={{ color: 'black', fontWeight: 'bold', fontSize: 15, marginLeft: 7 }}>{time}</span>
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>

                    <div style={{ display: 'flex', width: 13, height: 13, backgroundColor: status == 'PENDING' ? '#E1DA3F' : '#28C95F', borderRadius: '50%', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    </div>
                    <span style={{ color: 'black', fontWeight: 'bold', fontSize: 12, marginLeft: 7 }}>
                        {status == 'PENDING' ? 'Pendente' : 'Agendado'}
                    </span>
                </div>

            </div>
        </div >



    );
};

export default MeetRow;
