import { Select } from "@chakra-ui/react";
import "./customSelect.css";

type OptionSelect = {
  customValue: any;
  customLabel: string;
};

function CustomSelect({
  value,
  label,
  placeholder = " ",
  setValue,
  listArray,
  backgroundColor = "#EBEBEB",
}: {
  value: string;
  label: string;
  placeholder?: string;
  setValue: any;
  listArray: OptionSelect[];
  backgroundColor?: string;
}) {
  return (
    <div className="selectContainer">
      <label className="labelSelect">{label}</label>
      <Select
        style={{ backgroundColor: backgroundColor }}
        className="selectComponent"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {
          listArray.length > 0 && listArray.map((objectItem, i) => (
            <option
              className="selectOption"
              key={i + "_" + objectItem.customValue}
              value={objectItem.customValue}
            >
              {objectItem.customLabel}
            </option>
          ))
        }
      </Select>
    </div>
  );
}

export default CustomSelect;
