import './tinForm.css';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router';
import { useState } from 'react';

import SimpleInput from '../../../components/SimpleInput/SimpleInput';
import CustomSelect from '../../../components/CustomSelect/customSelect';
import FileSelector from '../../../components/FileSelector/FileSelector';
import Header from '../../../components/Header/Header';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import Loading from '../../../components/Loading/Loading';

import { MARKET_LIST } from '../../../utils/profiles';
import { NotificationType, showNotification } from '../../../slices/notificationSlice';
import axiosInstance from '../../../api/axiosInstance';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux-hooks';


const TinMassForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const { status } = useAppSelector((state) => state.tin);
    const [url, setUrl] = useState('');
    const [quantity, setQuantity] = useState('');
    const [information, setInformation] = useState(undefined);
    const [market, setMarket] = useState('');
    const [image, setImage] = useState('');
    const [op, setOp] = useState('');
    const [loading, setLoading] = useState(false);


    async function download(filename : any, data : any) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + data);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        return true;
    }



    const handleClick = () => {
        if (parseInt(quantity)) {
            setLoading(true)
            let bodyObject = {
                quantity,
                information,
                market,
                image,
                op,
                url,
            };

            let body = new FormData();
            body.append('url', bodyObject.url ? bodyObject.url : '');
            body.append('numberOfCodes ', bodyObject.quantity);
            body.append('information', bodyObject.information ? bodyObject.information : '');
            body.append('status', 'new');
            body.append('market', bodyObject.market ? bodyObject.market : '');
            body.append('op', bodyObject.op ? bodyObject.op : '');
            body.append('image', image ? image : '');

            let urlReq = 'qrcode/generateQRsAndRegister';
            let token = localStorage.getItem('token');
            let configMultipart = {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data",
                }
            }
            return axiosInstance.post(urlReq ? urlReq : '', body, configMultipart).then(async (response) => {
                await download('qrs.zip', response.data.base64Zip);
                setLoading(false);
                navigate('/admin/tin-list');
            });

        } else {
            dispatch(showNotification({
                message: 'Quantidade deve ser numérica!',
                type: NotificationType.Error
            }));
        }

    }

    return (
        <div className='tinFormMain' >
            <Header />
            <div className='tinFormTitle' >
                <ArrowBack onClick={() => navigate('/admin/tin-list')} style={{ cursor: "pointer" }} />
                <h1>{state && state.id ? "Edição " : "Cadastro "}</h1>
            </div>
            <div className='tinFormContent' >

                <SimpleInput 
                    label='URL' 
                    value={url} 
                    setValue={setUrl} 
                />
                <SimpleInput 
                    label='Quantidade' 
                    value={quantity} 
                    setValue={setQuantity} 
                />
                <SimpleInput 
                    label='Informações' 
                    value={information} 
                    setValue={setInformation} 
                />
                <CustomSelect
                    label='Mercado:'
                    value={market}
                    setValue={setMarket}
                    listArray={MARKET_LIST}
                />
                <SimpleInput 
                    label='Código de ordem de produção (OP):' 
                    value={op.toUpperCase()} 
                    setValue={setOp} 
                />

                <FileSelector
                    label='Imagem:'
                    multiple={false}
                    setValue={setImage}
                />

            </div>

            <PrimaryButton
                label='Cadastrar'
                onClick={handleClick}
                disabled={status == 'loading' || loading}
                padding='30px 0'
            />
            <Loading isOpen={status === 'loading' || loading} />
        </div>
    );
}

export default TinMassForm;