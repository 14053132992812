import { ThemeProvider } from "@emotion/react";
import createTheme from "@mui/material/styles/createTheme";
import { DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import "./datePicker.css";

import datePicker from "./datePicker.png";

import SimpleModal from "../SimpleModal/SimpleModal";

const DatePicker = ({
  label,
  value,
  setValue,
}: {
  label: string;
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="calendar">
      <label className="labelDatePicker">{label}</label>
      <div className="datePicker">
        <button onClick={() => setIsVisible(!isVisible)}>
          <img src={datePicker} className="logo" alt={label} />
        </button>
        <label className="datePicker">
          {value?.toLocaleDateString("pt-Br")}
        </label>
      </div>
      <SimpleModal isOpen={isVisible} onClose={() => setIsVisible(false)} >
        <div className="dateCalendar">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={newTheme}>
              <DateCalendar
                value={dayjs(value)}
                onChange={(ev: string | any) => {
                  if (ev) {
                    const formatedValue = dayjs(ev, "DD-MM-YYYY").toDate();
                    setValue(formatedValue);
                    setIsVisible(false);
                  }
                }}
              />
            </ThemeProvider>
          </LocalizationProvider>
        </div>
      </SimpleModal>
    </div>
  );
};

export default DatePicker;

const newTheme = (theme: any) =>
  createTheme({
    ...theme,
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            width: "fit-content",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderWidth: 0,
            height: "fit-content",
            width: "fit-content",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              borderRadius: 0,
              backgroundColor: "#D01235",
            },
            "&.Mui-selected:hover": {
              borderRadius: 0,
              backgroundColor: "#D01235",
            },

            borderRadius: 0,
          },
        },
      },
    },
  });
