import { createHashRouter } from "react-router-dom";

import DefaultLayout from "../layouts/DefaultLayout";
import AdminLayout from "../layouts/AdminLayout";
import PromoterLayout from "../layouts/PromoterLayout";
import ProtectedLayout from "../layouts/ProtectedLayout";

import PromotionForm from "../pages/admin/PromotionForm/PromotionForm";
import PromotionList from "../pages/admin/PromotionList/PromotionList";
import TutorialForm from "../pages/admin/TutorialForm/TutorialForm";
import TutorialList from "../pages/admin/TutorialList/TutorialList";
import AdminHome from "../pages/admin/AdminHome/AdminHome";
import PromotionHome from "../pages/admin/PromotionHome/PromotionHome";
import UserList from "../pages/admin/UserList/UserList";
import TinList from "../pages/admin/TinList/TinList";
import TinForm from "../pages/admin/TinForm/TinForm";
import TinMassForm from "../pages/admin/TinMassForm/TinMassForm";
import RewardList from "../pages/admin/RewardList/RewardList";
import CallList from "../pages/admin/CallList/CallList";

import ScanPage from "../pages/ScanPage/ScanPage";
import Tutorial from "../pages/Tutorial/Tutorial";
import HomeRewards from "../pages/HomeRewards/HomeRewards";
import ScheduleMeet from "../pages/ScheduleMeet/ScheduleMeet";
import UserForm from "../pages/UserForm/UserForm";
import UserFormAdmin from "../pages/UserForm/UserFormAdmin";
import PasswordForm from "../pages/PasswordForm/PasswordForm";
import Login from "../pages/Login/Login";
import Home from "../pages/Home/Home";
import Faq from "../pages/Faq/Faq";
import PasswordReset from "../pages/PasswordReset/PasswordReset";
import MarketChoice from "../pages/MarketChoice/MarketChoice";

import PromoterHome from "../pages/promoter/PromoterHome/PromoterHome";
import ScheduleFinalizedList from "../pages/promoter/ScheduleFinalizedList/ScheduleFinalizedList";


const router = createHashRouter([
  {
    element: <DefaultLayout />,
    children: [
      { path: "/login", element: <Login /> },
      { path: "/register", element: <UserForm /> },
      { path: "/reset-password", element: <PasswordReset /> },
    ],
  },
  {
    path: "",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/home",
        element: <Home />
      },
      {
        path: "/faq",
        element: <Faq />
      },
      {
        path: "/schedule",
        element: <ScheduleMeet />
      },
      {
        path: "/scan",
        element: <ScanPage />
      },
      {
        path: "/tutorial",
        element: <Tutorial />,
      },
      {
        path: "/home-rewards",
        element: <HomeRewards />
      },
      {
        path: "/user-form",
        element: <UserForm />
      },
      {
        path: "/password-form",
        element: <PasswordForm />
      },
      {
        path: "/market-choice",
        element: <MarketChoice />
      },

      {
        path: "/admin",
        element: <AdminLayout />,
        children: [
          {
            path: "/admin/",
            element: <AdminHome />
          },
          {
            path: "/admin/home",
            element: <AdminHome />
          },
          {
            path: "/admin/promotion-home",
            element: <PromotionHome />
          },
          {
            path: "/admin/promotion-form",
            element: <PromotionForm />,
          },
          {
            path: "/admin/promotion-list",
            element: <PromotionList />,
          },
          {
            path: "/admin/tutorial-form",
            element: <TutorialForm />
          },
          {
            path: "/admin/user-list",
            element: <UserList />
          },
          {
            path: "/admin/user-form",
            element: <UserForm />
          },
          {
            path: "/admin/tin-list",
            element: <TinList />
          },
          {
            path: "/admin/tin-form",
            element: <TinForm />
          },
          {
            path: "/admin/user-management",
            element: <UserFormAdmin />
          },
          {
            path: "/admin/tutorial-list",
            element: <TutorialList />
          },
          {
            path: "/admin/tin-mass-form",
            element: <TinMassForm />
          },
          {
            path: "/admin/reward-list",
            element: <RewardList />
          },
          {
            path: "/admin/call-list",
            element: <CallList />
          }
        ]
      },
      {
        path: "/promoter",
        element: <PromoterLayout />,
        children: [
          {
            path: "/promoter/",
            element: <PromoterHome />
          },
          {
            path: "/promoter/home",
            element: <PromoterHome />
          },
          {
            path: "/promoter/schedule-finalized-list",
            element: <ScheduleFinalizedList />
          },
        ]
      }

    ],
  },
]);

export default router;

