
let genericRoutes = [

    {
        route: 'customer_services/schedules-confirmed?userId=USER_ID',
        name: 'getScheduleds',
        method: 'GET'
    },

    {
        route: 'customer_services/available-time-slots?date=DATE_PARAM',
        name: 'getTimes',
        method: 'GET'
    },

    {
        route: 'customer_services/schedules-pending',
        name: 'getPendingMeets',
        method: 'GET'
    },
    {
        route: 'customer_services/schedules-finalized?promoterId=PROMOTER_ID',
        name: 'getFinalizedMeets',
        method: 'GET'
    },

    {
        route: 'customer_services/schedule?timeSlotId=TIME_ID&date=DATE_PARAM&customerId=USER_ID',
        name: 'schedule',
        method: 'POST'
    },

    {
        route: 'customer_services/confirm?customerServiceId=MEET_ID&promoterId=USER_ID',
        name: 'confirmMeet',
        method: 'POST'
    },
    {
        route: 'customer_services/finalize/MEET_ID',
        name: 'finalizeMeet',
        method: 'POST'
    },
    {
        route: 'customer_services/promoter/schedules-confirmed?promoterId=USER_ID',
        name: 'getPromoterConfirmeds',
        method: 'GET'
    },

    {
        route: 'customer_services/MEET_ID',
        name: 'refuseMeet',
        method: 'DELETE'
    },
    {
        route: 'home/register',
        name: 'createUser',
        method: 'POST'
    },
    {
        route: 'home/USER_ID',
        name: 'getUser',
        method: 'GET'
    },

    {
        route: 'home',
        name: 'updateUser',
        method: 'PUT'
    },
    {
        route: 'home',
        name: 'listUser',
        method: 'GET'
    },
    {
        route: 'home/',
        name: 'deleteUser',
        method: 'DELETE'
    },
    {
        route: 'home/remove/',
        name: 'removeUser',
        method: 'PUT'
    },
    {
        route: 'home/restore/',
        name: 'restoreUser',
        method: 'PUT'
    },
    {
        route: 'home/removed',
        name: 'listRemovedUser',
        method: 'GET'
    },

    {
        route: 'can',
        name: 'listCan',
        method: 'GET'
    },
    {
        route: 'can/',
        name: 'getCan',
        method: 'GET'
    },
    {
        route: 'qrcode/generateQR/URL_ID',
        name: 'getTinQr',
        method: 'GET'
    },
    {
        route: 'can/base64/',
        name: 'getTinBase64',
        method: 'GET'
    },
    {
        route: 'can/url/',
        name: 'getCanUrl',
        method: 'GET'
    },
    {
        route: 'can',
        name: 'createCan',
        method: 'MULTIPART_POST'
    },
    {
        route: 'can',
        name: 'updateCan',
        method: 'PUT'
    },
    {
        route: 'can/image',
        name: 'updateCanImage',
        method: 'MULTIPART_PUT'
    },
    {
        route: 'can/',
        name: 'deleteCan',
        method: 'DELETE'
    },
    {
        route: 'tutorial',
        name: 'listTutorial',
        method: 'GET'
    },
    {
        route: 'tutorial/',
        name: 'getTutorial',
        method: 'GET'
    },
    {
        route: 'tutorial',
        name: 'createTutorial',
        method: 'POST'
    },
    {
        route: 'tutorial',
        name: 'updateTutorial',
        method: 'PUT'
    },
    {
        route: 'tutorial/',
        name: 'deleteTutorial',
        method: 'DELETE'
    },


    {
        route: 'promotion/base64/ops',
        name: 'listPromotionOpsBase64',
        method: 'GET'
    },
    {
        route: 'promotion/ops',
        name: 'listPromotionOps',
        method: 'GET'
    },
    {
        route: 'promotion',
        name: 'listPromotion',
        method: 'GET'
    },
    {
        route: 'promotion/active',
        name: 'listCurrentPromotion',
        method: 'GET'
    },
    {
        route: 'promotion/ops/active',
        name: 'listCurrentPromotionOps',
        method: 'GET'
    },
    {
        route: 'promotion/base64/ops/active',
        name: 'listCurrentPromotionOpsBase64',
        method: 'GET'
    },
    {
        route: 'promotion/',
        name: 'getPromotion',
        method: 'GET'
    },
    {
        route: 'promotion',
        name: 'createPromotion',
        method: 'MULTIPART_POST'
    },
    {
        route: 'promotion',
        name: 'updatePromotion',
        method: 'PUT'
    },
    {
        route: 'promotion/image',
        name: 'updatePromotionImage',
        method: 'MULTIPART_PUT'
    },
    {
        route: 'promotion/',
        name: 'deletePromotion',
        method: 'DELETE'
    },
    {
        route: 'punctuation/qrcode',
        name: 'pontuateQr',
        method: 'POST'
    },
    {
        route: 'punctuation/banner',
        name: 'pontuateBanner',
        method: 'POST'
    },
    {
        route: 'qrcode/generateQRsAndRegister',
        name: 'generateMass',
        method: 'MULTIPART_POST'
    },


    {
        route: 'reward/accept/',
        name: 'updateRewardAccept',
        method: 'PUT'
    },
    {
        route: 'reward/accepted',
        name: 'getRewardAccepted',
        method: 'GET'
    },
    {
        route: 'reward/all',
        name: 'getRewardAll',
        method: 'GET'
    },
    {
        route: 'reward/open',
        name: 'getRewardOpen',
        method: 'GET'
    },
    {
        route: 'reward/reject/',
        name: 'updateRewardReject',
        method: 'PUT'
    },
    {
        route: 'reward/rejected',
        name: 'getRewardRejected',
        method: 'GET'
    },
    {
        route: 'reward/request',
        name: 'createRewardRequest',
        method: 'MULTIPART_POST'
    },
    {
        route: 'reward/',
        name: 'deleteReward',
        method: 'DELETE'
    },


    {
        route: 'can/op',
        name: 'getOps',
        method: 'GET'
    },


    {
        route: 'customer_services/all/schedules-finalized',
        name: 'getSchedulesFinalized',
        method: 'GET'
    },
    {
        route: 'customer_services/all/schedules-confirmed',
        name: 'getAllSchedulesConfirmed',
        method: 'GET'
    },
    {
        route: 'customer_services/all/schedules-canceled',
        name: 'getAllSchedulesCanceled',
        method: 'GET'
    },
]



export async function buildUrl(route, params = []) {
    // console.log("params ", params)
    let routePath = { ...[...genericRoutes].find(c => c.name === route) }
    let routeReturn = routePath.route
    if (params.length > 0) {
        for (let i = 0; i < params.length; i++) {
            routeReturn = routeReturn.replace(params[i].param, params[i].value)
        }
    }

    routePath.route = routeReturn

    return routePath
}
