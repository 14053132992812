import './loading.css';

const Loading = ({ isOpen } : { isOpen:boolean }) => {
    return(
        <>
            {
                isOpen ? (
                    <div className='mainLoading'>
                        <div className='spinner'/>
                    </div>
                ) : null
            }
        </>
    );
}

export default Loading;