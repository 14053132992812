import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import router from "./router/router";
import './styles.css'

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import NotificationBar from "./components/Notification/NotificationBar";
import "./fonts/Poppins-Bold.ttf"
import "./fonts/Poppins-Medium.ttf"
import "./fonts/Poppins-Regular.ttf"
import "./fonts/Poppins-Light.ttf"

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <Provider store={store}>
    <NotificationBar />
    <RouterProvider router={router} />
  </Provider>
);



serviceWorkerRegistration.register();
