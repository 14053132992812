import './userList.css';
import { ArrowBack, Info, Add, Block, Create, Check } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../../hooks/redux-hooks';
import { User, listUser, listRemovedUser , removeUser, restoreUser } from '../../../slices/userSlice';

import Header from '../../../components/Header/Header';
import SimpleModal from '../../../components/SimpleModal/SimpleModal';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import Loading from '../../../components/Loading/Loading';
import Search from '../../../components/Search/Search';
import shadows from '@mui/material/styles/shadows';

const UserList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { listResponse, status, userDeleted, userUpdated } = useAppSelector((state) => state.user);
    const { userProfile } = useAppSelector((state) => state.auth);
    const [isOpenInfo, setOpenInfo] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([] as User[]);
    const [showBloqUsers, setShowBloqUsers] = useState(false);

    const [selectUser, setSelectUser] = useState({
        id: 0,
        email: '',
        name: '',
        city: '',
        cpf: '',
        profile: '',
        contact: '',
        points: 0
    } as User);


    const blockAndRestore = async () => {
        setOpenInfo(false);
        if (selectUser.id) {
            if (showBloqUsers) {
                await dispatch(restoreUser(selectUser.id));
            } else {
                await dispatch(removeUser(selectUser.id));
            }
        }
    }

    const OpenModal = (user: User) => {
        setOpenInfo(true);
        setSelectUser({
            id: user.id,
            email: user.email,
            name: user.name,
            city: user.city,
            cpf: user.cpf,
            profile: user.profile,
            contact: user.contact,
            password: user.password,
            points: user.points,
            active: user.active
        });
    }

    useEffect(() => {
        if (showBloqUsers) {
            dispatch(listRemovedUser());
        } else {
            dispatch(listUser());
        }
    }, [dispatch, showBloqUsers]);

    useEffect(() => {
        setUsers(listResponse);
    }, [listResponse]);

    useEffect(() => {
        if (status === 'ready') {
            dispatch(listUser());
            setShowBloqUsers(false);
        }
    }, [status, userDeleted, userUpdated]);

    useEffect(() => {
        if(!search) {
            setUsers(listResponse);
        } else {
            let result = listResponse.filter(
                (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) != -1 || 
                item.profile.toLowerCase().indexOf(search.toLowerCase()) != -1
            );
            setUsers(result);
        }
    }, [search]);

    const activateBlockModal = () => {
        setOpenDeleteModal(!isOpenDeleteModal);
        setOpenInfo(!isOpenInfo);
    }

    return (
        <div className='userListMain' >
            <Header />
            <div className='userListTitle'>
                <ArrowBack onClick={() => navigate('/admin/home')} />
                <h1>Lista</h1>
            </div>

            <Search
                value={search}
                setValue={setSearch}
            />

            <div className='userListList' >
                <div className='userListSelect' >
                    <button 
                        style={!showBloqUsers ? { borderBottom: '3px solid #D02030', background: '#ffedef' } : {}} 
                        onClick={() => {setShowBloqUsers(false)}}
                    >
                        Ativos
                    </button>
                    <button 
                        style={showBloqUsers ? { borderBottom: '3px solid #D02030', background: '#ffedef' } : {}} 
                        onClick={() => {setShowBloqUsers(true)}}
                    >
                        Bloqueados
                    </button>
                </div>
                <div className='userListHeader' >
                    <div className='userListColumn' style={{ borderRight: '1px solid #A8A8A8', width: '40%' }} >
                        <h3>Nome</h3>
                    </div>
                    <div className='userListColumn' style={{ borderRight: '1px solid #A8A8A8', width: '40%' }} >
                        <h3>Perfil</h3>
                    </div>
                    <div className='userListColumn' style={{ width: '20%' }} >
                        <h3>Infos</h3>
                    </div>
                </div>
                {
                    users && users.map((user, index) => (
                        userProfile === "Gestor" && user.profile === "Administrador" ? (
                            null
                        ) : (
                            <div key={index} className='userListLine' >
                                <div className='userListColumn' style={{ borderRight: '1px solid #A8A8A8', width: '40%' }} >
                                    <h5>{user.name}</h5>
                                </div>
                                <div className='userListColumn' style={{ borderRight: '1px solid #A8A8A8', width: '40%' }} >
                                    <h5>{user.profile}</h5>
                                </div>
                                <div className='userListColumn' style={{ width: '20%' }} >
                                    <Info onClick={() => OpenModal(user)} style={{ cursor: "pointer" }}/>
                                </div>
                            </div>
                        )
                    ))
                }
            </div>

            <button onClick={() => navigate('/admin/user-management')} className='userListAddBtn' >
                <Add />
            </button>

            <Loading isOpen={status === 'loading'} />

            <DeleteModal
                onDelete={blockAndRestore}
                isOpen={isOpenDeleteModal}
                onClose={() => setOpenDeleteModal(!isOpenDeleteModal)}
                text={'Deseja realmente desativar esse usuário?'}
            />

            <SimpleModal isOpen={isOpenInfo} onClose={() => setOpenInfo(!isOpenInfo)} >
                <div className='userListInfosModal' >
                    <h2>{selectUser.name}</h2>
                    <label>
                        <strong>E-mail: </strong>
                        {selectUser.email}
                    </label>
                    <label>
                        <strong>Telefone: </strong>
                        {selectUser.contact}
                    </label>
                    <label>
                        <strong>CPF: </strong>
                        {selectUser.cpf}
                    </label>
                    <label>
                        <strong>Cidade: </strong>
                        {selectUser.city}
                    </label>
                    <label>
                        <strong>Perfil: </strong>
                        {selectUser.profile}
                    </label>
                    <label>
                        <strong>Pontuação: </strong>
                        {selectUser.points}
                    </label>
                    <div className='userListModalBtn'>
                        <button onClick={() => navigate('/admin/user-management', { state: { id: selectUser.id } })} >
                            <Create /> 
                            <p>Editar</p>
                        </button>
                        {
                            selectUser.active ? (
                                <button onClick={activateBlockModal} >
                                    <Block /> 
                                    <p>Bloquear</p>
                                </button>
                            ) : (
                                <button style={{ backgroundColor: 'green' }} onClick={blockAndRestore} >
                                    <Check /> 
                                    <p>Desbloquear</p>
                                </button>
                            )
                        }
                    </div>
                </div>
            </SimpleModal>
        </div>
    );
}

export default UserList;