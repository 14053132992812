import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import axiosInstance from '../api/axiosInstance';
import { mockRewards } from '../utils/mocks';
import { ErrorResponse } from '../utils/types';
import { buildUrl } from '../api/services';
import { CmpAxios } from '../api/CmpAxios';
import { MessageError } from '../utils/ErrorResponse';


export type Reward = {
    rewardId: number;
    acceptedDate : string;
    points : number;
    status : string;
    userName : string;
    image : string;
}

type RewardApiState = {
    listResponse: Reward[],
    getResponse: Reward,
    status: 'idle' | 'loading' | 'failed' | 'ready',
    error: string | null,
    rewardCreated : boolean | null,
    rewardUpdate : boolean | null,
    rewardDelete : boolean | null,
}

const initialState : RewardApiState = {
    listResponse: [],
    getResponse : {} as Reward,
    status : 'idle',
    error : null,
    rewardCreated : null,
    rewardUpdate : null,
    rewardDelete : null,
}

export const listReward = createAsyncThunk(
    "reward/list",
    async (_, { rejectWithValue }) => {
        try {
            const response  = { data : mockRewards };
            // const response = await axiosInstance.get('/');
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;

                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getRewardAccepted = createAsyncThunk(
    'tin/getRewardAccepted',
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getRewardAccepted');
            let token = localStorage.getItem('token');

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getRewardAll = createAsyncThunk(
    'tin/getRewardAll',
    async (_, { rejectWithValue }) => {
        try {
            // const response = mockRewards;
            // return response;
            let baseRequest = await buildUrl('getRewardAll');
            let token = localStorage.getItem('token');

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getRewardOpen = createAsyncThunk(
    'tin/getRewardOpen',
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getRewardOpen');
            let token = localStorage.getItem('token');

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const getRewardRejected = createAsyncThunk(
    'tin/getRewardRejected',
    async (_, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('getRewardRejected');
            let token = localStorage.getItem('token');

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const createRewardRequest = createAsyncThunk(
    'tin/createRewardRequest',
    async (data : any, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('createRewardRequest');
            let token = localStorage.getItem('token');

            return CmpAxios(
                baseRequest.route,
                baseRequest.method,
                data,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const updateRewardAccept = createAsyncThunk(
    'tin/updateRewardAccept',
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('updateRewardAccept');
            let token = localStorage.getItem('token');

            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const updateRewardReject = createAsyncThunk(
    'tin/updateRewardReject',
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('updateRewardReject');
            let token = localStorage.getItem('token');

            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

export const deleteReward = createAsyncThunk(
    'tin/deleteReward',
    async (id: number, { rejectWithValue }) => {
        try {
            let baseRequest = await buildUrl('deleteReward');
            let token = localStorage.getItem('token');

            return CmpAxios(
                `${baseRequest.route}${id}`,
                baseRequest.method,
                null,
                token
            );
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;
                return rejectWithValue(errorResponse);
            }
            throw error;
        }
    }
);

const rewardSlice = createSlice({
    name : "reward",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createRewardRequest.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createRewardRequest.fulfilled, (state, action) => {
                state.status = "ready";
                state.rewardCreated = true;
            })
            .addCase(createRewardRequest.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
                }
            })



            .addCase(updateRewardAccept.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(updateRewardAccept.fulfilled, (state, action) => {
                state.status = "ready";
                state.rewardUpdate = true;
                state.listResponse = action.payload;
            })
            .addCase(updateRewardAccept.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
                }
            })



            .addCase(updateRewardReject.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(updateRewardReject.fulfilled, (state, action) => {
                state.status = "ready";
                state.rewardUpdate = true;
                state.listResponse = action.payload;
            })
            .addCase(updateRewardReject.rejected, (state, action) => {
                state.status = "failed";
                if (action.payload) {
                    state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
                }
            })



            .addCase(getRewardAccepted.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getRewardAccepted.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(getRewardAccepted.rejected, (state, action) => {
                state.status = "failed";
                state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
            })



            .addCase(listReward.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(listReward.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(listReward.rejected, (state, action) => {
                state.status = "failed";
                state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
            })



            .addCase(getRewardAll.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getRewardAll.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(getRewardAll.rejected, (state, action) => {
                state.status = "failed";
                state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
            })



            .addCase(getRewardOpen.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getRewardOpen.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(getRewardOpen.rejected, (state, action) => {
                state.status = "failed";
                state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
            })



            .addCase(getRewardRejected.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getRewardRejected.fulfilled, (state, action) => {
                state.status = "idle";
                state.listResponse = action.payload;
            })
            .addCase(getRewardRejected.rejected, (state, action) => {
                state.status = "failed";
                state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
            })



            .addCase(deleteReward.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(deleteReward.fulfilled, (state, action) => {
                state.status = "ready";
                state.rewardDelete = true;
                state.getResponse = action.payload;
            })
            .addCase(deleteReward.rejected, (state, action) => {
                state.status = "failed";
                state.error = MessageError(({ message : action.payload } as ErrorResponse).message);
            })
    }
});

export default rewardSlice.reducer;