import './carousel.css';

const Carousel = ({ children } : { children : any }) => {
    return(
        <div className='mainCarousel'>
            {children}
        </div>
    );
}

export default Carousel;