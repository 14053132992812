import './passwordForm.css';
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs-react';

import Header from '../../components/Header/Header';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Loading from '../../components/Loading/Loading';

import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks';
import { showNotification, NotificationType } from '../../slices/notificationSlice';
import { User, updateUser, clearUserState } from '../../slices/userSlice';

const PasswordForm = () => {
    const dispatch = useAppDispatch();
    const { userData, userUpdated, error, status } = useAppSelector((state) => state.user);
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confNewPassword, setConfNewPassword] = useState<string>('');

    const [typeNewPassword, setTypeNewPassword] = useState('password');
    const [typeConfNewPassword, setTypeConfNewPassword] = useState('password');

    useEffect(() => {
        if (userData) {
            const {
                name,
                email,
            } = userData;

            setName(name);
            setEmail(email);
        }
    }, [userData]);

    useEffect(() => {
        setDisabled(
            Boolean(!(
                email &&
                newPassword &&
                confNewPassword
            ))
        );
    }, [email, newPassword, confNewPassword]);

    useEffect(() => {
        if (userUpdated) {
            dispatch(showNotification({
                message: 'Senha alterada com sucesso!',
                type: NotificationType.Success
            }));
            dispatch(clearUserState(true));
            navigate('/');
        }
    }, [userUpdated]);

    useEffect(() => {
        if (error && status === 'failed') {
            dispatch(showNotification({
                message: error.message,
                type: error.notificationType as NotificationType,
            }));
        }
    }, [error, status]);

    const handleClick = () => {
        if (confNewPassword === newPassword && email === userData.email) {
            var hashPass = bcrypt.hashSync(newPassword, 12);

            if (userData && userData.id) {
                let id : number = userData.id;
                dispatch(
                    updateUser({
                        id: id,
                        city: userData.city,
                        contact: userData.contact,
                        cpf: userData.cpf,
                        email: userData.email,
                        profile: userData.profile,
                        points: userData.points,
                        name: userData.name,
                        password: hashPass,
                    } as User)
                );
            }
        } else {
            dispatch(showNotification({
                message: 'As informações não estão condizentes',
                type: NotificationType.Warning
            }));
        }
    }

    const viewNewPassword = (setValue : any, value : string) => {
        if (value === 'password') {
            setValue('text');
        } else {
            setValue('password');
        }
    }

    return(
        <div className='passwordFormMain' >
            <Header/>
            <div className='passwordFormHeader' >
                <div style={{ background: '#D02030', height: '50%', width: '100%' }} />
                <AccountCircle />
                <label>{name}</label>
            </div>
            <div className='passwordFormContainer' >
                <div className='passwordFormInputFormat' >
                    <label>Email</label>
                    <input 
                        type="text" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='passwordFormInputFormat' >
                    <label>Nova Senha</label>
                    <div className='passwordFormContent' >
                        <input 
                            type={typeNewPassword} 
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <button onClick={() => viewNewPassword(setTypeNewPassword, typeNewPassword)} >
                            {
                                typeNewPassword === "text" ? (
                                    <VisibilityOff/>
                                ) : (
                                    <Visibility/>
                                )
                            }
                        </button>
                    </div>
                </div>
                <div className='passwordFormInputFormat' >
                    <label>Confirmar Nova Senha</label>
                    <div className='passwordFormContent' >
                        <input 
                            type={typeConfNewPassword} 
                            value={confNewPassword}
                            onChange={(e) => setConfNewPassword(e.target.value)}
                        />
                        <button onClick={() => viewNewPassword(setTypeConfNewPassword, typeConfNewPassword)} >
                            {
                                typeConfNewPassword === "text" ? (
                                    <VisibilityOff/>
                                ) : (
                                    <Visibility/>
                                )
                            }
                        </button>
                    </div>
                </div>
                <PrimaryButton
                    label='Confirmar'
                    onClick={handleClick}
                    padding=''
                    disabled={isDisabled}
                />
            </div>
            <Loading isOpen={ status === 'loading' } />
        </div>
    );
}

export default PasswordForm;