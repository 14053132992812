import { useState, useEffect } from "react";
import './meet.css'

import colors from "../../utils/colors";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { getMeets, getPendingMeets, getTimes, schedule } from "../../slices/meetSlice";
import DateFunctions from "../../utils/DateFunctions";

import Steper from "../../components/Steper/Steper";
import ImgButton from "../../components/Button/ImgButton";
import MenuHeaderRed from "../../components/MenuHeader/MenuHeaderRed";
import DateTimePicker from "../../components/DatePicker/DateTimePicker";
import BoschBtn from "../../components/Button/Button";
import MeetRow from "../../components/MeetRow/MeetRow";
import SimpleRedHeader from "../../components/MenuHeader/SimpleRedHeader";

const ScheduleMeet = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [step, setStep] = useState(1);
    const [selected, setSelected] = useState('');
    const [initialDate, setInitialDate] = useState<Date>();
    const dispatch = useAppDispatch();
    const { scheduledMeets, availableTimes, success, pendingMeets } = useAppSelector((state) => state.meet);

    useEffect(() => {
        refresh();

        const verificationInterval = setInterval(() => {
            setCurrentDate(new Date);
        }, 3000);

        return () => clearInterval(verificationInterval);
    }, []);


    useEffect(() => {
        if (success) {
            refresh()
        }
    }, [success]);


    useEffect(() => {

        if (initialDate) {
            let formDate = initialDate.toISOString().split('T')[0];
            dispatch(getTimes({ date: formDate }))
        }


    }, [initialDate]);


    function scheduleFunction() {
        let formDate = initialDate?.toISOString().split('T')[0];

        if(formDate === undefined) {
            let today : Date = new Date;
            formDate = today.toISOString().split('T')[0];
        }

        dispatch(schedule({ date: formDate, timeId: selected }))
    }

    function refresh() {
        setStep(1);
        let todayDate = new Date()
        let formDate = todayDate.toISOString().split('T')[0]
        dispatch(getMeets(true))
        dispatch(getTimes({ date: formDate }))
        dispatch(getPendingMeets(true))

    }

    const compareDates = (scheduleDate : string, currentDate : string) => {
        let splitScheduleDate : string[] = scheduleDate.split(':');
        let splitCurrentDate : string[] = currentDate.split(':');

        let hourScheduleDate : number = parseInt(splitScheduleDate[0]);
        let hourCurrentDate : number = parseInt(splitCurrentDate[0]);

        if (hourCurrentDate > hourScheduleDate && hourCurrentDate === (hourScheduleDate + 1)) {
            return true;
        } else if (hourCurrentDate === hourScheduleDate) {
            let minuteCurrentDate : number = parseInt(splitScheduleDate[1]);
            let minuteScheduleDate : number = parseInt(splitScheduleDate[1]);
            
            if (minuteCurrentDate >= minuteScheduleDate) {
                return true;
            }
        }
        return false;
    }

    function renderStep1() {
        if (scheduledMeets && scheduledMeets.length > 0) {
            return (

                <div style={{ width: '90%', margin: 'auto' }}>
                    <div style={{ fontSize: 23, fontWeight: 'bold', marginTop: 60 }}>Status</div>
                    <div className="sheduleRow">
                        <div className="spanShedule" >Atendimento confirmado.</div>
                    </div>
                    <MeetRow day={DateFunctions.formatSimpleDate(scheduledMeets[0].scheduleDate)} status="ACTIVE" time={scheduledMeets[0].time} />
                    <div className="joinDiv">
                        {
                            DateFunctions.formatSimpleDateLine(currentDate) === scheduledMeets[0].scheduleDate && 
                            compareDates(scheduledMeets[0].time, currentDate.toLocaleTimeString()) && (
                                <BoschBtn 
                                    title="Ingressar" 
                                    click={() => window.open('https://cmp-video-chat-react-9292-dev.twil.io', '_blank', 'rel=noopener noreferrer')} 
                                />
                            )
                        }
                    </div>
                </div>
            )

        } else if (pendingMeets && pendingMeets.length > 0) {
            return (

                <div style={{ width: '90%', margin: 'auto', maxWidth: "900px" }}>
                    <div style={{ fontSize: 23, fontWeight: 'bold', marginTop: 60 }}>Status</div>
                    <div className="sheduleRow">
                        <div className="spanShedule" >Atendimento aguardando confirmação.</div>


                        <div style={{ flex: 0.4, marginTop: -10 }}>
                            <ImgButton title="Agendar" img="calendar" click={() => { setStep(2) }} />
                        </div>
                    </div>

                    {
                        pendingMeets?.map((meetShow: any, index: number) => {
                            return (
                                <div key={index} style={{ marginRight: 5, marginLeft: 5, justifyContent: 'center', alignContent: 'center' }}>
                                    <MeetRow day={DateFunctions.formatSimpleDate(meetShow.scheduleDate)} status="PENDING" time={meetShow.time.substring(0, meetShow.time.length - 3)} />
                                </div>
                            )
                        })
                    }


                </div>
            )

        } else {
            return (

                <div style={{ width: '90%', margin: 'auto', maxWidth: "900px" }}>
                    <div style={{ fontSize: 23, fontWeight: 'bold', marginTop: 60 }}>Status</div>
                    <div className="sheduleRow">
                        <div className="spanShedule" >Você não possui nenhum atendimento agendado.</div>
                        <div style={{ flex: 0.4, marginTop: -10 }}>
                            <ImgButton title="Agendar" img="calendar" click={() => { setStep(2) }} />
                        </div>
                    </div>
                </div>
            )
        }

    }


    function renderStep2() {
        return (

            <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <DateTimePicker
                    selectFunc={(sVal: string) => { setSelected(sVal) }}
                    selected={selected}
                    label="Data de início"
                    value={initialDate}
                    setValue={setInitialDate}
                    availables={availableTimes}

                />

                {
                    selected && (
                        <div style={{ position: 'absolute', bottom: "20px", width: '80%', maxWidth: "900px", margin: "auto" }}>
                            <BoschBtn title="Próximo" click={scheduleFunction} />
                        </div>
                    )
                }

            </div>
        )
    }

    return (

        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            {
                step == 1 ? (
                    <div>
                        <SimpleRedHeader title='Agendamentos' />

                        <div className="basicHeader">
                            <div style={{ fontSize: 25, fontWeight: 'bold', color: colors.red }}>Status&nbsp;</div>
                            <div style={{ fontSize: 25, fontWeight: 'bold' }}>de agendamento</div>
                        </div>
                    </div>
                ) : <MenuHeaderRed />
            }


            <Steper step={step} />
            {step == 1 && renderStep1()}
            {step == 2 && renderStep2()}
        </div>
    );
};

export default ScheduleMeet;


