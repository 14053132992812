import './add.css';
import { useNavigate } from 'react-router';

const AddTinModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: Function }) => {
    const navigate = useNavigate();

    return (
        <>
            {
                isOpen ? (
                    <div className='addModal'>
                        <div className='addModalBackground' onClick={() => onClose()} >
                            <div className='addModalContainer'>
                                <div className='addModalHeader'>
                                    <h5>Cadastro de latas</h5>
                                </div>
                                <div className='addModalContent' >
                                    <p>Como você deseja cadastrar?</p>
                                    <div className='addModalBtns' >
                                        <button onClick={() => navigate('/admin/tin-form')} style={{ background: '#D02030', marginRight: 3 }} >
                                            Unidade
                                        </button>
                                        <button onClick={() => navigate('/admin/tin-mass-form')} style={{ background: '#D02030', marginLeft: 3 }} >
                                            Múltiplos
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}

export default AddTinModal;