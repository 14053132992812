import { Person, AdminPanelSettings } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import colors from "../../utils/colors";
import "./showcases.css";

import TinIcon from '../../images/tinIcon.png';
import playImg from "../../images/playbtn.png";
import settingsImg from "../../images/settings.png";
import rewardsImg from "../../images/rewardIcon.png";
import callIcon from "../../images/callIcon.png";

const AdminShowcases = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ 
        backgroundColor: colors.greyBackground, 
        flex: 0.45, 
        flexDirection: "column", 
        display: "flex", 
        justifyContent: "center", 
        width: "100%",
        maxWidth: "1200px",
        margin: "auto",
      }}
    >
      <div
        style={{ justifyContent: "space-around", flexDirection: "row", display: "flex" }}
      >
        <div
          onClick={() => navigate("/admin/tutorial-list")}
          className='showCaseButton'
        >
          <center>
            <img src={playImg} alt="playbtn" style={{ marginTop: 13.5 }} />
          </center>
        </div>

        <div
          onClick={() => navigate("/admin/promotion-list")}
          className='showCaseButton'
        >
          <center>
            <img src={settingsImg} alt="reward" style={{ marginTop: 12.5 }} />
          </center>
        </div>

        <div
          onClick={() => navigate("/admin/call-list")}
          className='showCaseButton'
        >
          <center>
            <img src={callIcon} alt="atendimentos" style={{ marginTop: 12.5, width: '23px', height: '23px'}} />
          </center>
        </div>

      </div>

      <div
        style={{
          justifyContent: "space-around",
          flexDirection: "row",
          display: "flex",
          marginBottom: '50px'
        }}
      >
        <div style={{ width: 70, textAlign: "center", marginTop: '6px' }}>
          <span style={{ textAlign: "center", fontSize: 10, fontWeight: 'bold', display: 'flex', maxHeight: 30 }}>Gestão de tutoriais</span>
        </div>

        <div style={{ width: 70, textAlign: "center", marginTop: '6px' }}>
          <span style={{ textAlign: "center", fontSize: 10, fontWeight: 'bold', display: 'flex', maxHeight: 30 }}>Gestão de promoções</span>
        </div>

        <div style={{ width: 70, textAlign: "center", marginTop: '6px' }}>
          <span style={{ textAlign: "center", fontSize: 10, fontWeight: 'bold', display: 'flex', maxHeight: 30 }}>Gestão de atendimentos</span>
        </div>
      </div>

      <div
        style={{
          justifyContent: "space-around",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          onClick={() => navigate("/admin/user-list")}
          style={{
            position: "relative",
            backgroundColor: "white",
            width: 70,
            height: 70,
            borderRadius: 8,
            cursor: "pointer",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Person />
        </div>

        <div
          onClick={() => navigate("/admin/tin-list")}
          style={{
            position: "relative",
            backgroundColor: "white",
            width: 70,
            height: 70,
            borderRadius: 8,
            cursor: "pointer",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={TinIcon} alt="tintas" style={{ width: '23px', height: '23px' }} />
        </div>

        <div
          onClick={() => navigate("/admin/reward-list")}
          style={{
            position: "relative",
            backgroundColor: "white",
            width: 70,
            height: 70,
            borderRadius: 8,
            cursor: "pointer",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={rewardsImg} alt="recompensas" style={{ width: '23px', height: '23px' }} />
        </div>
      </div>

      <div
        style={{
          justifyContent: "space-around",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div style={{ width: 70, textAlign: "center", display: 'flex', maxHeight: 30, marginTop: '6px' }}>
          <span style={{ textAlign: "center", fontSize: 10, fontWeight: 'bold' }}>Gestão de usuários</span>
        </div>

        <div style={{ width: 70, textAlign: "center", display: 'flex', maxHeight: 30, marginTop: '6px' }}>
          <span style={{ textAlign: "center", fontSize: 10, fontWeight: 'bold' }}>Gestão de latas</span>
        </div>

        <div style={{ width: 70, textAlign: "center", display: 'flex', maxHeight: 30, marginTop: '6px' }}>
          <span style={{ textAlign: "center", fontSize: 10, fontWeight: 'bold' }}>Gestão de recompensas</span>
        </div>

      </div>
    </div>
  );
};

export default AdminShowcases;


